import React, { useState, useEffect } from "react";
import constants from "../../../config/constants";
const NewFooter = ({ props }) => {
  const [area, setArea] = useState(null);
  const pages = [
    { id: 1, name: "About Us", link: "https://www.bookeventz.com/about-us" },
    { id: 2, name: "Team", link: "https://www.bookeventz.com/team" },
    { id: 3, name: "Contact Us", link: "https://www.bookeventz.com/contact-us" },
    { id: 4, name: "FAQ", link: "https://www.bookeventz.com/faq" },
    { id: 5, name: "Create Event Website", link: "https://www.bookeventz.com/build-your-event-website" },
    { id: 6, name: "List With Us", link: "https://www.bookeventz.com/why-list-with-us" },
  ];
  const pagesTwo = [
    { id: 1, name: "Media", link: "https://www.bookeventz.com/media/" },
    { id: 2, name: "Add Your Venue", link: "https://www.bookeventz.com/add-banquet" },
    { id: 3, name: "Feedback", link: "https://www.bookeventz.com/feedback" },
    { id: 4, name: "Real Events", link: "https://www.bookeventz.com/real-events" },
    { id: 5, name: "Offers", link: "https://www.bookeventz.com/offers" },
  ];
  const pagesThree = [
    { id: 1, name: "Corporate Offerings", link: "https://www.bookeventz.com/corporate-event-planning-portal" },
    { id: 2, name: "Jobs", link: "https://jobs.bookeventz.com/" },
    { id: 3, name: "Testimonial", link: "https://www.bookeventz.com/testimonial" },
    { id: 4, name: "Blog", link: "https://www.bookeventz.com/blog" },
  ];
  useEffect(() => {
    const { filters, areaList } = props;
    if (filters?.area) {
      const selectedArea = areaList?.find((a) => a.AreaId == filters.area);
      setArea(selectedArea ? selectedArea : null);
    }
  }, [props]);
  return (
    <div id="footer_wrapper">
      <div id="footer_top_6">
        <div id="top_1">
          <span>
            About <p>BOOKEVENTZ</p>
          </span>
          <span>India's Largest Event Booking Portal</span>
        </div>
        <div id="top_2">
          <ul>
            {pages &&
              pages.map((p, index) => {
                return (
                  <li key={index}>
                    <a
                      href={p.link}
                      target="_blank"
                      style={{
                        color: "inherit",
                        textDecoration: "none",
                      }}
                      rel="noopener noreferrer"
                    >
                      {p.name}
                    </a>
                  </li>
                );
              })}
          </ul>
        </div>
        <div id="top_3">
          <ul>
            {pagesTwo &&
              pagesTwo.map((p, index) => {
                return (
                  <li key={index}>
                    <a
                      href={p.link}
                      target="_blank"
                      style={{
                        color: "inherit",
                        textDecoration: "none",
                      }}
                      rel="noopener noreferrer"
                    >
                      {p.name}
                    </a>
                  </li>
                );
              })}
          </ul>
        </div>
        <div id="top_4">
          <ul>
            {pagesThree &&
              pagesThree.map((p, index) => {
                return (
                  <li key={index}>
                    <a
                      href={p.link}
                      target="_blank"
                      style={{
                        color: "inherit",
                        textDecoration: "none",
                      }}
                      rel="noopener noreferrer"
                    >
                      {p.name}
                    </a>
                  </li>
                );
              })}
          </ul>
        </div>
        <div id="top_5">
          <span>
            <a
              href="tel:9967581110"
              target="_blank"
              style={{
                color: "inherit",
                textDecoration: "none",
              }}
              rel="noopener noreferrer"
            >
              <img
                src="https://media.bookeventz.com/html/bookeventz.com/images/headerFooterIcons/pink_phone.svg"
                alt="Call us"
                fetchpriority="low"
                data-cache-control="public, max-age=31536000"
                width="15"
                height="24"
              />
              +91 99 675 81110
            </a>
          </span>
        </div>
        <div id="top_6">
          <span>
            <a
              href="mailto:info@bookeventz.com"
              target="_blank"
              style={{
                color: "inherit",
                textDecoration: "none",
              }}
              rel="noopener noreferrer"
            >
              <img
                src="https://media.bookeventz.com/html/bookeventz.com/images/headerFooterIcons/mail.svg"
                alt="Email us"
                fetchpriority="low"
                data-cache-control="public, max-age=31536000"
                width="22"
                height="18"
              />
              info@bookeventz.com
            </a>
          </span>
          <div>
            <a
              href={` https://facebook.com/BookEventz/`}
              target="_blank"
              style={{
                color: "inherit",
                textDecoration: "none",
              }}
              rel="noopener noreferrer"
            >
              <img src="https://media.bookeventz.com/html/bookeventz.com/images/headerFooterIcons/fb.svg" alt="Facebook" fetchpriority="low" data-cache-control="public, max-age=31536000" />
            </a>
            <a
              href={`https://x.com/BookEventz`}
              target="_blank"
              style={{
                color: "inherit",
                textDecoration: "none",
              }}
              rel="noopener noreferrer"
            >
              <img src="https://media.bookeventz.com/html/bookeventz.com/images/headerFooterIcons/x.svg" alt="Twitter / X" fetchpriority="low" data-cache-control="public, max-age=31536000" />
            </a>

            <a
              href="https://www.instagram.com/accounts/login/?next=/bookeventz/"
              target="_blank"
              style={{
                color: "inherit",
                textDecoration: "none",
              }}
              rel="noopener noreferrer"
            >
              <img src="https://media.bookeventz.com/html/bookeventz.com/images/headerFooterIcons/insta.svg" alt="Insatgram" fetchpriority="low" data-cache-control="public, max-age=31536000" />
            </a>
            <a
              href={`https://in.pinterest.com/bookeventz/`}
              target="_blank"
              style={{
                color: "inherit",
                textDecoration: "none",
              }}
              rel="noopener noreferrer"
            >
              {" "}
              <img src="https://media.bookeventz.com/html/bookeventz.com/images/headerFooterIcons/pint.svg" alt="Pinterest" fetchpriority="low" data-cache-control="public, max-age=31536000" />
            </a>

            <a
              href={`https://www.linkedin.com/company/bookeventz`}
              target="_blank"
              style={{
                color: "inherit",
                textDecoration: "none",
              }}
              rel="noopener noreferrer"
            >
              {" "}
              <img src="https://media.bookeventz.com/html/bookeventz.com/images/headerFooterIcons/link.svg" alt="Linkedin" fetchpriority="low" data-cache-control="public, max-age=31536000" />
            </a>
            <a
              href={`https://www.youtube.com/user/UrbanRestro?themeRefresh=1`}
              target="_blank"
              style={{
                color: "inherit",
                textDecoration: "none",
              }}
              rel="noopener noreferrer"
            >
              <img src="https://media.bookeventz.com/html/bookeventz.com/images/headerFooterIcons/yt.svg" alt="Youtube" fetchpriority="low" data-cache-control="public, max-age=31536000" />
            </a>
          </div>
        </div>
      </div>
      <div id="footer_top_7">
        <span>Banquet Halls by City</span>
        <ul>
          {props?.cityList?.map((city, index) =>
            city?.CountryId == props?.sessionData?.CountryId && city?.CityId !== "14" ? (
              <li key={index}>
                <a
                  href={`https://www.bookeventz.com/banquets/${city?.CityUniqueLink}`}
                  target="_blank"
                  style={{
                    color: "inherit",
                    textDecoration: "none",
                  }}
                  rel="noopener noreferrer"
                >
                  {city.CityName}
                </a>
              </li>
            ) : null
          )}
        </ul>
      </div>
      <div id="footer_top_7">
        <span>Banquet by Area</span>
        <ul>
          {Array.isArray(props?.areaList) &&
            props.areaList
              .filter(area => {
                {/* cityArea */ }
                {/* destination-wedding */ }
                // If `ifActive` exists, include areas with `ifActive == "1"`
                if ("ifActive" in area) {
                  return area?.ifActive == "1" && area?.isPopular == "1";
                }
                // Otherwise, include areas with `CityId` and `isPopular` matching criteria
                return area?.CityId == props?.sessionData?.CityId && area?.isPopular == "1";
              })
              .map((area, index) => {
                let link = "";
                if (props?.cityArea !== "" && props?.cityArea == "destination-wedding") {
                  link = `https://www.bookeventz.com/banquets/destination-wedding/${area?.AreaUniqueLink}`;
                } else {
                  link = `https://www.bookeventz.com/banquets/${props?.sessionData?.CityUniqueLink}/${area?.AreaUniqueLink}`
                }
                return (
                  <li key={index}>
                    <a
                      href={link}
                      target="_blank"
                      style={{
                        color: "inherit",
                        textDecoration: "none",
                      }}
                      rel="noopener noreferrer"
                    >
                      {area.AreaName}
                    </a>
                  </li>
                )
              })}
        </ul>
      </div>
      <div id="footer_top_7">
        <span>Banquet by Venue</span>
        <ul>
          {constants?.VENUE_TYPE?.map((venueType, index) => {
            if (!["2", 2].includes(venueType.id)) {
              if (venueType.cityId == null) {
                return (
                  <li key={index}>
                    <a
                      key={venueType.id}
                      target="_blank"
                      style={{
                        color: "inherit",
                        textDecoration: "none",
                      }}
                      rel="noopener noreferrer"
                      href={`https://www.bookeventz.com/banquets/${props?.sessionData?.CityUniqueLink}/${venueType.uniqueLink}`}
                    >
                      {venueType.name}
                    </a>
                  </li>
                );
              } else {
                let ids = venueType.cityId.split(",");
                if (ids.indexOf(props?.sessionData?.CityId) !== -1) {
                  return (
                    <li key={index}>
                      <a
                        key={venueType.id}
                        target="_blank"
                        style={{
                          color: "inherit",
                          textDecoration: "none",
                        }}
                        rel="noopener noreferrer"
                        href={`https://www.bookeventz.com/banquets/${props?.sessionData?.CityUniqueLink}/${venueType.uniqueLink}`}
                      >
                        {venueType.name}
                      </a>
                    </li>
                  );
                }
              }
            }
          })}
        </ul>
      </div>
      <div id="footer_top_7" className="footer_top_7">
        <span>Banquet by Occasion</span>
        <ul>
          {props.occasionList !== undefined && Array.isArray(props?.occasionList) &&
            props.occasionList.length > 0 &&
            props.occasionList.map((occasion, index) => {
              if (
                props?.sessionData?.tier == "1" && occasion?.popular == "1" && props?.pageType !== "" &&
                props?.pageType === "vendorListing" && // Ensure this condition checks the actual pageType
                ["10", "12", "46", "33", "3", "58", "9", "59"].includes(occasion.id) &&
                occasion.ifActive !== "0" // Ensure the occasion is active and popular
              ) {
                return (
                  <li key={index}>
                    <a
                      key={occasion.id}
                      target="_blank"
                      style={{
                        color: "inherit",
                        textDecoration: "none",
                      }}
                      rel="noopener noreferrer"
                      href={`https://www.bookeventz.com/banquets/${props.sessionData?.CityUniqueLink}/${occasion.uniqueLink}`}
                    >
                      {occasion.name}
                    </a>
                  </li>
                );
              } else if (props?.sessionData?.seoTier !== "2" && occasion.ifActive !== "0" && occasion?.popular == "1") {
                return (
                  <li key={index}>
                    <a
                      key={occasion.id}
                      target="_blank"
                      style={{
                        color: "inherit",
                        textDecoration: "none",
                      }}
                      rel="noopener noreferrer"
                      href={`https://www.bookeventz.com/banquets/${props.sessionData?.CityUniqueLink}/${occasion.uniqueLink}`}
                    >
                      {occasion.name}
                    </a>
                  </li>
                );
              }
              else if (props?.sessionData?.seoTier == "2" && occasion.ifActive !== "0" && occasion?.popular == "1" && occasion?.type == "1") {
                return (
                  <li key={index}>
                    <a
                      key={occasion.id}
                      target="_blank"
                      style={{
                        color: "inherit",
                        textDecoration: "none",
                      }}
                      rel="noopener noreferrer"
                      href={`https://www.bookeventz.com/banquets/${props.sessionData?.CityUniqueLink}/${occasion.uniqueLink}`}
                    >
                      {occasion.name}
                    </a>
                  </li>
                );
              }
              return null;
            })}
        </ul>
      </div>
      <div id="policyt">
        <a
          title="Terms Of Services"
          target="_blank"
          href={`https://www.bookeventz.com/terms-of-service`}
          style={{
            color: "inherit",
            textDecoration: "none",
          }}
          rel="noopener noreferrer"
        >
          <span>Terms Of Services</span>
        </a>
        <a
          title="Terms Of Services"
          target="_blank"
          href={`https://www.bookeventz.com/privacy-policy`}
          style={{
            color: "inherit",
            textDecoration: "none",
          }}
          rel="noopener noreferrer"
        >
          <span>Policies</span>
        </a>
        <a
          title="Terms Of Services"
          target="_blank"
          href={`https://www.bookeventz.com/web-sitemap`}
          style={{
            color: "inherit",
            textDecoration: "none",
          }}
          rel="noopener noreferrer"
        >
          <span>Sitemap</span>
        </a>
      </div>
    </div>
  );
};

export default NewFooter;
