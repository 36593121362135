var constants = {};
var showAd = true;
var initialise = function () {
  constants = {
    FILE_READ_MODE: "0644",
    ILE_WRITE_MODE: "0666",
    DIR_READ_MODE: "0755",
    DIR_WRITE_MODE: "0755",
    protocol: "https",
    IMG_BASE: "//media.bookeventz.com/html/bookeventz.com/",
    IMG_BASE_Short: "https://media.bookeventz.com/",
    IMG_BASE_THUMBS: "https://media.bookeventz.com/html/bookeventz.com/",
    IMG_BASE_THUMBS_PRODUCT: "https://media.bookeventz.com/html/bookeventz.com//mainthumbs/",
    IMG_BASE_THUMBS_150:
      "http://bookeventz-files.s3-website-ap-southeast-1.amazonaws.com/150x110/html/bookeventz.com/",
    IMG_BASE_THUMBS_LISTING: "https://media.bookeventz.com//443x272/html/bookeventz.com/",
    SCRIPT_BASE: "https://media.bookeventz.com//443x272/html/bookeventz.com/",
    THREE_SIXTY_SLIDERS_BASE: "https://media.bookeventz.com/html/bookeventz.com/slider/",
    ENCRYPTION_KEY: "urbanLocal",
    LIVE_SERVER: "www.bookeventz.com",
    LIVE_SERVER_2: "bookeventz.com",
    DEMO_SERVER: "52.76.193.227",
    CENTRAL_SERVER: "192.168.1.100",
    PUBLIC_CENTRAL_SERVER: "120.138.109.173",
    LOCAL_SERVER: "localhost",
    BLOG_BASE_URL: "https://www.bookeventz.com/blog-old/wp-json/wp/v2/",
    API_AUTH_TOKEN: "blah",
    FOPEN_READ: "rb",
    FOPEN_READ_WRITE: "r+b",
    FOPEN_WRITE_CREATE_DESTRUCTIVE: "wb",
    FOPEN_READ_WRITE_CREATE_DESCTRUCTIVE: "w+b",
    FOPEN_WRITE_CREATE: "ab",
    FOPEN_READ_WRITE_CREATE: "a+b",
    FOPEN_WRITE_CREATE_STRICT: "xb",
    FOPEN_READ_WRITE_CREATE_STRICT: "x+b",
    EXIT_SUCCESS: 0,
    EXIT_ERROR: 1,
    EXIT_CONFIG: 2,
    EXIT_UNKNOWN_FILE: 3,
    EXIT_UNKNOWN_CLASS: 4,
    EXIT_UNKNOWN_METHOD: 5,
    EXIT_USER_INPUT: 6,
    EXIT_DATABASE: 7,
    EXIT__AUTO_MIN: 8,
    EXIT__AUTO_MAX: 9,
    UTIL_JS: "bookeventz-utils-temp.js",
    DEFAULT_CITY: 1,
    DEFAULT_PRODUCT: 3,
    DEFAULT_REST_ID: 0,
    BANQUET_MIN_CAPACITY: 10,
    BANQUET_MAX_CAPACITY: 2000,
    BANQUET_MIN_BUDGET: 500,
    BANQUET_MAX_BUDGET: 3000,
    CATERER_MIN_BUDGET: 100,
    CATERER_MAX_BUDGET: 3000,
    CATERER_MIN_PLATES: 0,
    CATERER_MAX_PLATES: 1000,
    THEMES_MIN_BUDGET: 10000,
    THEMES_MAX_BUDGET: 60000,
    THEMES_MIN_CAPACITY: 10,
    ARTIST_MIN_BUDGET: 1000,
    ARTIST_MAX_BUDGET: 50000,
    BANQUETS_ID: 3,
    CATERERS_ID: 5,
    THEMES_ID: 7,
    ARTIST_ID: 8,
    PAGE_404: "page404",
    PAGE_WRONG: "wrongplace",
    COMPARE_PAGE: "comparepage",
    COMPARE_CHECKOUT_PAGE: "comparecheckout",
    showAdFeature: showAd ? true : null,

    GUEST_USER_TYPE: 0,
    SIGNED_USER_TYPE: 1,
    VENDOR_ADMIN_USER_TYPE: 2,
    SUPER_ADMIN_USER_TYPE: 3,
    CITY_ADMIN_USER_TYPE: 4,
    SALES_ADMIN_USER_TYPE: 5,

    OPEN: 1,
    NEW_EVENT: 11,
    SUGGESTED: 5,
    SHORTLISTED: 2,
    FINALIZED: 3,
    USER_FINALIZED: 10,
    CONVERTED: 4,
    CANCELED: 0,
    ENQUIRY_PREFIX: "REF",
    EVENT_PREFIX: "BZ",
    ENQUIRY: 0,
    EVENT: 1,
    CUSTOMIZED_PACKAGE: 1,
    INBUILT_PACKAGE: 0,
    RESPONSE_JSON: "json",
    RESPONSE_RETURN: "json",
    RESPONSE_REDIRECT: "redirect",
    PRICE_HIGH: "high",
    PRICE_LOW: "low",
    SHORTLIST_HIGH: "high",
    SHORTLIST_LOW: "low",
    MY_SORT_ASC: "asc",
    MY_SORT_DESC: "desc",
    REVIEW_PRODUCT_PAGE_ID: 1,
    REVIEW_BOOKING_ID: 2,
    REVIEW_FROM_EVENT: 3,
    REVIEW_LEAD_ID: 4,
    REVIEW_VENUE_DASHBOARD_ID: 8,
    REVIEW_SPECIFIC_VENUE: 9,
    REVIEW_MAX_RATING: 10,
    USER_TYPE_SALES: 0,
    USER_TYPE_VENUE: 1,
    USER_TYPE_CUSTOMER: 2,
    VENDOR_CATERER: 1,
    VENDOR_BANQUET: 2,
    VENDOR_ARTIST: 3,
    VEG: 1,
    NON_VEG: 0,
    PURE_VEG: 3,
    LIQUOR_SERVED: 3,
    LIQUOR_NOT_SERVED: 0,
    FORMAT_ARRAY: 1,
    NOT_FORMAT_ARRAY: 0,
    MD_ENCRYPT: 1,
    NOT_MD_ENCRYPT: 1,
    ALLOWED: 1,
    NOT_ALLOWED: 0,
    ACTIVE: 1,
    NOT_ACTIVE: 0,
    GIVEN: 1,
    NOT_GIVEN: 0,

    TRIGGER_ENQUIRY: 1,
    TRIGGER_CATERER_ENQUIRY: 2,
    TRIGGER_ARTIST_ENQUIRY: 3,
    TRIGGER_BANQUET_REVIEW: 4,
    TRIGGER_CATERER_REVIEW: 5,
    TRIGGER_ARTIST_REVIEW: 6,

    SINGLE_DATE: 0,
    RANGE_DATE: 1,
    MULTIPLE_DATE: 2,
    DATE_FORMAT_JQ: "dd-M-yy",
    DATE_FORMAT_SQL_JQ: "d-M-Y",
    DATE_FORMAT_SQL: "Y-m-d",
    TIME_FORMAT_SQL: "Y-m-d H:i:s",
    DATE_TIME_FORMAT_SQL: "",
    DATE_FORMAT_UI: "m-d-Y",
    DATE_TIME_FORMAT_UI: "j M, Y, g:i a",
    DATE_FORMAT_UI2: "j M, y",
    DATE_FORMAT_UI3: "j M y",

    AVAILABILITY_NO: 0,
    AVAILABILITY_YES: 1,
    AVAILABILITY_TENTATIVE: 2,
    CATERER_LEAD_TYPE: 1,
    VENUE_LEAD_TYPE: 2,
    ARTIST_LEAD_TYPE: 3,

    BG_IMG_BANNER: "asset/images/background-image-banner.jpg",
    SALES_PIC_PATH: "images/restaurant-logo/",
    PHONE_NUMBER1: "images/restaurant-logo/",
    PHONE_NUMBER2: "+91 9930050439",
    BANQUETS_AS_HALLS: [12, 18, 31, 10, 16, 19, 21, 5, 22, 26, 27, 6, 7, 28, 8, 9, 29, 30, 15, 25],
    OTHER_OCCASION: "32",
    photographerNoOfHrsAncillaryId: 9,
    photographerNoOfPhotosAncillaryId: 10,
    photographerNoOfPhotographersAncillaryId: 11,
    artistPaymentTermsTypeId: 1,
    artistCancellationPolicyTypeId: 2,
    artistTravelVenueTypeId: 3,
    artistTravelCostTypeId: 4,
    artistTrialPolicyTypeId: 5,
    artistOtherTypeId: 6,
    POPULAR_AREA_IDS: [
      1, 12, 8, 13, 51, 6, 1770, 80, 19, 221, 222, 1720, 229, 231, 249, 237, 1838, 1527, 1535, 1536,
      1537, 1545, 1547, 1548, 1550, 130, 131, 133, 135, 138, 140, 158, 1925, 367, 376, 1641, 695,
      735, 742, 797, 1321, 3142, 1937, 1876, 1868, 1505, 3096, 1498, 1509, 3614, 3578, 3624, 3599,
      3606, 3612, 3640, 2354, 3034, 3074, 2346, 2347, 2348, 2350, 2355, 2252, 3187, 2219, 2220,
      2221, 2222, 2250, 2251, 3268, 2304, 2308, 2309, 2311, 2317, 2318, 2337, 1359, 1363, 1372,
      1390, 1398, 4420, 1414, 1444, 3427, 3467, 3486, 3453, 3432, 3444, 1336, 1333, 1334, 1340,
      1342, 1343, 1345, 1346, 3856, 2209, 2248, 2217, 2206, 2210, 2212, 2213, 2247, 2489, 2578,
      1730, 1791, 1786, 4294, 2580, 4491, 2259, 2260, 2261, 2266, 2294, 2275, 2276, 2277, 2278,
      2279, 2280, 2282, 2374, 2366, 2367, 2365, 2368, 2371, 2372, 2376, 2404, 2381, 2382, 2383,
      2384, 2399, 2387, 2388, 2287, 2283, 2284, 2285, 2289, 2288, 2290, 2291, 2229, 2258, 2262,
      2227, 2254, 2255, 2256, 2258, 1466, 1462, 1469, 2503, 2522, 2506, 2513, 2518, 2515, 2527,
      1572, 1568, 1569, 1570, 1571, 1576, 1591, 1592, 100, 102, 96, 95, 111, 126, 97, 128, 2270,
      2234, 2237, 2267, 2271, 2235, 2292, 2236,
    ],
    POPULAR_BRAND: [16515, 16647, 16593, 16595, 16510, 16615, 16620],
    AREA_HAS_EAST_WEST: [
      {
        1: [1607, 1608],
        2: [1622, 1623],
        3: [1610, 1611],
        6: [1609, 1624],
        8: [1620, 1621],
        13: [1616, 1617],
        14: [1618, 1619],
        44: [1612, 1613],
        50: [1612, 1613],
      },
    ],
    ALBUM_COVER_HORIZONTAL: "0",
    ALBUM_COVER_VERTICAL: "1",
    ALBUM_IMG_TYPE_MAIN: "1",
    ALBUM_IMG_TYPE_COVER_MAIN: "2",
    ALBUM_IMG_TYPE_COVER_OTHER: "3",
    ALBUM_IMG_TYPE_OTHER: "0",
    ALBUM_IMG_PATH: "images/albums/",
    ALBUM_IMG_PATH_THUMB: "images/albums/thumbs/",
    CITY_IMG_PATH: "images/cityPictures/",
    col_xxs: "500",
    col_xs: "767",
    col_sm: "991",
    col_md: "1199",
    AREA_IMG_PATH: "images/areaPictures/",
    SHOW_PACKAGE_CITY: [1],
    ENQUIRY_TIMING_LIST_NEW: [
      {id: "1", name: "Morning", time: "07:00-10:00", startTime: "07:00", endTime: "10:00"},
      {id: "2", name: "Afternoon", time: "11:00-15:30", startTime: "10:00", endTime: "15:30"},
      {id: "3", name: "Evening", time: "16:00-19:00", startTime: "16:00", endTime: "19:00"},
      {id: "4", name: "Late Evening", time: "19:00-23:30", startTime: "19:00", endTime: "23:30"},
      {id: "5", name: "Full day", time: "09:30-18:30", startTime: "09:00", endTime: "18:30"},
    ],
    BANQUET_PACKAGE_TYPE_LIST: [
      {
        1: {Name: "Veg"},
        2: {Name: "Non Veg"},
        3: {Name: "Veg + Liquor"},
        4: {Name: "Non Veg + Liquor"},
        5: {Name: "Liquor"},
      },
    ],
    BANQUET_PACKAGE_OCCASION_LIST: [
      {
        1: {Name: "Lunch"},
        2: {Name: "Dinner"},
        3: {Name: "High Tea"},
        4: {Name: "Full Day"},
        5: {Name: "Lunch + Dinner"},
      },
    ],
    defaultCountryDialCodeArray: [{id: "98", name: "India", dial_code: "+91", code: "IN"}],
    BANQUET_PACKAGE_LIQUOR_TYPE_LIST: [
      {
        1: {id: "1", Name: "Beer Only"},
        2: {id: "2", Name: "Beer and Breezers"},
        3: {id: "3", Name: "Beer and Wine"},
        4: {id: "4", Name: "IMFL Drinks"},
        5: {id: "5", Name: "Imported Liquor"},
      },
    ],
    THEME_TYPE: [
      {
        1: {id: "1", name: "2D", uniqueLink: "2d"},
        2: {id: "2", name: "3D", uniqueLink: "3d"},
      },
    ],
    THEME_OCCASION_LIST: [
      {
        1: {id: "1", name: "Kids Parties", uniqueLink: "kids-parties"},
        2: {id: "2", name: "Cocktail Parties", uniqueLink: "cocktail-parties"},
        3: {id: "3", name: "Corporate Get Together", uniqueLink: "corporate-get-together"},
        4: {id: "4", name: "Social Get Together", uniqueLink: "social-get-together"},
        5: {id: "5", name: "Wedding", uniqueLink: "wedding"},
        6: {id: "6", name: "Farewell Party", uniqueLink: "farewell-party"},
        7: {id: "7", name: "Freshers Party", uniqueLink: "freshers-party"},
        8: {id: "8", name: "Freshers Party", uniqueLink: "birthday-party"},
      },
    ],
    ENQUIRY_MENU_TYPE: [
      {
        0: {id: "0", name: "Non-Veg", uniqueLink: "non-veg"},
        1: {id: "0", name: "Veg", uniqueLink: "veg"},
        2: {id: "0", name: "Both", uniqueLink: "both"},
        3: {id: "0", name: "I want Pure Veg", uniqueLink: "pure-veg"},
      },
    ],
    ENQUIRY_TIMING_LIST: [
      {
        1: {id: "1", name: "Breakfast", time: "07:00-10:30"},
        2: {id: "2", name: "Lunch", time: "11:00-15:30"},
        3: {id: "3", name: "Hi-Tea", time: "15:00-18:00"},
        4: {id: "4", name: "Other", time: "Null"},
        5: {id: "5", name: "Full Day", time: "09:30-18:30"},
      },
    ],
    VENDOR_DURATIOn: [
      {id: "1", name: "Half Day"},
      {id: "2", name: "One Day"},
      {id: "3", name: "One and half Days"},
      {id: "4", name: "Two Days"},
    ],
    ENVIRONMENT: "development",
    // WEB_BASE:'http://staging.bookeventz.com/',
    // API_BASE:'https://apidemo.bookeventz.com/',
    // SERVER_BASE:'http://localhost:5000/',

    WEB_BASE: "https://www.bookeventz.com/",
    API_BASE: "https://api.bookeventz.com/",
    TEST_API_BASE: "https://apitest.bookeventz.com/",
    SERVER_BASE: "https://grunt.bookeventz.com/",

    RESTAURANT_BANNER_PATH: "images/restaurant-picture/",
    RESTAURANT_BANNER_PATH_NEW: "images/banquet-layout/",
    VENUE_TYPE: [
      {id: 2, name: "AC Banquet Hall", uniqueLink: "banquet-hall", cityId: null},
      {id: 3, name: "Hotel", uniqueLink: "hotels", cityId: null},
      {id: 4, name: "Restaurant", uniqueLink: "restaurants", cityId: null},
      {id: 5, name: "Lounge", uniqueLink: "lounges", cityId: null},
      {id: 6, name: "Resort", uniqueLink: "resorts", cityId: null},
      {id: 7, name: "Party Lawns", uniqueLink: "party-lawns", cityId: null},
      {id: 9, name: "5 Star Hotel", uniqueLink: "5-star-hotels", cityId: null},
      {id: 10, name: "Farmhouses", uniqueLink: "farmhouses", cityId: "4,5,6"},
      {id: 11, name: "Training/Boardroom", uniqueLink: "training-boardroom", cityId: null},
      // {id:13,name:'Kids Birthday Party',uniqueLink:'kids-birthday-party',cityId:null},
      {id: 14, name: "Palaces", uniqueLink: "palaces", cityId: "9,13,33"},
      {id: 15, name: "Coworking spaces", uniqueLink: "coworking-spaces", cityId: "1"},
      {id: 16, name: "Penthouse", uniqueLink: "penthouse", cityId: "60"},
      {id: 17, name: "Wedding Chapels", uniqueLink: "wedding-chapels", cityId: "8"},
    ],
    VENDOR_CITY_IDS: [1, 2, 3, 4, 5, 6, 7, 12, 16, "7", "12", "16", "1", "2", "3", "4", "5", "6"],
    VENDOR_COUNTRY_IDS: [1, "1"],
    FILTERS: [
      "grayscale",
      "invert",
      "remove-color",
      "sepia",
      "brownie",
      "brightness",
      "contrast",
      "saturation",
      "vibrance",
      "noise",
      "vintage",
      "pixelate",
      "blur",
      "sharpen",
      "emboss",
      "technicolor",
      "polaroid",
      "blend-color",
      "gamma",
      "kodachrome",
      "blackwhite",
      "blend-image",
      "hue",
      "resize",
    ],
    FOOTER_LIST_1: [
      {id: 1, name: "About Us", uniqueLink: "about-us"},
      {id: 2, name: "Team", uniqueLink: "team"},
      {id: 3, name: "Contact Us", uniqueLink: "contact-us"},
      {id: 4, name: "FAQ", uniqueLink: "faq"},
      {id: 5, name: "Create Event Website", uniqueLink: "build-your-event-website"},
      {id: 6, name: "List With Us", uniqueLink: "why-list-with-us"},
    ],
    FOOTER_LIST_2: [
      {id: 1, name: "Media", uniqueLink: "media/"},
      {id: 2, name: "Add Your Venue", uniqueLink: "add-banquet"},
      {id: 3, name: "Feedback", uniqueLink: "feedback"},
      {id: 4, name: "Real Events", uniqueLink: "real-events"},
      {id: 5, name: "Offers", uniqueLink: "offers"},
    ],
    FOOTER_LIST_3: [
      {id: 1, name: "Corporate Offerings", uniqueLink: "corporate-event-planning-portal"},
      {id: 3, name: "Jobs", uniqueLink: "jobs.bookeventz.com"},
      {id: 2, name: "Testimonial", uniqueLink: "testimonial"},
      {id: 4, name: "Blog", uniqueLink: "blog"},
    ],
  };
};
initialise();
module.exports = constants;
