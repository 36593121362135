'use strict';

import React, {Component} from 'react'

import axios from 'axios'
import {DropdownButton, MenuItem} from 'react-bootstrap'
import {Circle} from 'better-react-spinkit'
import generalUtil from '../general/generalUtil'
import { FixedSizeList as List } from 'react-window';

/*
 * Country Code drop down where user can select country code
 * Selected country code is updated into store sessionData -> countryCode
 */
class BzCountryCodeDropDown extends Component {
    constructor(props)
    {
        super(props);

        var countryCodeValue = {"id":"98","name":"India","dial_code":"+91","code":"IN"};
        var selCountryCode = {"id":"98","name":"India","dial_code":"+91","code":"IN"};

        this.state = {'countryCodeArr': [], countryCodeValue, selCountryCode};
    }
    componentDidMount(){
        var that = this;
         window.innerWidth < 768?
             that.getCountryCodeList()
         :''
    }
    //handle country code selection and update to store
    countryCodeChange(row)
    {
        this.setState({'countryCodeValue':row.id, 'selCountryCode': row });

        this.props.updateCountryCode(row.dial_code);
    }

    //on click request for countryCode code list
    getCountryCodeList()
    {
        var that = this;

        if(this.state.countryCodeArr.length == 0)
        {
            axios.post("https://apidemo.bookeventz.com/Basic/getCountryCodeList")
                .then((xhrResponse) => {

                    if(generalUtil.safeReturn(xhrResponse, 'data', false) !== false)
                    {
                        let data = xhrResponse.data;

                        that.setState({'countryCodeArr': data});
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    selectOnChange(e)
    {
        this.countryCodeChange(JSON.parse(e.target.value))
    }

    render()
    {
        return(
            <div className={"bzCountryCodeDropDown my-display-inline"} >
                {
                    window.innerWidth > this.props.constants.col_xs
                    ?
                        <DropdownButton title={this.state.selCountryCode.code+" "+this.state.selCountryCode.dial_code}
                                        onClick={this.getCountryCodeList.bind(this)}>
                            {
                                this.state.countryCodeArr.length != 0
                                    ?
                                    this.state.countryCodeArr.map(function(row, i)
                                    {
                                        return(
                                            <MenuItem key={i} eventKey={row.id} onSelect={this.countryCodeChange.bind(this, row)}>
                                                {row.code+" "+row.dial_code}
                                            </MenuItem>
                                        )
                                    }, this)
                                    :
                                    <MenuItem className="loader-center">
                                        <Circle />
                                    </MenuItem>
                            }
                        </DropdownButton>
                        :
                        <select title={this.state.selCountryCode.code+" "+this.state.selCountryCode.dial_code}
                                onClick={this.getCountryCodeList.bind(this)}
                                onChange={this.selectOnChange.bind(this)} >
                            {
                                this.state.countryCodeArr.length != 0
                                    ?
                                    this.state.countryCodeArr.map(function(row, i)
                                    {
                                        let selectedToggle = false;
                                        row.dial_code == "+91" ?
                                            selectedToggle = true
                                            :''

                                        return(
                                            <option key={i} value={JSON.stringify(row)} selected = {selectedToggle}>
                                                {row.code+" "+row.dial_code}
                                            </option>
                                        )
                                    }, this)
                                    :
                                    <option>
                                        {this.state.selCountryCode.code+" "+this.state.selCountryCode.dial_code}
                                    </option>
                            }
                            {
                                this.state.countryCodeArr.length == 0
                                ?
                                    <option>
                                        Loading...
                                    </option>
                                    :
                                    ""
                            }
                        </select>
                        
                }

            </div>
        )
    }
}

export default BzCountryCodeDropDown;

{/* <select title={this.state.selCountryCode.code+" "+this.state.selCountryCode.dial_code}
                                onClick={this.getCountryCodeList.bind(this)}
                                onChange={this.selectOnChange.bind(this)} >
                            {
                                this.state.countryCodeArr.length != 0
                                    ?
                                    this.state.countryCodeArr.map(function(row, i)
                                    {
                                        let selectedToggle = false;
                                        row.dial_code == "+91" ?
                                            selectedToggle = true
                                            :''

                                        return(
                                            <option key={i} value={JSON.stringify(row)} selected = {selectedToggle}>
                                                {row.code+" "+row.dial_code}
                                            </option>
                                        )
                                    }, this)
                                    :
                                    <option>
                                        {this.state.selCountryCode.code+" "+this.state.selCountryCode.dial_code}
                                    </option>
                            }
                            {
                                this.state.countryCodeArr.length == 0
                                ?
                                    <option>
                                        Loading...
                                    </option>
                                    :
                                    ""
                            }
                        </select> */}
// <select title={this.state.selCountryCode.code+" "+this.state.selCountryCode.dial_code}
                        //         onClick={this.getCountryCodeList.bind(this)}
                        //         onChange={this.selectOnChange.bind(this)} >
                        //     {
                        //         this.state.countryCodeArr.length != 0
                        //             ?
                        //             // this.state.countryCodeArr.map(function(row, i)
                        //             // {
                        //             //     let selectedToggle = false;
                        //             //     row.dial_code == "+91" ?
                        //             //         selectedToggle = true
                        //             //         :''

                        //             //     return(
                        //             //         <option key={i} value={JSON.stringify(row)} selected = {selectedToggle}>
                        //             //             {row.code+" "+row.dial_code}
                        //             //         </option>
                        //             //     )
                        //             // }, this)
                        //             <List
                        //                 itemData={this.state.countryCodeArr}
                        //                 innerElementType="option"
                        //                 itemCount={this.state.countryCodeArr.length}
                        //                 itemSize={20}
                        //                 height={700}
                        //                 width={400}
                        //             >
                        //                 {({ data, index, style }) => {
                        //                     console.log(data,index,style);
                        //                     let row = data;
                        //                     let selectedToggle = false;
                        //                     row.dial_code == "+91" ?
                        //                         selectedToggle = true
                        //                         :''
                        //                     return  <option key={index} value={JSON.stringify(row)} selected = {selectedToggle}>
                        //                         {row.code+" "+row.dial_code}
                        //                     </option>;
                        //                 }}
                        //             </List>
                        //             :
                        //             <option>
                        //                 {this.state.selCountryCode.code+" "+this.state.selCountryCode.dial_code}
                        //             </option>
                        //     }
                        //     {
                        //         this.state.countryCodeArr.length == 0
                        //         ?
                        //             <option>
                        //                 Loading...
                        //             </option>
                        //             :
                        //             ""
                        //     }
                        // </select>