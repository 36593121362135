"use strict";

var React = require("react");
var generalUtil = require("./generalUtil");
/* Uncomment below import to enable Firebase */
import SubscribeModal from "./subscribeModal";
import {initializeApp} from "firebase/app";
import {getMessaging, getToken, onMessage} from "firebase/messaging";
import Chatbot from "../general/WhatsApp";
/* Do Not uncomment below import */
import {onBackgroundMessage} from "firebase/messaging/sw";
var querystring = require("querystring");
/*
import * as  CryptoJS from 'crypto-js';
*/
/*
import {Popover} from 'react-bootstrap'
*/
// var Reactbootstrap = require('react-bootstrap');

// import CityListPopup from './cityListPopup'

if (typeof window === "undefined") {
  global.window = {};
}
var environment = "production";
if (environment == "development") {
  var API_BASE = "https://apidemo.bookeventz.com/";
  var WEB_BASE = "https://staging.bookeventz.com/";
} else {
  var API_BASE = "https://api.bookeventz.com/";
  var WEB_BASE = "https://www.bookeventz.com/";
}

var SSR_WEB_BASE = "";
switch (process.env.NODE_ENV) {
  case "production":
    SSR_WEB_BASE = "https://www.bookeventz.com";
    break;
  case "development":
    SSR_WEB_BASE = "https://grunt.bookeventz.com";
    break;
  case "local":
    SSR_WEB_BASE = "http://localhost:5000";
    break;
  default:
    SSR_WEB_BASE = "";
}
SSR_WEB_BASE = "https://www.bookeventz.com";

const footerListArray = [
  {type: "city", title: "Banquet Halls by City"},
  {type: "area", title: "Banquet by Area"},
  {type: "venueType", title: "Banquet by Venue"},
  {type: "occasion", title: "Banquet by Occasion"},
];

import constants from "../../config/constants";
import config from "../../config/config";
class Footer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      filters: this.props.otherProps.filters,
      // showNewSubscriber:['venueListing','homePage','venueProduct'].includes(this.props.pageType),
      showNewSubscriber: true,
      userData: this.props.otherProps?.userData,
      notifModal: false,
      payLoad: null,
    };
  }

  componentWillMount() {}
  componentDidMount() {
    /**
     * Uncomment it for enable FCM
     * **/
    const firebaseConfig = {
      apiKey: "AIzaSyB4pVOqXTiHWF8LCz05km9SyCXUepPfFA4",
      authDomain: "bookeventz-web.firebaseapp.com",
      projectId: "bookeventz-web",
      storageBucket: "bookeventz-web.appspot.com",
      messagingSenderId: "577866287857",
      appId: "1:577866287857:web:d3b1419b534907b808f750",
      measurementId: "G-4R0LN143ZW",
    };
    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);
    generalUtil.fcmPushNotification(messaging, onMessage, getToken);
    onMessage(messaging, (payload) => {
      this.setState({payLoad: payload});
      let permission = Notification.permission;
      if (permission === "granted") {
        this.setState({notifModal: !this.state.notifModal});
        var title = payload.notification.title;
        let icon = "https://img.onesignal.com/t/6574a703-52ff-45cc-bbfb-a941769222db.png";
        var body = payload.notification.body;
        let image = payload.notification.image;
        let data = {
          click_action: payload.click_actionf ? payload.click_action : "https://www.bookeventz.com/blog",
        };
        var notification = new Notification(title, {body, icon, image, data});
        notification.onclick = () => {
          notification.close();
          window.location.href = "https://www.bookeventz.com";
          window.parent.focus();
        };
      } else if (permission === "default") {
        // requestAndShowPermission();
      } else {
        console.log("Use normal alert");
      }
    });
  }
  render() {
    var that = this;
    var user = "info";
    var domain = "bookeventz.com";
    const {showNewSubscriber} = this.state;
    return (
      <footer>
        {this.props.eventId == null && this.props.sessionData && !("userName" in this.props.sessionData) && (
          <Chatbot
            sessionData={this.props.sessionData}
            event_Id={this.props.eventId}
            class_name={this.props.class_name}
            productDetails={this.props.productDetails}
            flag={this.props.flag}
            filters={this.props.filters}
          />
        )}
        {this.state.notifModal && (
          <div
            id="fcm-wrapper"
            style={{
              width: 365,
              height: "auto",
              background: "#FFFFFF",
              borderRadius: 5,
              padding: "16px 10px 14px 16px",
              position: "fixed",
              top: 10,
              right: 20,
              zIndex: 999999,
            }}
          >
            <span
              id="closi"
              style={{
                width: 18,
                height: 18,
                background: "#E7E7E7",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "100%",
                position: "absolute",
                right: -8,
                top: -5,
              }}
              onClick={() => this.setState({notifModal: !this.state.notifModal})}
            >
              <img src="../../media/closi.svg" alt="closi-button" />
            </span>
            <a href={this.state.payLoad?.data?.custom_open_url} target="_blank" onClick={() => this.setState({notifModal: false})} style={{cursor: "pointer"}}>
              <div
                id="ro"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: 14,
                  width: "100%",
                  height: "100%",
                }}
              >
                <div id="i" style={{display: "flex", flexDirection: "column", gap: 8}}>
                  <span
                    id="ti"
                    style={{
                      fontFamily: "Metropolis,sans-serif",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: 14,
                      color: "#333333",
                      width: "100%",
                      lineBreak: "anywhere",
                    }}
                  >
                    {this.state.payLoad?.notification?.title.length > 55 ? this.state.payLoad?.notification?.title.slice(0, 55) + "." : this.state.payLoad?.notification?.title}
                  </span>
                  <span
                    id="tid"
                    style={{
                      fontFamily: "Metropolis,sans-serif",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: 12,
                      color: "#888888",
                      width: "100%",
                      lineBreak: "anywhere",
                    }}
                  >
                    {this.state.payLoad?.notification?.body?.length > 100 ? this.state.payLoad?.notification?.body.slice(0, 100) + "..." : this.state.payLoad?.notification?.body}
                  </span>
                </div>
                <div id="im" style={{width: 87, height: 63, borderRadius: 5}}>
                  <img src={this.state.payLoad?.notification?.image} alt="Image of Notification" style={{height: 63, width: 87, objectFit: "cover", borderRadius: 5}} />
                </div>
              </div>
            </a>
          </div>
        )}

        {showNewSubscriber && (
          <SubscribeModal
            token={this.props.props.token}
            isShowModal={showNewSubscriber}
            pageType={this.props.pageType}
            filters={this.state.filters}
            userData={this.state.userData}
            sessionData={this.props.sessionData}
          />
        )}
        <div className="top-section-footer">
          <div className="hidden-xs col-sm-5 col-md-3">
            <span style={{fontSize: 24, color: "#fff"}}> About BOOKEVENTZ</span>
            <p className="footer-creative">India's Largest Event Booking Portal</p>
          </div>
          <div className="hidden-xs hidden-md hidden-lg col-sm-7">
            <div className="row">
              <div className="col-sm-6 no-pad">
                {that.props.sessionData != undefined ? (
                  that.props.sessionData.CityId == "1" ? (
                    <i data-src="https://media.bookeventz.com/html/bookeventz.com/asset/images/footerDesktop-sprite-min.png" className="b-lazy mobile-icon"></i>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                {that.props.sessionData != undefined ? (
                  that.props.sessionData.CityId == "1" ? (
                    <p>
                      <a href="tel:9967581110">+91 99 675 81110</a>
                    </p>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>
              <div className="col-sm-6 no-pad displayFlex"></div>

              <div className="col-sm-12 social-icons no-pad"></div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-5">
            <div className="col-xs-6 col-sm-4 col-md-4 no-pad">
              <ul className="footer-link">
                {constants.FOOTER_LIST_1.map((footerList1, index) => {
                  return (
                    <li key={index}>
                      <a title={footerList1.name} target="_blank" href={generalUtil.uniqueUrlLinkCheck(config.BASE_URL + footerList1.uniqueLink)}>
                        {footerList1.name}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="col-xs-6 col-sm-4 col-md-4 no-pad">
              <ul className="footer-link">
                {constants.FOOTER_LIST_2.map((footerList2, index) => {
                  return (
                    <li key={index}>
                      <a
                        title={footerList2.name}
                        target="_blank"
                        // href={generalUtil.uniqueUrlLinkCheck(
                        //   config.BASE_URL + footerList2.uniqueLink + "/"
                        // )}
                        href={`https://www.bookeventz.com/${footerList2.uniqueLink}`}
                      >
                        {footerList2.name}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="col-xs-6 col-sm-4 col-md-4 no-pad">
              <ul className="footer-link">
                <li>
                  <a title="About Us" target="_blank" href={SSR_WEB_BASE + "/corporate-event-planning-portal"}>
                    Corporate Offerings
                  </a>
                </li>
                <li>
                  <a rel="noreferrer" title="Jobs" target="_blank" href="https://jobs.bookeventz.com/">
                    Jobs
                  </a>
                </li>
                <li>
                  <a title="Testimonial" target="_blank" href={SSR_WEB_BASE + "/testimonial"}>
                    Testimonial
                  </a>
                </li>
                <li>
                  <a title="Blog" target="_blank" href="https://www.bookeventz.com/blog/">
                    Blog
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="hidden-xs hidden-sm col-md-4">
            <div className="row">
              <div className="col-sm-6 no-pad">
                <div>
                  {that.props.sessionData != undefined ? (
                    that.props.sessionData.CityId == "1" ? (
                      <i data-src="https://media.bookeventz.com/html/bookeventz.com/asset/images/footerDesktop-sprite-min.png" className="b-lazy mobile-icon"></i>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {that.props.sessionData != undefined ? (
                    that.props.sessionData.CityId == "1" ? (
                      <p>
                        <a href="tel:9967581110">+91 99 675 81110</a>
                      </p>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-sm-6 no-pad displayFlex">
                <i data-src="https://media.bookeventz.com/html/bookeventz.com/asset/images/footerDesktop-sprite-min.png" className="envelope-icon b-lazy"></i>
                <p>
                  <a href="mailto:&#105;&#110;&#102;&#111;&#064;&#098;&#111;&#111;&#107;&#101;&#118;&#101;&#110;&#116;&#122;&#046;&#099;&#111;&#109;">
                    &#105;&#110;&#102;&#111;&#064;&#098;&#111;&#111;&#107;&#101;&#118;&#101;&#110;&#116;&#122;&#046;&#099;&#111;&#109;
                  </a>
                </p>
              </div>
              <div className="col-sm-12 social-icons no-pad">
                <ul className="list-inline">
                  <li>
                    <a rel="noreferrer" title="Facebook" href="https://www.facebook.com/BookEventz/" target="_blank">
                      <i data-src="https://media.bookeventz.com/html/bookeventz.com/asset/images/footerDesktop-sprite-min.png" className="footer-social-icon facebook_icon b-lazy"></i>
                      <span className="facebook-text" style={{display: "none"}}>
                        Bookeventz Facebook
                      </span>
                    </a>
                  </li>
                  <li>
                    <a rel="noreferrer" title="Twitter" href="https://twitter.com/BookEventz" target="_blank">
                      <i data-src="https://media.bookeventz.com/html/bookeventz.com/asset/images/footerDesktop-sprite-min.png" className="footer-social-icon twitter_icon b-lazy"></i>
                      <span className="twitter-text" style={{display: "none"}}>
                        Bookeventz Twitter
                      </span>
                    </a>
                  </li>
                  <li>
                    <a rel="noreferrer" title="Instagram" href="https://www.instagram.com/accounts/login/?next=/bookeventz/" target="_blank">
                      <i data-src="https://media.bookeventz.com/html/bookeventz.com/asset/images/footerDesktop-sprite-min.png" className="footer-social-icon instagram_icon b-lazy"></i>
                      <span className="insta-text" style={{display: "none"}}>
                        Bookeventz Instagram
                      </span>
                    </a>
                  </li>
                  <li>
                    <a rel="noreferrer" title="Pinterest" href="https://in.pinterest.com/bookeventz/" target="_blank">
                      <i data-src="https://media.bookeventz.com/html/bookeventz.com/asset/images/footerDesktop-sprite-min.png" className="footer-social-icon pintrest_icon b-lazy"></i>
                      <span className="pint-text" style={{display: "none"}}>
                        Bookeventz Pinterest
                      </span>
                    </a>
                  </li>
                  <li>
                    <a rel="noreferrer" title="Linkedin" href="https://www.linkedin.com/company/bookeventz" target="_blank">
                      <i data-src="https://media.bookeventz.com/html/bookeventz.com/asset/images/footerDesktop-sprite-min.png" className="footer-social-icon linkedin_icon b-lazy"></i>
                      <span className="linkedin-text" style={{display: "none"}}>
                        Bookeventz Linkedin
                      </span>
                    </a>
                  </li>
                  <li>
                    <a rel="noreferrer" title="Youtube" href="https://www.youtube.com/user/UrbanRestro" target="_blank">
                      <i data-src="https://media.bookeventz.com/html/bookeventz.com/asset/images/footerDesktop-sprite-min.png" className="footer-social-icon youtube_icon b-lazy"></i>
                      <span className="youtube-text" style={{display: "none"}}>
                        Bookeventz Youtube Channel
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 social-icons no-pad hidden-lg hidden-md hidden-sm"></div>
        <hr className="hidden-xs line" />
        <div className={"footer-blocks"} style={{paddingLeft: "16px", paddingRight: "16px"}}>
          {that.props.newcitySession &&
            that.props.newcitySession.CityUniqueLink != undefined &&
            footerListArray.map((footer, index) => {
              return (
                <div key={index} className={this.props.isMobile != "1" ? "freqListWrap" : "freqListWrap"} style={{backgroundColor: "#1d1d1d"}}>
                  <h4>
                    <span href={""} style={{margin: 0, paddingTop: "10px", paddingBottom: "0px"}} className="headingFooter">
                      {" "}
                      {footer.title}{" "}
                    </span>
                  </h4>
                  {footer.type == "city" && (
                    <div className={"freqList"} style={{wordWrap: "break-word", paddingTop: "10px", paddingBottom: "10px"}}>
                      {this.props.cityList &&
                        this.props.cityList.map((city) => {
                          if (city?.CountryId == that.props.sessionData?.CountryId && city?.CityId != "14") {
                            return (
                              <>
                                <a target="_blank" key={city.CityId} style={{whiteSpace: "nowrap"}} href={generalUtil.uniqueUrlLinkCheck(config.BASE_URL + "banquets/" + city.CityUniqueLink)}>
                                  {city.CityName}
                                </a>
                              </>
                            );
                          }
                        })}
                    </div>
                  )}
                  {footer.type == "area" && (
                    <div className={"freqList"} style={{wordWrap: "break-word", paddingTop: "10px", paddingBottom: "10px"}}>
                      {this.props.areaList &&
                        this.props.areaList.map((area) => {
                          if (area?.CityId == that.props.sessionData?.CityId && area?.isPopular == "1") {
                            return (
                              <>
                                <a
                                  key={area.AreaId}
                                  target="_blank"
                                  href={generalUtil.uniqueUrlLinkCheck(`${config.BASE_URL}banquets/${that.props.newcitySession.CityUniqueLink}/${area.AreaUniqueLink}`)}
                                >
                                  {area.AreaName}
                                </a>
                              </>
                            );
                          }
                        })}
                    </div>
                  )}
                  {footer.type == "venueType" && (
                    <div className={"freqList"} style={{wordWrap: "break-word", paddingTop: "10px", paddingBottom: "10px"}}>
                      {constants.VENUE_TYPE.map((venueType) => {
                        if (!["2", 2].includes(venueType.id)) {
                          if (venueType.cityId == null) {
                            return (
                              <>
                                <a
                                  key={venueType.id}
                                  target="_blank"
                                  href={
                                    this.props.NewAreaUniqueLink !== "" && this.props.NewAreaUniqueLink !== null && this.props.NewAreaUniqueLink !== undefined
                                      ? generalUtil.uniqueUrlLinkCheck(`${config.BASE_URL}banquets/${that.props.newcitySession.CityUniqueLink}/${this.props.NewAreaUniqueLink}/${venueType.uniqueLink}`)
                                      : generalUtil.uniqueUrlLinkCheck(`${config.BASE_URL}banquets/${that.props.newcitySession.CityUniqueLink}/${venueType.uniqueLink}`)
                                  }
                                >
                                  {venueType.name}
                                </a>
                              </>
                            );
                          } else {
                            let ids = venueType.cityId.split(",");
                            if (ids.indexOf(that.props.sessionData?.CityId) != -1) {
                              return (
                                <>
                                  <a
                                    key={venueType.id}
                                    target="_blank"
                                    href={
                                      this.props.NewAreaUniqueLink !== "" && this.props.NewAreaUniqueLink !== null && this.props.NewAreaUniqueLink !== undefined
                                        ? generalUtil.uniqueUrlLinkCheck(
                                            `${config.BASE_URL}banquets/${that.props.newcitySession.CityUniqueLink}/${this.props.NewAreaUniqueLink}/${venueType.uniqueLink}`
                                          )
                                        : generalUtil.uniqueUrlLinkCheck(`${config.BASE_URL}banquets/${that.props.newcitySession.CityUniqueLink}/${venueType.uniqueLink}`)
                                    }
                                  >
                                    {venueType.name}
                                  </a>
                                </>
                              );
                            }
                          }
                        }
                      })}
                    </div>
                  )}
                  {footer.type == "occasion" && (
                    <div className={"freqList"} style={{wordWrap: "break-word", paddingTop: "10px", paddingBottom: "10px"}}>
                      {this.props.occasionList &&
                        this.props.occasionList.map((occasion, index) => {
                          if (["10", "12", "46", "33", "3", "58", "9", "59"].includes(occasion.id)) {
                            return (
                              <>
                                <a
                                  key={occasion.id}
                                  target="_blank"
                                  href={
                                    this.props.NewAreaUniqueLink !== "" && this.props.NewAreaUniqueLink !== null && this.props.NewAreaUniqueLink !== undefined
                                      ? generalUtil.uniqueUrlLinkCheck(`${config.BASE_URL}banquets/${that.props.newcitySession.CityUniqueLink}/${this.props.NewAreaUniqueLink}/${occasion.uniqueLink}`)
                                      : generalUtil.uniqueUrlLinkCheck(`${config.BASE_URL}banquets/${that.props.newcitySession.CityUniqueLink}/${occasion.uniqueLink}`)
                                  }
                                >
                                  {occasion.name}
                                </a>
                              </>
                            );
                          }
                        })}
                    </div>
                  )}
                </div>
              );
            })}
        </div>
        <div className="col-sm-12 social-icons no-pad"></div>
        <hr className="hidden-xs line hidden-lg" />
        <div className="copyright">
          <div className="hidden-lg hidden-md hidden-sm center-block text-center">
            <a title="Terms Of Services" target="_blank" href={SSR_WEB_BASE + "/terms-of-service"}>
              Terms Of Services{" "}
            </a>
            |
            <a title="Polices" target="_blank" href={SSR_WEB_BASE + "/privacy-policy"}>
              {" "}
              Policies
            </a>
            |
            <a href={SSR_WEB_BASE + "/web-sitemap"} title="Sitemap" target="_blank">
              {" "}
              Sitemap{" "}
            </a>
          </div>
          <div className="col-sm-6 col-md-6 hidden-xs">
            <a title="Terms Of Services" target="_blank" href={SSR_WEB_BASE + "/terms-of-service"}>
              Terms Of Services{" "}
            </a>
            |
            <a href={SSR_WEB_BASE + "/privacy-policy"} target="_blank" title="Polices">
              {" "}
              Policies
            </a>
            |
            <a href={SSR_WEB_BASE + "/web-sitemap"} target="_blank" title="Sitemap">
              {" "}
              Sitemap{" "}
            </a>
          </div>
          {/* <div className="hidden-sm msite-div">
            {that.props.newcitySession != undefined ? (
              that.props.newcitySession.CityId == "1" ? (
                <div>
                  {that.props.pageType == "ContractorPackageListing" ? (
                    <div className="col-xs-6 col-sm-3 no-pad no-padding">
                      <i
                        data-src={
                          "https://media.bookeventz.com/html/bookeventz.com/asset/images/footerMobile-sprite-min.png"
                        }
                        className="b-lazy mobile-icon"
                      >
                        {" "}
                      </i>
                      <p>
                        <a href="tel:9967581110">+91 99 675 81110</a>
                      </p>
                    </div>
                  ) : that.props.pageType == "venueProduct" ? (
                    <div className="col-xs-6 col-sm-3 no-pad no-padding">
                      <i
                        data-src={
                          "https://media.bookeventz.com/html/bookeventz.com/asset/images/footerMobile-sprite-min.png"
                        }
                        className="b-lazy mobile-icon"
                      >
                        {" "}
                      </i>
                      <p>
                        <a href="tel:9967581110">+91 99 675 81110</a>
                      </p>
                    </div>
                  ) : (
                    <div className="col-xs-6 col-sm-3 no-pad no-padding">
                      <i
                        data-src={
                          "https://media.bookeventz.com/html/bookeventz.com/asset/images/footerMobile-sprite-min.png"
                        }
                        className="b-lazy mobile-icon"
                      >
                        {" "}
                      </i>
                      <p>
                        <a href="tel:9967581110">+91 99 675 81110</a>
                      </p>
                    </div>
                  )}
                  <div className="col-xs-6 col-sm-3 no-pad no-padding">
                    <i
                      data-src={
                        "https://media.bookeventz.com/html/bookeventz.com/asset/images/footerMobile-sprite-min.png"
                      }
                      className="b-lazy envelope-icon"
                    >
                      {" "}
                    </i>
                    <p>
                      <a href="mailto:&#105;&#110;&#102;&#111;&#064;&#098;&#111;&#111;&#107;&#101;&#118;&#101;&#110;&#116;&#122;&#046;&#099;&#111;&#109;">
                        &#105;&#110;&#102;&#111;&#064;&#098;&#111;&#111;&#107;&#101;&#118;&#101;&#110;&#116;&#122;&#046;&#099;&#111;&#109;
                      </a>
                    </p>
                  </div>
                </div>
              ) : (
                <div className="col-xs-12 col-sm-6 no-pad no-padding">
                  <i
                    data-src={
                      "https://media.bookeventz.com/html/bookeventz.com/asset/images/footerMobile-sprite-min.png"
                    }
                    className="b-lazy envelope-icon"
                  >
                    {" "}
                  </i>
                  <p>
                    <a href="mailto:&#105;&#110;&#102;&#111;&#064;&#098;&#111;&#111;&#107;&#101;&#118;&#101;&#110;&#116;&#122;&#046;&#099;&#111;&#109;">
                      &#105;&#110;&#102;&#111;&#064;&#098;&#111;&#111;&#107;&#101;&#118;&#101;&#110;&#116;&#122;&#046;&#099;&#111;&#109;
                    </a>
                  </p>
                </div>
              )
            ) : (
              ""
            )}
          </div> */}
          <div className="col-sm-6 col-md-6" id="lofi">
            Copyright@ 2023. Urban Online Services Pvt. Ltd.
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
