import React, { useEffect, useState } from "react";
import { Modal, Button, ButtonGroup, Grid } from "react-bootstrap";
import axios from "axios";
import querystring from "querystring";
import localStorageUtil from "./localstorage";
const WEB_BASE = "https://www.bookeventz.com/";

export default function SubscribeModal({ isShowModal, pageType, filters, sessionData, token }) {
  const [show, setShow] = useState(false);
  const [eventId, setEventId] = useState(null);
  const [userData, setUserData] = useState(null);
  const [user_profile_id, setUser_profile_id] = useState(null);
  const handleClose = () => {
    setShow(false);
    // document.body.style.overflow = "hidden";
    // localStorageUtil.setLocal('_showSubscribeModal',{value:false},10080)
    localStorage.setItem("_showSubscribeModal", "no_action");
    localStorageUtil.setLocal("_isBackDropSubscribe", { value: false }, 10080);
  };
  const handleEscape = () => {
    setShow(false);
  };
  useEffect(() => {
    getCookies();
  }, []);
  const getCookies = () => {
    const cookies = document.cookie.split(";").map((cookie) => cookie.trim());
    const sessionDataCookie = cookies.find((cookie) => cookie.startsWith("sessionData="));
    const userDataCookie = cookies.find((cookie) => cookie.startsWith("userData="));
    if (sessionDataCookie) {
      const sessionData = decodeURIComponent(sessionDataCookie.split("=")[1]);
      const parsedSessionData = JSON.parse(sessionData);
      setUser_profile_id(parsedSessionData.user_profile_id);
    }
    if (userDataCookie) {
      const userData = decodeURIComponent(userDataCookie.split("=")[1]);
      const parsedUserData = JSON.parse(userData);
      setUserData(parsedUserData);
      setEventId(parsedUserData.eventId);
    }
  };
  useEffect(() => {
    if (eventId !== null) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, []);

  const handleShow = () => setShow(true);
  const onSubscribeClick = () => {
    setShow(false);
    let data = {
      bypass: 404,
      fcm_token: token,
      city_id: filters?.city,
      is_vendor: 0,
      page_type: pageType,
      page_source: "",
      area_id: filters?.area,
      area_id: filters?.area,
      event_id: eventId,
      occasion_id: filters?.occasion,
      lead_status: "",
      is_shortlisted: "",
      vendor_type: "",
      vendor_id: "",
      venue_type_id: filters?.category,
    };
    let URL = "https://api.bookeventz.com" + "/PushNotification/storeSubscriber";
    axios.post(URL, querystring.stringify(data)).then((res) => {
      if (res.data.success) {
        // localStorageUtil.setLocal('_showSubscribeModal',{value:true},10080)
        localStorage.setItem("_showSubscribeModal", "yes_action");
        // localStorageUtil.setLocal('_isBackDropSubscribe',{value:false},10080)
      }
    });
  };

  const myGet = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    /*return decodeURIComponent(results[2].replace(/\+/g, " "));*/
    return decodeURIComponent(results[2]);
  };

  const checkPopupOpen = () => {
    let isShowMainSliderCloseIcon = false;
    let isShowMainSlider = false;
    if (
      document.referrer != "" &&
      localStorage.getItem("openpopuponce") == null &&
      document.referrer.search(WEB_BASE + "banquets") != -1 &&
      selectedTab == "about"
    ) {
      isShowMainSlider = true;
      isShowMainSliderCloseIcon = false;
    }

    if (localStorage.getItem("sliderPopupIsActive") != null && window.location.search.length > 0) {
      isShowMainSlider = true;
    } else if (
      localStorage.getItem("sliderPopupIsActive") == null &&
      window.location.search.length > 0
    ) {
      isShowMainSlider = true;
    } else if (localStorage.getItem("sliderPopupIsActive") == null) {
      isShowMainSlider = true;
    }

    if (
      myGet("utm_source") != null ||
      myGet("adcampaign") != null ||
      myGet("adgroup") != null ||
      myGet("device") != null ||
      myGet("utm_campaign") != null ||
      myGet("utm_medium") != null ||
      myGet("gclid") != null ||
      myGet("inf") != null
    ) {
      isShowMainSlider = true;
    }

    if (myGet("checkPopup") != null) {
      isShowMainSlider = true;
    }
    return isShowMainSlider;
  };

  const checkSubscribeModal = () => {
    let showModal = true;
    switch (localStorage.getItem("_showSubscribeModal")) {
      case "no_action":
        showModal = false;
        break;
      case "yes_action":
        showModal = false;
        break;
      case "backdrop_action":
        // if(localStorageUtil.getLocal('_isBackDropSubscribe')==null){
        //     showModal= true;
        // }else{
        showModal = false;
        // }
        break;
      default:
        showModal = true;
        break;
    }
    return showModal;
  };
  const showModalSubscription = (showCondition) => {
    setTimeout(() => {
      setShow(showCondition);
    }, 20000);
  };

  const checkListingPopupOpen = () => {
    var isShowMainSlider = true;
    if (localStorage.getItem("sliderPopupIsActive") != null) {
      isShowMainSlider = false;
    }
    if (
      myGet("utm_source") != null ||
      myGet("adcampaign") != null ||
      myGet("adgroup") != null ||
      myGet("device") != null ||
      myGet("utm_campaign") != null ||
      myGet("utm_medium") != null ||
      myGet("gclid") != null ||
      myGet("inf") != null
    ) {
      isShowMainSlider = true;
    }
    if (myGet("checkPopup") != null) {
      isShowMainSlider = true;
    }

    if (localStorage.getItem("sliderPopupIsActive") != null) {
      isShowMainSlider = false;
    }
    return isShowMainSlider;
  };

  const onBackDropHide = () => {
    setShow(false);
    document.body.style.overflow = "hidden";
    // localStorageUtil.setLocal('_showSubscribeModal',{value:false},10080)
    localStorage.setItem("_showSubscribeModal", "backdrop_action");
    localStorageUtil.setLocal("_isBackDropSubscribe", { value: true }, 1);
  };

  useEffect(() => {
    if (checkSubscribeModal()) {
      showModalSubscription(true);
    }
  }, []);
  return (
    <>
      {/* <Modal show={show} onHide={()=>onBackDropHide()} dialogClassName={'subscribe-dialog'} backdropClassName={'subscribe-backdrop'} backdrop={true} onEscapeKeyDown={()=>setShow(false)} keyboard={true}> */}
      {show && (
        <div id="suus">
          <div className="show-grid" style={{ backgroundColor: "#fff" }}>
            <div style={{ display: "flex", flexDirection: "row", margin: 10, alignItems: "center" }}>
              <div>
                <img
                  src="https://img.onesignal.com/t/6574a703-52ff-45cc-bbfb-a941769222db.png"
                  style={{ width: "80px", height: "80px" }}
                />
              </div>
              <div>
                <p
                  style={{
                    padding: "0 0 0 1em",
                    fontWeight: "400",
                    float: "left",
                    lineHeight: "1.45em",
                    fontSize: "18px",
                    color: "#000",
                  }}
                >
                  Need updates about new venues and ideas for your event.
                </p>
              </div>
            </div>
            <div style={{ textAlign: "end" }}>
              <Button style={{ border: "none" }} variant="secondary" onClick={handleClose}>
                NO THANKS
              </Button>
              <Button
                style={{
                  padding: "0.75em 1.5em",
                  fontSize: "1em",
                  borderRadius: "0.25em",
                  fontWeight: "400",
                  boxShadow: "unset",
                  display: "-ms-flexbox",
                  backgroundColor: "blue",
                  color: "#fff",
                }}
                variant="primary"
                onClick={onSubscribeClick}
              >
                YES
              </Button>
            </div>
          </div>
        </div>
      )}
      {/* </Modal> */}
    </>
  );
}
