import React from "react";
import {render} from "react-dom";
import config from "../config/config";
import HeaderLoginSection from "../views/general/headerLoginSection";
import constants from "../config/constants";

import cookie from "react-cookies";
import IdeaApp from "../views/IdeaApp";

var sessionData = {
  cityId: 1,
  cityName: "Mumbai",
  cityUniqueLink: "mumbai",
  isEnquiryCreated: false,
  CityName: "Mumbai",
  CityUniqueLink: "mumbai",
  CityId: 1,
  CountryId: 1,
};

window.toggleSignupModalNew = function (isShowSignUpModal) {
  var filters = document.getElementById("filters").value;
  var city = pathArray[2];
  var props = {
    config: config,
    constants: constants,
    sessionData: sessionData,
    occasionList: global.initState.occasionList,
    areaList: global.initState.areaList,
    citySelected: {city},
    cityList: global.initState.cityList,
    pageType: pageType,
    filters: JSON.parse(filters),
    callType: "login",
    isShowSignUpModal: isShowSignUpModal,
  };
  myReactDOM2.unmountComponentAtNode(document.getElementById("login"));
  myReactDOM2.render(
    myReact2.createElement(HeaderLoginSection, props),
    document.getElementById("login")
  );
};

window.loginPopup = function (isShowLoginModal) {
  let city = pathArray[2];
  let props = {
    config: config,
    constants: constants,
    sessionData: sessionData,
    citySelected: {city},
    pageType: "homePage",
    callType: "login",
    isShowLoginModal: isShowLoginModal,
  };
  myReactDOM12.unmountComponentAtNode(document.getElementById("login"));
  myReactDOM12.render(
    myReact12.createElement(HeaderLoginSection, props),
    document.getElementById("login")
  );
};
var OneSignal = window.OneSignal || [];
OneSignal.push(function () {
  OneSignal.init({
    appId: "f16c9026-ddf6-4f6c-935b-e39ac4b90c0f",
    autoRegister: false,
    autoResubscribe: true,
    notifyButton: {
      enable: false,
      position: "bottom-left",
    },
    promptOptions: {
      actionMessage: "Need updates about new venues and ideas for your event.",
      acceptButtonText: "YES",
      cancelButtonText: "NO THANKS",
    },
  });
});
$(document).ready(function () {
  var props = window.__INITIAL_STATE__;
  global.initState = props;

  setTimeout(function () {
    var bLazy = new Blazy();
  }, 4000);
  window.recontructBlazy = function () {
    if (typeof bLazy === "undefined") {
      var bLazy = new Blazy();
    } else {
      bLazy.destroy();
      bLazy = new Blazy();
    }
  };
});

var url = window.location.href;

var pathname = new URL(url).pathname;
var pathArray = pathname.split("/");
let object3;
const cookies = document.cookie.split(";").map((cookie) => cookie.trim());
const sessionDataCookie = cookies.find((cookie) => cookie.startsWith("sessionData="));
if (sessionDataCookie) {
  const sessionData = decodeURIComponent(sessionDataCookie.split("=")[1]);
  const parsedSessionData = JSON.parse(sessionData);
  object3 = parsedSessionData.sessionData;
}

setTimeout(function () {
  render(
    <IdeaApp
      cityByIp={document.getElementById("cityByIp")?.value}
      props={config}
      cityList={global.initState?.cityList}
      areaList={global.initState?.areaList}
      occasionList={global.initState?.occasionList}
      constants={constants}
      sessionData={sessionData}
      newcitySession={sessionData}
      // citySelected={}
      pageType={global.initState.pageType}
      pageHtmlType={global.initState.pageHtmlType}
    />,
    document.getElementById("root")
  );
}, 500);
