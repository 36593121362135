var React = require("react");
var axios = require("axios");
// var generalUtil = require('../views/general/generalUtil');
// var Header = require("./general/Header");
var Slider = require("react-slick").default;
// var Footer = require("./general/Footer");
// var querystring = require("querystring");
import generalUtil from "./general/generalUtil";
import Header from "./general/Header";
// import {Slider} from 'react-slick'
import Footer from "./general/Footer";
import querystring from "querystring";
import { Spinner } from "react-bootstrap";
import { Modal } from "react-bootstrap";
if (typeof window === "undefined") {
  global.window = {};
}
const sessionData = {
  cityId: 1,
  cityName: "Mumbai",
  cityUniqueLink: "mumbai",
  isEnquiryCreated: false,
  CityName: "Mumbai",
  CityUniqueLink: "mumbai",
  CityId: 1,
  CountryId: 1,
};
class IdeaApp extends React.Component {
  constructor(props) {
    let settingsTE = {
      dots: true,
      dotsClass: "testimonialslickdots",
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    let settingsRE = {
      className: "slider variable-width",
      dots: false,
      infinite: false,
      centerMode: false,
      slidesToShow: 1,
      slidesToScroll: 6,
      variableWidth: true,
    };

    super(props);
    this.state = {
      otherProps: {
        cityList: [],
        areaList: [],
        occasionList: [],
      },
      newcitySession: sessionData,
      sessionData: sessionData,
      cityList: this.props.cityList,
      areaList: this.props.areaList,
      occasionList: this.props.occasionList,
      settingsRE,
      settingsTE,
      settingsTE,
      loading: false,
      loading1: false,
      loading2: false,
      name: "",
      email: "",
      isOpen: false,
      pageHtmlType: this.props.pageHtmlType,
    };
    this.closeModal = this.closeModal.bind(this);
    this.getAnalytics();
  }
  initialilzeoneSignal() {
    OneSignal.push(function () {
      OneSignal.showHttpPrompt();
    });
  }
  checkforsubscribtion() {
    let that = this;
    setTimeout(function () {
      if (typeof OneSignal != "undefined") {
        OneSignal.push(function () {
          OneSignal.on("popoverAllowClick", function (permissionChange) {
            OneSignal.sendTags(
              {
                city: that.state.newcitySession.CityName,
                pageUrl: "homePage",
              },
              function (tagsSent) {
                // Callback called when tags have finished sending
              }
            );
          });
        });
      }
    }, 5000);
  }
  componentDidMount() {
    let that = this;
    let OneSignal = [];
    OneSignal.push(function () {
      OneSignal.init({
        appId: "f16c9026-ddf6-4f6c-935b-e39ac4b90c0f",
        autoRegister: false,
        autoResubscribe: true,
        notifyButton: {
          enable: false,
          position: "bottom-left",
        },
        promptOptions: {
          actionMessage: "Need updates about new venues and ideas for your event.",
          acceptButtonText: "YES",
          cancelButtonText: "NO THANKS",
        },
      });
    });
    setTimeout(function () {
      {
        that.initialilzeoneSignal();
        that.checkforsubscribtion();
      }
    }, 12000);
  }
  //Calling an API
  submit() {
    let id = 10;
    switch (this.props.pageType) {
      case "corporateideas":
        id = 1;
        break;
      case "birthdayideas":
        id = 2;
        break;
      case "weddingideas":
        id = 3;
        break;
    }
    const { name, email } = this.state;
    let url = "https://api.bookeventz.com/email/ideaSectionEmails/";

    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("occasion", id);

    axios
      .post(url, formData, {
        headers: { "Content-Type": "multipart/form-data" }
      })
      .then((response) => {
        this.setState({ isOpen: true });
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
  }
  closeModal() {
    this.setState({ isOpen: false });
  }
  getAnalytics() {
    if (typeof window !== "undefined" && window.localStorage) {
      var pageType = "feedBack";
      localStorage.setItem("pageType", pageType);

      (function (i, s, o, g, r, a, m) {
        i["GoogleAnalyticsObject"] = r;
        (i[r] =
          i[r] ||
          function () {
            (i[r].q = i[r].q || []).push(arguments);
          }),
          (i[r].l = 1 * new Date());
        (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m);
      })(window, document, "script", "//www.google-analytics.com/analytics.js", "ga");

      ga("create", "UA-39073605-1", {
        siteSpeedSampleRate: 30,
      });
      ga("send", "pageview");
    }
  }
  render() {
    return (
      <div className="App homeView HomeViewNew">
        <div id="header">
          <Header
            props={this.props}
            otherProps={this.state}
            newcitySession={sessionData}
            userData={this.state.userData}
            sessionData={sessionData}
            cityList={this.props.cityList}
            areaList={this.props.areaList}
            pageType={this.props.pageType}
            occasionList={this.props.occasionList}
          />
        </div>
        {/* //Corporate Component */}
        {this.state.pageHtmlType == "corporateideas" ? (
          <div className="main-container">
            <div className="topSpace"></div>
            <div id="corporate-container">
              <div className="row">
                <div className="col-sm-6 col-xs-12 text-center">
                  <img
                    className="imageWrap"
                    src="https://media.bookeventz.com/html/bookeventz.com/asset/images/Corporate-Guide.png"
                    alt="Nothing just a birthday pic"
                  />
                </div>
                <div className="col-sm-5 col-xs-12 text-center">
                  <div>
                    <h1 className="guideTitle">FREE CORPORATE EVENT GUIDE</h1>
                    <div className="guideSubTitle">UNVEIL THE TO DO LIST FOR</div>
                    <div className="guideSubTitle2">A SUCCESSFUL CORPORATE EVENT</div>
                  </div>
                  <div>
                    <form id="submitForm">
                      <div>
                        <input
                          id="firstName"
                          className="text_field"
                          type="text"
                          name="name"
                          value={this.state.name}
                          onChange={(data) => {
                            this.setState({ name: data.target.value });
                          }}
                          placeholder="Your First Name Here..."
                        />
                        <br />
                        <input
                          id="email"
                          className="text_field2"
                          type="text"
                          name="email"
                          value={this.state.email}
                          onChange={(data) => {
                            this.setState({ email: data.target.value });
                          }}
                          placeholder="Your Email Address Here..."
                        />
                      </div>
                      <br />
                      <button
                        className="submit-btn"
                        type="button"
                        onClick={() => {
                          this.submit();
                        }}
                        disabled={this.loading}
                      >
                        {this.state.loading && (
                          <Spinner
                            animation="border"
                            size="sm"
                            role="status"
                            style={{ marginRight: "10px" }}
                          ></Spinner>
                        )}
                        {this.state.loading && "Sending"}
                        {!this.state.loading && "Yes! Email Me the Guide"}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {/* Modal section here */}
        <Modal
          show={this.state.isOpen}
          onHide={this.closeModal}
          style={{ boxShadow: "0 5px 15px rgba(0,0,0,.5)" }}
        >
          <Modal.Header style={{ backgroundColor: "#e52878" }} closeButton></Modal.Header>
          <Modal.Body style={{ textAlign: "center", fontSize: "16px", color: "#555" }}>
            Your guide has been successfully mailed to you
          </Modal.Body>
        </Modal>
        {/* Modal Section Ends Here */}
        {/* // BIRTHDAY SECTION */}
        {this.state.pageHtmlType == "birthdayideas" ? (
          <div className="main-container">
            <div className="topSpace"></div>
            <div id="birthday-container">
              <div className="row">
                <div className="col-sm-6 col-xs-12 text-center">
                  <img
                    className="imageWrap"
                    src="https://media.bookeventz.com/html/bookeventz.com/asset/images/Birthday_Guide.jpg"
                    alt="Nothing just a birthday pic"
                  />
                </div>
                <div className="col-sm-5 col-xs-12 text-center">
                  <div>
                    <h1 className="guideTitle">FREE BIRTHDAY PARTY GUIDE</h1>
                    <div className="guideSubTitle">CHECK OUT EXHAUSTIVE BIRTHDAY GUIDE</div>
                    <div className="guideSubTitle2">FOR A COMPLETE BIRTHDAY PARTY</div>
                  </div>
                  <div>
                    <form id="submitForm">
                      <div>
                        <input
                          id="firstName"
                          className="text_field"
                          type="text"
                          name="name"
                          value={this.state.name}
                          onChange={(data) => {
                            this.setState({ name: data.target.value });
                          }}
                          placeholder="Your First Name Here..."
                        />
                        <br />
                        <input
                          id="email"
                          className="text_field2"
                          type="text"
                          name="email"
                          value={this.state.email}
                          onChange={(data) => {
                            this.setState({ email: data.target.value });
                          }}
                          placeholder="Your Email Address Here..."
                        />
                      </div>
                      <br />
                      <button
                        className="submit-btn"
                        type="button"
                        onClick={() => {
                          this.submit();
                        }}
                        disabled={this.loading}
                      >
                        {this.state.loading && (
                          <Spinner
                            animation="border"
                            size="sm"
                            role="status"
                            style={{ marginRight: "10px" }}
                          ></Spinner>
                        )}
                        {this.state.loading && "Sending"}
                        {!this.state.loading && "Yes! Email Me the Guide"}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {/* // WEDDING SECTION */}
        {this.state.pageHtmlType == "weddingideas" ? (
          <div className="main-container">
            <div className="topSpace"></div>
            <div id="wedding-container">
              <div className="row">
                <div className="col-sm-6 col-xs-12 text-center">
                  <img
                    className="imageWrap"
                    src="https://media.bookeventz.com/html/bookeventz.com/asset/images/WeddingGuideDesktop.png"
                    alt="Nothing just a Wedding pic"
                  />
                </div>
                <div className="col-sm-5 col-xs-12 text-center">
                  <div>
                    <h1 className="guideTitle">FREE WEDDING GUIDE</h1>
                    <div className="guideSubTitle">UNFOLD THE SECRET STEPS</div>
                    <div className="guideSubTitle2">TO AN ENTHRALLING WEDDING EVENT</div>
                  </div>
                  <div>
                    <form id="submitForm">
                      <div>
                        <input
                          id="firstName"
                          className="text_field"
                          type="text"
                          name="name"
                          value={this.state.name}
                          onChange={(data) => {
                            this.setState({ name: data.target.value });
                          }}
                          placeholder="Your First Name Here..."
                        />
                        <br />
                        <input
                          id="email"
                          className="text_field2"
                          type="text"
                          name="email"
                          value={this.state.email}
                          onChange={(data) => {
                            this.setState({ email: data.target.value });
                          }}
                          placeholder="Your Email Address Here..."
                        />
                      </div>
                      <br />
                      <button
                        className="submit-btn"
                        type="button"
                        onClick={() => {
                          this.submit();
                        }}
                        disabled={this.loading}
                      >
                        {this.state.loading && (
                          <Spinner
                            animation="border"
                            size="sm"
                            role="status"
                            style={{ marginRight: "10px" }}
                          ></Spinner>
                        )}
                        {this.state.loading && "Sending"}
                        {!this.state.loading && "Yes! Email Me the Guide"}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <div id="loginheader"></div>
        <Footer
          props={this.props}
          otherProps={this.state}
          newcitySession={this.state.sessionData}
          userData={this.state.userData}
          sessionData={this.state.sessionData}
          cityList={this.props.cityList}
          areaList={this.props.areaList}
          pageType={this.props.pageType}
          occasionList={this.props.occasionList}
        />
      </div>
    );
  }
}
export default IdeaApp;
