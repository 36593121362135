import React, { useEffect, useState } from "react";
import CityList from "../cityList";
import GoogleTranslateWidget from "../GoogleTranslateWidget";
import { callGa } from "../generalUtil";

const DesktopHeader = ({ props, toggleLoginModalNew, logoutNew, sessionData, vendorData, nearAreas, cityArea, sepcial }) => {
  const [section, setSection] = useState(null);
  const [options, setOptions] = useState(false);
  const [cityPopup, setCityPopup] = useState(false);
  useEffect(() => { }, [props]);
  const handleNavSections = (index) => {
    if (index == "close") {
      setSection(null);
    } else {
      setSection(index);
    }
  };
  const checkSessionData = () => {
    if (sessionData && "userName" in sessionData && sessionData?.userName) {
      setOptions(true);
    } else {
      toggleLoginModalNew(true);
    }
  };
  const handleCityPopup = () => {
    setCityPopup(!cityPopup);
  };
  return (
    <>
      <div id="header_desktop_wrapper">
        {props?.pageType !== "venueProduct" && (
          <div id="hd_top">
            <a
              href={`https://www.bookeventz.com/`}
              target="_blank"
              style={{
                color: "inherit",
                textDecoration: "none",
              }}
              rel="noopener noreferrer"
            >
              <img
                src="https://media.bookeventz.com/html/bookeventz.com/asset/images/bzMainLogo.svg"
                alt="Bookeventz"
                id="bkz_logo"
                fetchpriority="high"
                height="24"
                width="161"
                data-cache-control="public, max-age=31536000"
              />
            </a>
            <div id="last_3">
              <span id="city_control" onClick={handleCityPopup}>
                {props?.sessionData?.CityName ? props?.sessionData?.CityName : "Mumbai"}
                <img
                  src="https://media.bookeventz.com/html/bookeventz.com/images/headerFooterIcons/dropDown.svg"
                  alt="Drop down "
                  fetchpriority="low"
                  data-cache-control="public, max-age=31536000"
                  width="12"
                  height="7"
                />
              </span>
              {(props.pageType === "homeBlogListing" || props.pageType === "categoryBlogListing" || props.pageType === "authorBlogListing" || props.pageType === "tagBlogListing") && (
                <div className="city-change lang" id="change_blog_language">
                  <GoogleTranslateWidget />
                </div>
              )}

              {props?.sessionData?.CityId && props?.sessionData?.CityId == "1" && (
                <span id="city_controll">
                  <img
                    src="https://media.bookeventz.com/html/bookeventz.com/images/headerFooterIcons/phone.svg"
                    alt="Call"
                    fetchpriority="low"
                    data-cache-control="public, max-age=31536000"
                    width="20"
                    height="20"
                  />
                  99675 81110
                </span>
              )}
              <spam id="divio"></spam>
              <span id="city_controlll" onClick={() => checkSessionData()}>
                <img
                  src="https://media.bookeventz.com/html/bookeventz.com/images/headerFooterIcons/circle.svg"
                  alt="Profile"
                  fetchpriority="low"
                  data-cache-control="public, max-age=31536000"
                  width="20"
                  height="20"
                />
                {sessionData && "userName" in sessionData ? sessionData?.userName : "LOGIN"}
              </span>
              {options && (
                <ul onMouseLeave={() => setOptions(false)}>
                  <li>
                    <a
                      href="https://www.bookeventz.com/user-bookings"
                      target="_blank"
                      style={{
                        color: "inherit",
                        textDecoration: "none",
                      }}
                      rel="noopener noreferrer"
                    >
                      <span>My Profile</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.bookeventz.com/user-bookings"
                      target="_blank"
                      style={{
                        color: "inherit",
                        textDecoration: "none",
                      }}
                      rel="noopener noreferrer"
                    >
                      <span>My Event Enquiries</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.bookeventz.com/user-bookings"
                      target="_blank"
                      style={{
                        color: "inherit",
                        textDecoration: "none",
                      }}
                      rel="noopener noreferrer"
                    >
                      <span>My Bookings</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.bookeventz.com/user-bookings"
                      target="_blank"
                      style={{
                        color: "inherit",
                        textDecoration: "none",
                      }}
                      rel="noopener noreferrer"
                    >
                      <span>My Reviews</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href={`https://www.bookeventz.com/shortlistedVendors/${sessionData?.CityUniqueLink}`}
                      target="_blank"
                      style={{
                        color: "inherit",
                        textDecoration: "none",
                      }}
                      rel="noopener noreferrer"
                    >
                      <span>Shortlisted Vendors</span>
                    </a>
                  </li>
                  <li onClick={logoutNew}>
                    <span>Logout</span>
                  </li>
                </ul>
              )}
            </div>
          </div>
        )}
        <div id="hd_bottom">
          {props?.NewsessionData !== null && props?.NewsessionData !== undefined && props?.NewsessionData?.CityUniqueLink == "destination-wedding" ? (
            <span id="city_control_">
              <a href={`https://www.bookeventz.com/banquets/${props?.NewsessionData?.CityUniqueLink}`} target="_blank">
                Venues
              </a>
            </span>
          ) : (
            <span id="city_control_" onMouseEnter={() => handleNavSections(0)}>
              Venues
              <img src="https://media.bookeventz.com/html/bookeventz.com/images/headerFooterIcons/w_arrow.svg" alt="Profile" fetchpriority="low" data-cache-control="public, max-age=31536000" />
              {section == 0 && (
                <div id="venues_bar" onMouseEnter={() => handleNavSections(0)} onMouseLeave={() => handleNavSections("close")}>
                  <span id="dviua" className="dviua1"></span>
                  <span id="trianga"></span>
                  <div id={sepcial !== null && sepcial !== undefined && sepcial.length > 0 ? "fpoa" : "no_fpoa"}>
                    <ul class={sepcial !== null && sepcial !== undefined && sepcial.length > 0 ? "" : "gts"}>
                      <span id="spinca">By Type</span>
                      {props?.sessionData?.CityId == 1 && (
                        <li>
                          <a
                            href={`https://www.bookeventz.com/banquets/bookeventz-venues/${props?.sessionData?.CityUniqueLink}`}
                            target="_blank"
                            style={{
                              color: "inherit",
                              textDecoration: "none",
                            }}
                            rel="noopener noreferrer"
                          >
                            Bookeventz venues
                          </a>
                        </li>
                      )}
                      <li>
                        <a
                          href={`https://www.bookeventz.com/banquets/${props?.sessionData?.CityUniqueLink}`}
                          target="_blank"
                          style={{
                            color: "inherit",
                            textDecoration: "none",
                          }}
                          rel="noopener noreferrer"
                        >
                          banquet halls in {props?.sessionData?.CityName}
                        </a>
                      </li>
                      <li>
                        <a
                          href={`https://www.bookeventz.com/banquets/${props?.sessionData?.CityUniqueLink}/wedding`}
                          target="_blank"
                          style={{
                            color: "inherit",
                            textDecoration: "none",
                          }}
                          rel="noopener noreferrer"
                        >
                          wedding venues in {props?.sessionData?.CityName}
                        </a>
                      </li>
                      <li>
                        <a
                          href={`https://www.bookeventz.com/banquets/${props?.sessionData?.CityUniqueLink}/birthday-party`}
                          target="_blank"
                          style={{
                            color: "inherit",
                            textDecoration: "none",
                          }}
                          rel="noopener noreferrer"
                        >
                          birthday party halls in {props?.sessionData?.CityName}
                        </a>
                      </li>
                      <li>
                        <a
                          href={`https://www.bookeventz.com/banquets/${props?.sessionData?.CityUniqueLink}/5-star-hotels`}
                          target="_blank"
                          style={{
                            color: "inherit",
                            textDecoration: "none",
                          }}
                          rel="noopener noreferrer"
                        >
                          5 Star Banquet Halls in {props?.sessionData?.CityName}
                        </a>
                      </li>
                      {props?.sessionData?.CityId == 1 && (
                        <li id="mc_donald">
                          <a
                            href={`https://www.bookeventz.com/${props?.sessionData?.CityUniqueLink}/mcdonalds-birthday-party`}
                            target="_blank"
                            style={{
                              color: "inherit",
                              textDecoration: "none",
                            }}
                            rel="noopener noreferrer"
                          >
                            McDonalds Party Section
                          </a>
                        </li>
                      )}
                    </ul>
                    {sepcial !== null && sepcial !== undefined && sepcial.length > 0 && (
                      <ul id="special_ocassion">
                        {sepcial !== null &&
                          sepcial !== undefined &&
                          sepcial.length > 0 &&
                          sepcial?.map((i, index) => {
                            return (
                              <li key={index}>
                                <a
                                  href={`https://www.bookeventz.com/banquets/${cityArea}/${i.link}`}
                                  target="_blank"
                                  style={{
                                    color: "inherit",
                                    textDecoration: "none",
                                  }}
                                  rel="noopener noreferrer"
                                >
                                  {i.name}
                                </a>
                              </li>
                            );
                          })}
                      </ul>
                    )}
                  </div>
                  <div id="seco_part">
                    <span id="spinca" style={{ marginBottom: "8px" }}>
                      By Area
                    </span>
                    <ul>
                      {props?.areaList &&
                        props.areaList.length > 0 &&
                        props.areaList.map((area, index) => {
                          return (
                            Number(area.isPopular) === 1 && (
                              <li key={index}>
                                <a
                                  href={`https://www.bookeventz.com/banquets/${props?.sessionData?.CityUniqueLink}/${area.AreaUniqueLink}`}
                                  target="_blank"
                                  style={{
                                    color: "inherit",
                                    textDecoration: "none",
                                  }}
                                  rel="noopener noreferrer"
                                >
                                  {area.AreaName}
                                </a>
                              </li>
                            )
                          );
                        })}
                    </ul>
                  </div>
                  {nearAreas !== null && nearAreas?.length > 0 && (
                    <div id="seco_part" className="droid">
                      <span id="spinca" style={{ marginBottom: "8px" }}>
                        Nearby Areas
                      </span>
                      <ul>
                        {nearAreas !== null &&
                          nearAreas?.length > 0 &&
                          nearAreas?.map((area, index) => {
                            return (
                              <li key={index}>
                                <a
                                  href={`https://www.bookeventz.com/banquets/${props?.sessionData?.CityUniqueLink}/${area.AreaUniqueLink}`}
                                  target="_blank"
                                  style={{
                                    color: "inherit",
                                    textDecoration: "none",
                                  }}
                                  rel="noopener noreferrer"
                                >
                                  {area.AreaName}
                                </a>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  )}
                  {props?.occasionList &&
                    props?.occasionList.length > 0 &&
                    <div id="seco_part">
                      <span id="spinca" style={{ marginBottom: "8px" }}>
                        By Occasion
                      </span>
                      <ul>
                        {props?.occasionList &&
                          props?.occasionList.length > 0 &&
                          props?.occasionList.map((o, index) => {
                            return (
                              Number(o.type) == 1 && (
                                <li key={index}>
                                  <a
                                    href={`https://www.bookeventz.com/banquets/${cityArea}/${o.uniqueLink}`}
                                    target="_blank"
                                    style={{
                                      color: "inherit",
                                      textDecoration: "none",
                                    }}
                                    rel="noopener noreferrer"
                                  >
                                    {o.name}
                                  </a>
                                </li>
                              )
                            );
                          })}
                      </ul>
                    </div>
                  }
                </div>
              )}
            </span>
          )}
          {vendorData !== null && vendorData !== undefined && vendorData.length > 0 && (
            <span id="city_control_" onMouseEnter={() => handleNavSections(1)}>
              Vendors
              <img src="https://media.bookeventz.com/html/bookeventz.com/images/headerFooterIcons/w_arrow.svg" alt="Profile" fetchpriority="low" data-cache-control="public, max-age=31536000" />
              {section == 1 && (
                <div id="venues_bar" onMouseEnter={() => handleNavSections(1)} onMouseLeave={() => handleNavSections("close")} className="vendor_section_76">
                  <span id="dviua" className="dviua2"></span>
                  <span id="trianga"></span>
                  <div id="fpoa">
                    <ul>
                      {vendorData &&
                        vendorData?.map((vendor, index) => {
                          let new_id = parseInt(vendor?.id);
                          let link = `https://www.bookeventz.com/${vendor?.uniqueLink}/${props?.sessionData?.CityUniqueLink}`;
                          switch (new_id) {
                            case 6:
                              link = `https://www.bookeventz.com/choreographer/${props?.sessionData?.CityUniqueLink}`;
                              break;
                            case 2:
                              link = `https://www.bookeventz.com/bridal-makeups/${props?.sessionData?.CityUniqueLink}`;
                              break;
                            case 1:
                            case 3:
                            case 15:
                              link = `https://www.bookeventz.com/${vendor?.uniqueLink}s/${props?.sessionData?.CityUniqueLink}`;
                              break;
                            case 7:
                              link = `https://www.bookeventz.com/artists/comedian-in-india`;
                              break;
                            case 11:
                              link = `https://www.bookeventz.com/artists/anchors-in-india`;
                              break;
                            default:
                          }
                          return (
                            <li key={index}>
                              <a
                                href={link}
                                target="_blank"
                                style={{
                                  color: "inherit",
                                  textDecoration: "none",
                                }}
                                rel="noopener noreferrer"
                              >
                                {vendor?.name}
                              </a>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              )}
            </span>
          )}
          <span id="city_control_">
            <a
              href={`https://www.bookeventz.com/blog`}
              target="_blank"
              style={{
                color: "inherit",
                textDecoration: "none",
                cursor: "pointer",
              }}
              rel="noopener noreferrer"
            >
              Blog
            </a>
            {/* <img src="https://media.bookeventz.com/html/bookeventz.com/images/headerFooterIcons/w_arrow.svg" alt="Profile" fetchpriority="low" data-cache-control="public, max-age=31536000" /> */}
          </span>
          <span id="city_control_" onMouseEnter={() => handleNavSections(3)}>
            Invites
            <img src="https://media.bookeventz.com/html/bookeventz.com/images/headerFooterIcons/w_arrow.svg" alt="Profile" fetchpriority="low" data-cache-control="public, max-age=31536000" />
            {section == 3 && (
              <div id="venues_bar" onMouseEnter={() => handleNavSections(3)} onMouseLeave={() => handleNavSections("close")} className="vendor_section_78">
                <span id="dviua" className="dviua4"></span>
                <span id="trianga"></span>
                <div id="fpoad">
                  <ul>
                    <li>
                      <a
                        href={`https://www.bookeventz.com/invites/wedding-invitation-templates`}
                        target="_blank"
                        style={{
                          color: "inherit",
                          textDecoration: "none",
                        }}
                        rel="noopener noreferrer"
                      >
                        Wedding
                      </a>
                    </li>
                    <li>
                      <a
                        href={`https://www.bookeventz.com/invites/birthday-invitation-templates`}
                        target="_blank"
                        style={{
                          color: "inherit",
                          textDecoration: "none",
                        }}
                        rel="noopener noreferrer"
                      >
                        Birthday party
                      </a>
                    </li>
                    <li>
                      <a
                        href={`https://www.bookeventz.com/invites/corporate-event-invitation-templates`}
                        target="_blank"
                        style={{
                          color: "inherit",
                          textDecoration: "none",
                        }}
                        rel="noopener noreferrer"
                      >
                        Corporate
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </span>
          <span id="city_control_" onMouseEnter={() => handleNavSections(4)}>
            Ideas
            <img src="https://media.bookeventz.com/html/bookeventz.com/images/headerFooterIcons/w_arrow.svg" alt="Profile" fetchpriority="low" data-cache-control="public, max-age=31536000" />
            {section == 4 && (
              <div id="venues_bar" onMouseLeave={() => handleNavSections("close")} onMouseEnter={() => handleNavSections(4)} className="vendor_section_79">
                <span id="dviua" className="dviua5"></span>
                <span id="trianga"></span>
                <div id="fpoad">
                  <ul>
                    <li>
                      <a
                        href={`https://weddingideas.bookeventz.com/`}
                        target="_blank"
                        style={{
                          color: "inherit",
                          textDecoration: "none",
                        }}
                        rel="noopener noreferrer"
                      >
                        wedding
                      </a>
                    </li>
                    <li>
                      <a
                        href={`https://birthdayideas.bookeventz.com/`}
                        target="_blank"
                        style={{
                          color: "inherit",
                          textDecoration: "none",
                        }}
                        rel="noopener noreferrer"
                      >
                        birthday party
                      </a>
                    </li>
                    <li>
                      <a
                        href={`https://corporateeventideas.bookeventz.com/`}
                        target="_blank"
                        style={{
                          color: "inherit",
                          textDecoration: "none",
                        }}
                        rel="noopener noreferrer"
                      >
                        corporate party
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </span>
          <span id="city_control_" onMouseEnter={() => handleNavSections(5)}>
            Corporate
            <img src="https://media.bookeventz.com/html/bookeventz.com/images/headerFooterIcons/w_arrow.svg" alt="Profile" fetchpriority="low" data-cache-control="public, max-age=31536000" />
            {section == 5 && (
              <div id="venues_bar" onMouseLeave={() => handleNavSections("close")} onMouseEnter={() => handleNavSections(5)} className="vendor_section_80">
                <span id="dviua" className="dviua6"></span>
                <span id="trianga"></span>
                <div id="fpoad">
                  <ul>
                    <li>
                      <a
                        href={`https://www.bookeventz.com/build-your-event-website`}
                        target="_blank"
                        style={{
                          color: "inherit",
                          textDecoration: "none",
                        }}
                        rel="noopener noreferrer"
                      >
                        build event microwebsite
                      </a>
                    </li>
                    <li>
                      <a
                        href={`https://www.bookeventz.com/corporate-event-planning-portal`}
                        target="_blank"
                        style={{
                          color: "inherit",
                          textDecoration: "none",
                        }}
                        rel="noopener noreferrer"
                      >
                        corporate event services
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </span>
          <span className="crigo">
            <a
              href={`https://www.bookeventz.com/why-list-with-us`}
              target="_blank"
              style={{
                color: "inherit",
                textDecoration: "none",
              }}
              rel="noopener noreferrer"
            >
              List with us
            </a>
          </span>
        </div>
      </div>
      {cityPopup && (
        <CityList
          props={props}
          sessionData={props?.sessionData}
          citySelected={props?.sessionData?.CityUniqueLink ? props?.sessionData?.CityUniqueLink : "mumbai"}
          newcitySession={props?.sessionData}
          cityList={props?.cityList}
          pageType={props?.pageType}
          handleCityPopup={handleCityPopup}
        />
      )}
    </>
  );
};

export default DesktopHeader;
