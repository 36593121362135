"use strict";

import React, {Component} from "react";

import FacebookLogin from "react-facebook-login"; //FacebookLogin: React Facebook login (https://www.npmjs.com/package/react-facebook-login)
import axios from "axios"; //axios: React Ajax Call (https://www.npmjs.com/package/axios)
import querystring from "querystring";
import {Modal} from "react-bootstrap";
import {ThreeBounce} from "better-react-spinkit";

import generalUtil from "./generalUtil";
import localStorageUtil from "./localstorage";
import cookie from "react-cookies";

const loaderStyle = {
  color: "#4a266e",
};
var environment = "production";
if (environment == "development") {
  var API_BASE = "https://apidemo.bookeventz.com/";
  var WEB_BASE = "https://staging.bookeventz.com/";
} else {
  var API_BASE = "https://api.bookeventz.com/";
  var WEB_BASE = "https://www.bookeventz.com/";
}
/*
 * Facebook Login Component. On button click data is fetched from facebook and accordingly user session is created
 */
class BzFacebookLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userEmail: "",
      userName: "",
      userMobileNo: "",
      registrationType: "1",
      pictureUrl: "",
      show: false,
      userMobileNoError: "",
      mobileNoMandatory: "",
      modalHideCounter: 0,
      fatalError: "",
      socialId: "",
      noUserMobileCase: false,
      noUserEmailCase: false,
    };
    this.handleClose = this.handleClose.bind(this);
  }
  handleClose() {
    this.setState({successfull: false});
    location.reload();
  }
  //Handles button clicked
  componentClicked(response) {
    //Different category for contractorLanding and Listing page
    /* if(this.props.pageType == 'ContractorPackageListing' || this.props.pageType == 'ContractorPackagesLanding')
         {
             generalUtil.callGa('vendor popup ', 'clicked submit', 'Facebook');
         }
         else if(this.props.extraParam != undefined && this.props.extraParam == true)
         {
             generalUtil.callGa('vendor page atc/en', 'clicked submit', 'Facebook');
         }
         else
         {
             generalUtil.callGa(generalUtil.safeReturn(this.props, 'pageType', '') + ' slider', 'submit', 'Facebook');
         }
 */

    this.setState({showLoader: true});
  }

  //callBack function after facebook login.
  responseFacebook(response) {
    var showLoader5 = document.getElementById("showLoader5");
    if (showLoader5 != undefined) {
      showLoader5.setAttribute("style", "display: block;");
      document.getElementById("showLoader5").style.display = "block";
    }

    var that = this;
    if (typeof response["email"] != "undefined" || typeof response["userID"] != "undefined") {
      generalUtil.callGa("Login Menu", "LoggedIn", "Facebook");
      localStorageUtil.setLocal(
        "isSocialLogin",
        generalUtil.safeReturn(this.props.constants, "ACTIVE", "")
      );
      let picture = "";
      if (
        typeof response["picture"] != "undefined" &&
        typeof response["picture"]["data"] != "undefined" &&
        typeof response["picture"]["data"]["url"] != "undefined"
      ) {
        picture = "https://graph.facebook.com/" + response["userID"] + "/picture?type=large";
      }

      this.setState(
        {
          userEmail: generalUtil.safeReturn(response, "email", ""),
          userName: generalUtil.safeReturn(response, "name", ""),
          pictureUrl: picture,
          showLoader: true,
          socialId: generalUtil.safeReturn(response, "userID", ""),
        },
        function () {
          that.checkUser();
        }
      );
    } else {
      this.setState({fatalError: "Error in Facebook Login", showLoader: false});
    }
  }

  /*Check user in DB if exists create session or if does not exist check for mobile no in
    state if exists then insert data into DB and then create session or else show modal to get mobile no.*/
  checkUser() {
    var that = this;

    if (this.state.showLoader == false) {
      this.setState({showLoader: true});
    }

    var loader = document.getElementById("spinner");
    if (loader != undefined) {
      loader.setAttribute("style", "display: block;");
    }
    var showloader = document.getElementById("showLoader");
    if (showloader != undefined) {
      showloader.setAttribute("style", "display: block;");
    }
    var showloader = document.getElementById("showLoaderen");
    if (showloader != undefined) {
      showloader.setAttribute("style", "display: block;");
    }
    var ppLoader = document.getElementById("showLoaderRL");
    if (ppLoader != undefined) {
      document.getElementById("showLoaderRL").style.display = "block";
    }
    axios
      .post(
        WEB_BASE + "login/socialLoginCheck/json",
        querystring.stringify({
          userEmail: this.state.userEmail,
          userName: this.state.userName,
          userMobileNo: this.state.userMobileNo,
          registrationType: this.state.registrationType,
          pictureUrl: this.state.pictureUrl,
          socialId: this.state.socialId,
        })
      )
      .then((xhrResponse) => {
        if (generalUtil.safeReturn(xhrResponse, "data", false) !== false) {
          if (typeof xhrResponse.data.error != undefined && xhrResponse.data.error == "7") {
            if (this.state.userMobileNo == "") {
              if (
                this.props.pageType == "home" ||
                this.props.pageType == "venueListing" ||
                this.props.pageType == "venueProduct"
              ) {
                this.setState({show: true});
              }
              return false;
            }
          }
          let openShow = 0;
          localStorageUtil.setLocal("sessionData", xhrResponse.data, 186000);
          cookie.save("sessionData", xhrResponse.data, {path: "/"});

          let userMobileNo = this.state.userMobileNo;
          if (xhrResponse.data.userMobileNo != "") {
            userMobileNo = xhrResponse.data.userMobileNo;
          }
          if (this.props.sessionData.CityId != undefined) {
            var ajaxData = {
              guestName: xhrResponse.data.userName,
              guestEmail: xhrResponse.data.userEmail,
              guestMobileNo: userMobileNo,
              cityId: that.props.sessionData.CityId,
              token: that.props.token,
            };
          } else {
            var ajaxData = {
              guestName: xhrResponse.data.userName,
              guestEmail: xhrResponse.data.userEmail,
              guestMobileNo: userMobileNo,
              cityId: that.props.sessionData.cityId,
              token: that.props.token,
            };
          }

          if (
            that.props.pageType == "marketReview" ||
            that.props.pageType == "market" ||
            that.props.pageType == "cartPage"
          ) {
            openShow++;
            var sessionData = cookie.load("sessionData", xhrResponse.data);
            var userData = xhrResponse.data;
            cookie.save("userData", userData, {path: "/"});
            location.reload();
          }

          let data = xhrResponse.data;
          cookie.save("sessionData", data, {path: "/"});

          /*if(that.props.pageType == 'market')
                    {
                        var sessionData = cookie.load('sessionData',data.sessionData);
                        var userData = data.sessionData;
                        cookie.save('userData',userData,{path: '/' });
                        location.reload();
                    }*/

          if (that.props.callType != undefined) {
            if (that.props.callType == "login") {
              openShow++;
              var sessionData = cookie.load("sessionData", data.sessionData);
              var userData = data.sessionData;
              cookie.save("userData", userData, {path: "/"});
              location.reload();
            }
          }

          if (that.props.reviewLogin != undefined) {
            openShow++;
            var sessionData = cookie.load("sessionData", data.sessionData);
            var userData = data.sessionData;
            cookie.save("userData", userData, {path: "/"});
            that.setState({show: false}, () => {
              //notify parent with final data
              that.props.response({
                userEmail: data.userEmail,
                userId: data.userId,
                userMobileNo: data.userMobileNo,
                userName: data.userName,
              });
            });

            location.reload();
          }

          if (this.props.vendorDetails != undefined || this.props.pageType == "vendorPage") {
            let eventId = "";
            let functionId = "";
            openShow++;
            $.ajax({
              type: "POST",
              dataType: "json",
              url: API_BASE + "events/createEventOnly",
              data: ajaxData,
              success: function (response) {
                localStorageUtil.setLocal("eventId", response.eventId, 186000);
                cookie.save("eventId", response.eventId);
                var ajaxData2 = {
                  eventId: response.eventId,
                  token: that.props.token,
                };
                eventId = response.eventId;

                if (typeof response.eventId != "undefined") {
                  $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: API_BASE + "functions/createFunctionOnly",
                    data: ajaxData2,
                    success: function (response2) {
                      that.setState({functionId: response2.functionId});
                      cookie.save("functionId", response2.functionId);
                      localStorageUtil.setLocal("functionId", response2.functionId, 186000);
                      var enquiryData = localStorageUtil.getLocal("enquiryParams");
                      if (
                        that.props.banquetDetails != undefined &&
                        that.props.banquetDetails != ""
                      ) {
                        var ajaxData3 = {
                          functionMasterId: response2.functionId,
                          token: that.props.token,
                          status: 14,
                          artistType:
                            that.props.banquetDetails.artistType != undefined
                              ? that.props.banquetDetails.artistType.id
                              : that.props.banquetDetails.categories,
                          artistId: that.props.banquetDetails.id,
                          service: enquiryData.service != undefined ? enquiryData.service : "",
                          duration: enquiryData.duration != undefined ? enquiryData.duration : "",
                          community:
                            enquiryData.community != undefined ? enquiryData.community : "",
                          side: enquiryData.side != undefined ? enquiryData.side : "",
                          budget: enquiryData.budget != undefined ? enquiryData.budget : "",
                          budgetDiff:
                            enquiryData.budgetDiff != undefined ? enquiryData.budgetDiff : "",
                          eventDate:
                            enquiryData.eventDate != undefined ? enquiryData.eventDate : "",
                          comments: enquiryData.comments != undefined ? enquiryData.comments : "",
                        };
                        $.ajax({
                          type: "POST",
                          dataType: "json",
                          url: API_BASE + "contractor/createArtistLead",
                          data: ajaxData3,
                          success: function (response3) {
                            localStorageUtil.setLocal("showPopup", true, 186000);
                            if (
                              that.props.banquetDetails != undefined &&
                              that.props.banquetDetails != ""
                            ) {
                              var filters = {};
                              filters.token = that.props.token;
                              filters.leadId = response.eventId;
                              filters.status = "2";
                              filters.artists = that.props.banquetDetails.id;
                              filters.insertedBy = "1";
                              filters.functionId = response2.functionId;
                              axios
                                .post(
                                  API_BASE + "artist/insertArtistMapping",
                                  querystring.stringify(filters)
                                )
                                .then((xhrResponse) => {
                                  if (xhrResponse.data != undefined) {
                                    if (xhrResponse.data.inserted == true) {
                                      var shortlisted = that.state.shortlisted;
                                      var shortlistedList = that.state.shortlistedList;

                                      if (shortlisted != undefined) {
                                        shortlisted.push(that.props.banquetDetails.id);
                                      }

                                      if (shortlistedList != undefined) {
                                        shortlistedList.push(that.props.banquetDetails);
                                      }

                                      that.setState({shortlisted: shortlisted});

                                      localStorageUtil.setLocal("shortlisted", shortlisted, 870000);
                                      localStorageUtil.setLocal(
                                        "shortlistedList",
                                        shortlistedList,
                                        870000
                                      );

                                      window.location.reload();
                                    }
                                  }
                                })
                                .catch((error) => {
                                  ////console.log(error);
                                });
                            } else {
                              that.setState({showPopup: false});
                              this.setState({successfull: true});
                              window.location.reload();
                            }
                          },
                        });
                      } else if (that.props.pageType == "vendorPage") {
                        var ajaxData3 = {
                          functionMasterId: response2.functionId,
                          token: that.props.token,
                          status: 14,
                          artistId: that.props.category,
                          service: enquiryData.service != undefined ? enquiryData.service : "",
                          duration: enquiryData.duration != undefined ? enquiryData.duration : "",
                          community:
                            enquiryData.community != undefined ? enquiryData.community : "",
                          side: enquiryData.side != undefined ? enquiryData.side : "",
                          budget: enquiryData.budget != undefined ? enquiryData.budget : "",
                          budgetDiff:
                            enquiryData.budgetDiff != undefined ? enquiryData.budgetDiff : "",
                          eventDate:
                            enquiryData.eventDate != undefined ? enquiryData.eventDate : "",
                          comments: enquiryData.comments != undefined ? enquiryData.comments : "",
                        };
                        $.ajax({
                          type: "POST",
                          dataType: "json",
                          url: API_BASE + "contractor/createArtistLead",
                          data: ajaxData3,
                          success: function (response3) {
                            localStorageUtil.setLocal("showPopup", true, 186000);
                            if (
                              that.props.banquetDetails != undefined &&
                              that.props.banquetDetails != ""
                            ) {
                              var filters = {};
                              filters.token = that.props.token;
                              filters.leadId = response.eventId;
                              filters.status = "2";
                              filters.artists = that.props.banquetDetails.id;
                              filters.insertedBy = "1";
                              filters.functionId = response2.functionId;
                              axios
                                .post(
                                  API_BASE + "artist/insertArtistMapping",
                                  querystring.stringify(filters)
                                )
                                .then((xhrResponse) => {
                                  if (xhrResponse.data != undefined) {
                                    if (xhrResponse.data.inserted == true) {
                                      var shortlisted = that.state.shortlisted;
                                      var shortlistedList = that.state.shortlistedList;

                                      if (shortlisted != undefined) {
                                        shortlisted.push(that.props.banquetDetails.id);
                                      }

                                      if (shortlistedList != undefined) {
                                        shortlistedList.push(that.props.banquetDetails);
                                      }

                                      that.setState({shortlisted: shortlisted});
                                      localStorageUtil.setLocal("shortlisted", shortlisted, 870000);
                                      localStorageUtil.setLocal(
                                        "shortlistedList",
                                        shortlistedList,
                                        870000
                                      );

                                      window.location.reload();
                                    }
                                  }
                                })
                                .catch((error) => {
                                  ////console.log(error);
                                });
                            } else {
                              that.setState({showPopup: false});
                              this.setState({successfull: true});
                            }
                          },
                        });
                      } else {
                        var ajaxData3 = {
                          functionMasterId: response2.functionId,
                          token: that.props.token,
                          status: 14,
                          artistType: that.props.vendorDetails.vendorType,
                          artistId: that.props.vendorDetails.id,
                        };
                        $.ajax({
                          type: "POST",
                          dataType: "json",
                          url: API_BASE + "contractor/createArtistLead",
                          data: ajaxData3,
                          success: function (response3) {
                            localStorageUtil.setLocal("showPopup", true, 186000);
                            if (
                              that.props.banquetDetails != undefined &&
                              that.props.banquetDetails != ""
                            ) {
                              var filters = {};
                              filters.token = that.props.token;
                              filters.leadId = response.eventId;
                              filters.status = "2";
                              filters.artists = that.props.banquetDetails.id;
                              filters.insertedBy = "1";
                              filters.functionId = response2.functionId;
                              axios
                                .post(
                                  API_BASE + "artist/insertArtistMapping",
                                  querystring.stringify(filters)
                                )
                                .then((xhrResponse) => {
                                  if (xhrResponse.data != undefined) {
                                    if (xhrResponse.data.inserted == true) {
                                      var shortlisted = that.state.shortlisted;
                                      var shortlistedList = that.state.shortlistedList;

                                      shortlistedList.push(that.props.banquetDetails);
                                      shortlisted.push(that.props.banquetDetails.id);

                                      that.setState({shortlisted: shortlisted});
                                      localStorageUtil.setLocal("shortlisted", shortlisted, 870000);
                                      localStorageUtil.setLocal(
                                        "shortlistedList",
                                        shortlistedList,
                                        870000
                                      );

                                      window.location.reload();
                                    }
                                  }
                                })
                                .catch((error) => {
                                  ////console.log(error);
                                });
                            } else {
                              that.setState({showPopup: false});
                              this.setState({successfull: true});
                            }
                          },
                        });
                      }
                    },
                  });
                }
              },
            });
          } else {
            if (userMobileNo == "") {
              let noUserMobileCase = this.state.noUserMobileCase;
              let noUserEmailCase = this.state.noUserEmailCase;

              if (this.state.userEmail == "") {
                noUserEmailCase = true;
              }
              if (this.state.userMobileNo == "") {
                noUserMobileCase = true;
              }

              if (
                (that.props.pageType == "home" ||
                  that.props.pageType == "venueListing" ||
                  that.props.pageType == "venueProduct") &&
                openShow == 0
              ) {
                that.setState({
                  show: true,
                  noUserMobileCase: noUserMobileCase,
                  noUserEmailCase: noUserEmailCase,
                });
              }
            } else {
              var params1 = [];
              var pageType = "";

              switch (that.props.pageType) {
                case "home":
                  pageType = "1";
                  break;
                case "venueListing":
                  pageType = "2";
                  break;
                case "venueProduct":
                  pageType = "3";
                  break;
              }
              params1.pageType = pageType;

              for (let i in this.props.enquiryParams) {
                params1[i] = this.props.enquiryParams[i];
              }
              params1.userName = xhrResponse.data.userName;
              params1.userEmail = xhrResponse.data.userEmail;
              params1.userMobileNo = userMobileNo;
              let urlSource = window.location.pathname;
              //This urlSource is stored in googleAnalytics table
              if (urlSource !== "") {
                let lastIndex =
                  urlSource.indexOf("?") > -1 ? urlSource.indexOf("?") : urlSource.length;
                urlSource = urlSource.substring(0, lastIndex);
              }
              params1["page_source"] = urlSource !== "" ? urlSource : "/";
              if (that.props.reviewLogin == undefined) {
                axios
                  .post(
                    "https://www.bookeventz.com/home/getListingResult",
                    querystring.stringify(params1)
                  )
                  .then((xhrResponse) => {
                    if (generalUtil.safeReturn(xhrResponse, "data", false) !== false) {
                      let data = xhrResponse.data;

                      if (typeof data.error != "undefined") {
                        errorMsgLog.serverError = data.errorMsg;
                        that.setState({errorMsgLog, showLoader: false});
                      } else {
                        if (generalUtil.myGet("inf") != null) {
                          var params = {
                            refCode: generalUtil.myGet("inf"),
                            authToken: this.props.sessionData.authToken,
                            eventId: data.eventId,
                          };

                          /* axios.post(this.props.constants.API_BASE + 'influencer/checkInfluencer', querystring.stringify(params))
                                                             .then((xhrResponse) => {
                                                                 if(generalUtil.safeReturn(xhrResponse, 'data', false) !== false)
                                                                 {
                                                                     let data = xhrResponse.data;

                                                                     if(typeof data.error != 'undefined')
                                                                     {

                                                                         errorMsgLog.serverError = data.errorMsg;
                                                                         that.setState({errorMsgLog, showLoader : false});
                                                                     }
                                                                     else
                                                                     {


                                                                     }
                                                                 }
                                                             })
                                                             .catch((error) => {
                                                                 console.log(error);
                                                             });*/
                        }
                        /* gtag('event', 'conversion', {
                                                         'send_to': 'AW-990480324/YoBbCMDKnbABEMSPptgD',
                                                         'value': value,
                                                         'currency': 'INR',
                                                     });*/
                        generalUtil.callGa(
                          that.props.pageType + " slider",
                          "submit",
                          "enquiry",
                          data.enquiryId,
                          that.props.pageType
                        );
                        setTimeout(
                          function () {
                            if (that.props.pageType == "venueProduct") {
                              var datanew = {};
                              var datanew = {};
                              datanew.venueLeadId = data.venueLeadId;
                              datanew.eventId = data.eventId;
                              datanew.enquiryId = data.enquiryId;
                              datanew.eventIdMd5 = data.eventIdMd5;
                              localStorageUtil.setLocal("sliderPopupIsActive", "1", 86400000);
                              this.props.productData(datanew);
                            } else {
                              var sessionData = cookie.load("sessionData", data);
                              var userData = data;
                              cookie.save("userData", userData, {path: "/"});
                              localStorageUtil.setLocal("sliderPopupIsActive", "1", 86400000);

                              if (that.props.enquiryParams.product != undefined) {
                                var datanew = {};
                                datanew.venueLeadId = data.venueLeadId;
                                datanew.eventId = data.eventId;
                                datanew.enquiryId = data.enquiryId;
                                datanew.eventIdMd5 = data.eventIdMd5;
                                this.props.productData(datanew);
                              } else {
                                window.location.href =
                                  data.pageUrl + "&pageType=" + that.props.pageType;
                              }
                            }
                          }.bind(this),
                          5000
                        );
                      }
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            }
          }

          data.isEnquiryCreated = true;
          if (typeof data.error == "undefined") {
            cookie.save("sessionData", data, {path: "/"});
          }

          var params = [];
          var pageType = "";

          switch (that.props.pageType) {
            case "home":
              pageType = "1";
              break;
            case "venueListing":
              pageType = "2";
              break;
            case "venueProduct":
              pageType = "3";
              break;
          }
          params.pageType = pageType;

          for (let i in this.props.enquiryParams) {
            params[i] = this.props.enquiryParams[i];
          }

          var cookieData = cookie.load("sessionData");
          /* if(that.props.pageType == 'market')
                         {
                             var sessionData = cookie.load('sessionData',data.sessionData);
                             var userData = data.sessionData;
                             cookie.save('userData',userData,{path: '/' });
                             location.reload
                         }*/

          /*let value = 0;
                        if(generalUtil.inArray(params['occasion'],occasionCategory))
                        {
                            value = '800.0';
                        }
                        else
                        {
                            value = '350.0';
                        }*/

          let urlSource = window.location.pathname;
          //This urlSource is stored in googleAnalytics table
          if (urlSource !== "") {
            let lastIndex = urlSource.indexOf("?") > -1 ? urlSource.indexOf("?") : urlSource.length;
            urlSource = urlSource.substring(0, lastIndex);
          }
          params["page_source"] = urlSource !== "" ? urlSource : "/";

          if (params["userName"] == "" || params["userName"] == undefined) {
            params["userName"] = data.userName;
            params["userMobileNo"] = data.userMobileNo;
            params["userEmail"] = data.userEmail;
          }

          that.setState({showLoader: false});

          if (typeof data.error !== "undefined") {
            //On error if mobile number empty then show modal to get mobile no
            if (that.state.userMobileNo == "") {
              that.setState({show: true});
            } else {
              that.setState({fatalError: "Error in Facebook Login"}, () => {
                //notify parent with final data
                that.props.response({
                  error: "1",
                  errorMsg: that.state.fatalError,
                });
              });
            }
          } else {
            that.props.updateSessionData(that.props, data.sessionData);
            that.setState({show: false}, () => {
              //notify parent with final data
              that.props.response();
              /*that.props.response({
                                     userEmail: data.userEmail,
                                     userId: data.userId,
                                     userMobileNo: data.userMobileNo,
                                     userName: data.userName
                                 })*/
            });

            //to check mobile number later
            if (typeof data.isNewUser != "undefined") {
              localStorageUtil.setLocal("isMobileNoReconfirm", "1", "60000");
            }
            //sWin.updateSubscription(); //external function call
          }
        }
      })
      .catch((error) => {
        that.setState({fatalError: "Error in Facebook Login", showLoader: false});
      });
  }

  //Mobile No modal submit function
  checkUserModal() {
    var isMobileValid = this.state.userMobileNo.length == 10 ? true : false;
    var isEmailValid =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        this.state.userEmail
      )
        ? true
        : false;

    if (isMobileValid && isEmailValid) {
      this.setState({show: false});
      this.checkUser();
    } else {
      if (!isMobileValid) {
        this.setState({userInputError: "Wrong Mobile no."});
      } else if (!isEmailValid) {
        this.setState({userInputError: "Wrong Email Id."});
      }
    }
  }

  //mobile number validation function
  handleUserMobileNo(event) {
    let userMobileNo = event.target.value.trim();
    let userInputError = this.state.userInputError;

    if (event.type == "blur") {
      if (userMobileNo.length != 10) {
        userMobileNo = "";
        userInputError = "Wrong Mobile No.";
      } else {
        userInputError = "";
      }
    }

    this.setState({userMobileNo, userInputError});
  }

  handleUserEmail(event) {
    let userEmail = event.target.value.trim();
    let userInputError = this.state.userInputError;
    let emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (event.type == "blur") {
      if (!emailRegex.test(userEmail)) {
        userEmail = "";
        userInputError = "Wrong Email Id";
      } else {
        userInputError = "";
      }
    }

    this.setState({userEmail, userInputError});
  }

  hideModal() {
    //first time notify user that mobile no is manditory to make enquiry.
    switch (this.state.modalHideCounter) {
      case 0:
        this.setState({
          mobileNoMandatory: "Mobile No Is mandatory to continue",
          modalHideCounter: 1,
        });
        break;

      case 1:
        this.setState({show: false});
        break;
    }
  }

  render() {
    return (
      <div className="bzFacebookLogin myRelative">
        <FacebookLogin
          appId="304094696374352"
          autoLoad={false}
          fields="name,email,picture"
          scope="public_profile, email"
          onClick={this.componentClicked.bind(this)}
          callback={this.responseFacebook.bind(this)}
          cssClass={generalUtil.safeReturn(this.props, "className", "")}
          icon={this.props.children}
          textButton=""
          disableMobileRedirect={true}
        />

        {this.state.showLoader == true ? (
          <div className="loader-overlay">
            <ThreeBounce {...loaderStyle} />
          </div>
        ) : (
          ""
        )}

        <Modal
          show={this.state.show}
          onHide={this.hideModal.bind(this)}
          bsClass="modal"
          dialogClassName="facebook-mobile-form"
        >
          <Modal.Header closeButton>
            <div className="header-section">
              <img alt={"media.bookeventz.com"} src={this.state.pictureUrl} />
              <p>{this.state.userEmail}</p>
            </div>
          </Modal.Header>

          <Modal.Body>
            <div className="row">
              <div className="col-sm-12 ">
                <div className="form-group mobile-form">
                  <p>Enter your Mobile Number</p>
                  <input
                    type="number"
                    className="form-control"
                    onBlur={this.handleUserMobileNo.bind(this)}
                    onChange={this.handleUserMobileNo.bind(this)}
                    value={this.state.userMobileNo}
                    placeholder="MOBILE NUMBER"
                  />
                </div>

                <div className="form-group email-form">
                  <p>Enter your Email ID</p>
                  <input
                    type="email"
                    className="form-control"
                    onBlur={this.handleUserEmail.bind(this)}
                    onChange={this.handleUserEmail.bind(this)}
                    value={this.state.userEmail}
                    placeholder="EMAIL ID"
                  />
                </div>

                <div className="color-error">
                  <p>{this.state.userInputError}</p>
                </div>

                <button
                  className="btn btn-primary center-block"
                  onClick={this.checkUserModal.bind(this)}
                >
                  Confirm
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default BzFacebookLogin;
