"use strict";

var React = require("react");
import ReactDOM from "react-dom";
import config from "../../config/config";
import constants from "../../config/constants";
import HeaderLoginSection from "../../views/general/headerLoginSection";
import generalUtil from "./generalUtil";
import localStorageUtil from "./localstorage";
import NewHeader from "./newHeader";
var axios = require("axios");
var Sidebar = require("react-sidebar").default;
var cookie = require("react-cookies");
// var Reactbootstrap = require('react-bootstrap');
const { Panel, Nav, NavDropdown, NavItem } = require("react-bootstrap");
var querystring = require("querystring");
const pageType = "venueListing";
if (typeof window === "undefined") {
  global.window = {};
}
var environment = "production";
if (environment == "development") {
  var API_BASE = "https://apidemo.bookeventz.com/";
  var WEB_BASE = "https://staging.bookeventz.com/";
} else {
  var API_BASE = "https://api.bookeventz.com/";
  var WEB_BASE = "https://www.bookeventz.com/";
}

var SSR_WEB_BASE = "";
switch (process.env.NODE_ENV) {
  case "production":
    SSR_WEB_BASE = "https://www.bookeventz.com";
    break;
  case "development":
    SSR_WEB_BASE = "https://grunt.bookeventz.com";
    // SSR_WEB_BASE = 'http://localhost:8000';
    break;
  default:
    SSR_WEB_BASE = "";
}
SSR_WEB_BASE = "https://www.bookeventz.com";
class Header extends React.Component {
  constructor(props) {
    super(props);
    // console.log(this.props.pageType,'page type')

    var isShowUserDashboardModal = false;
    if (generalUtil.safeReturn(this.props.sessionData, "eventId", false, ["", null]) !== false || generalUtil.safeReturn(this.props.sessionData, "enquiryId", false, ["", null]) !== false) {
      isShowUserDashboardModal = true;
      /* if(localStorageUtil.getLocal('isHideUserDashboardPrompt') == '1')
             {
                 isShowUserDashboardModal = false;
             }*/
    }

    this.state = {
      otherProps: this.props.otherProps,
      sidebarOpen: false,
      blogDropOpen: false,
      areaDropOpen: false,
      venuesDropOpen: false,
      bazaarDropOpen: false,
      cityDropOpen: false,
      sidebarViewPort: false,
      isShowLoginModal: false,
      isShowUserDashboardModal,
      isShowSocialConformationModal: false,
      isShowSignUpModal: false,
      venuesTabOpen: false,
      corporateOpen: false,
      bazaarOpen: false,
      vendorsTabOpen: false,
      invitesRSVPTabOpen: false,
      realEventsTabOpen: false,
      blogTabOpen: false,
      ideasTabOpen: false,
      invitesTabOpen: false,
      vendorsDropOpen: false,
      rsvpDropopen: false,
      rsvpEventsDropopen: false,
      ideasDropopen: false,
      showModal: false,
      // cityPopup:false,
      showUnsportedBrowser: false,
      QA: this.props.QA,
      cityList: this.props.cityList,
      logout: false,
      isWish: this.props.isWish != undefined ? this.props.isWish : false,
      renderCity: false,
      vendorData: []

    };
    this.toggleRealEventsDrop = this.toggleRealEventsDrop.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleHide = this.handleHide.bind(this);
    this.loginPopup = this.loginPopup.bind(this);
  }

  loginPopup(isShowLoginModal) {
    var filters = this.props?.props?.filters;
    var url = window.location.href;
    var pathname = new URL(url).pathname;
    if (this.props?.sessionData?.CityId == "14") {
      pathname = "/banquets" + pathname;
    }
    var pathArray = pathname.split("/");

    var city = pathArray.length > 1 ? pathArray[2] : "";
    var props = {
      config: config,
      constants: constants,
      sessionData: this.props.sessionData,
      occasionList: this.props?.props?.occasionList,
      areaList: this.props.areaList,
      citySelected: { city },
      cityList: this.props.cityList,
      pageType: this.props.pageType,
      filters: filters,
      callType: "login",
      isShowLoginModal: isShowLoginModal,
      inviteHandleSubmit: this.props.inviteHandleSubmit,
    };
    ReactDOM.unmountComponentAtNode(document.getElementById("login"));
    ReactDOM.render(React.createElement(HeaderLoginSection, props), document.getElementById("login"));
  }

  goToEventPage() {
    let url = SSR_WEB_BASE + "/" + "user_profile/lead_venu_listing/suggested/";

    if (generalUtil.safeReturn(this.props.userData, "eventId", false, ["", null]) !== false) {
      url = url + "?id=" + this.props.userData.eventIdMd5;
    }

    window.open(url, "__blank");
  }
  componentWillMount() {
    this.getVendorData()
    /*    if(this.props.cityList != undefined && this.props.cityList.length == 0)
            {
                this.props.requestCityList(this.props);
            }*/
    this.switchInvite = this.switchInvite.bind(this);
    this.toggleCityDrop = this.toggleCityDrop.bind(this);
  }
  switchInvite() { }
  handleClose() {
    this.setState({ showUnsportedBrowser: false });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      isShowLoginModal: nextProps.isShowLoginModal,
      otherProps: nextProps.otherProps,
    });
  }
  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open });
  }
  toggleLoginModalNew = () => {
    var data = this.props;
    var that = this;
    this.setState({ showLoader: true });
    let isShowLoginModal = true;
    this.loginPopup(isShowLoginModal);
    this.setState({ isShowLoginModal: isShowLoginModal });
    setTimeout(function () {
      that.setState({ showLoader: false });
    }, 3000);
  }
  componentDidMount() {
    if (generalUtil.myGet("logout") == 1) {
      localStorage.removeItem("sessionData");
      clearCookies();
    }

    this.setState({ sidebarViewPort: true });
    if (navigator.userAgent.search("Safari") == 91) {
      this.setState({ showUnsportedBrowser: false });
    }
  }

  toggleCityDrop() {
    if (this.state.cityDropOpen == false) {
      this.setState({ cityDropOpen: true });
    } else {
      this.setState({ cityDropOpen: false });
    }
  }
  toggleRealEventsDrop() {
    if (this.state.rsvpEventsDropopen == false) {
      this.setState({
        blogDropOpen: false,
        vendorsDropOpen: false,
        rsvpDropopen: false,
        rsvpEventsDropopen: true,
      });
    } else {
      this.setState({ rsvpEventsDropopen: false });
    }
  }
  handleHide() {
    this.setState({ cityPopup: false });
    // localStorageUtil.setLocal('cityPopup','true');
    document.getElementById("overlay").style.display = "none";
  }
  toggleVendorsDrop() {
    if (this.state.vendorsDropOpen == false) {
      this.setState({
        vendorsDropOpen: true,
        blogDropOpen: false,
        vendorOpen: "wedding",
      });
    } else {
      this.setState({ vendorsDropOpen: false, vendorOpen: "" });
    }
  }
  toggleVendors(vendor) {
    if (this.state.vendorOpen == vendor) {
      this.setState({ vendorOpen: "" });
    } else {
      this.setState({ vendorOpen: vendor });
    }
  }
  toggleBazaar() {
    if (this.state.rsvpDropopen == false) {
      this.setState({
        blogDropOpen: false,
        vendorsDropOpen: false,
        rsvpDropopen: false,
        bazaarDropOpen: true,
      });
      generalUtil.callGa(window.pageType + " header", "clicked", "Bzaar clicked");
    } else {
      this.setState({ bazaarDropOpen: false });
    }
  }
  toggleIdeasDrop() {
    if (this.state.ideasDropopen == false) {
      this.setState({
        blogDropOpen: false,
        vendorsDropOpen: false,
        rsvpDropopen: false,
        rsvpEventsDropopen: false,
        ideasDropopen: true,
      });
    } else {
      this.setState({ ideasDropopen: false });
    }
  }
  toggleRsvpDrop() {
    if (this.state.rsvpDropopen == false) {
      this.setState({
        blogDropOpen: false,
        vendorsDropOpen: false,
        rsvpDropopen: true,
      });
      generalUtil.callGa(window.pageType + " header", "clicked", "invites clicked");
    } else {
      this.setState({ rsvpDropopen: false });
    }
  }
  toggleBlogDrop() {
    if (this.state.blogDropOpen == false) {
      this.setState({ blogDropOpen: true, vendorsDropOpen: false });
    } else {
      this.setState({ blogDropOpen: false });
    }
  }
  toggleVendorsDrop() {
    if (this.state.vendorsDropOpen == false) {
      this.setState({
        vendorsDropOpen: true,
        blogDropOpen: false,
        vendorOpen: "wedding",
      });
    } else {
      this.setState({ vendorsDropOpen: false, vendorOpen: "" });
    }
  }
  handleGetAreaList() {
    var that = this;
    if (this.state.parentAreaList != undefined && this.state.parentAreaList.length == 0) {
      axios
        .post(WEB_BASE + "home/getParentAreaList")
        .then((xhrResponse) => {
          if (generalUtil.safeReturn(xhrResponse, "data", false) !== false) {
            let data = xhrResponse.data;

            that.props.initialiseParentAreaList(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  destinationWeddingLinksHtml() {
    var areaListNew = [[], [], []];
    var index = 0;
    for (let i in this.state.areaList) {
      let row = this.state.areaList[i];

      if (row.isPopular == 1) {
        switch (index) {
          case 0:
            areaListNew[0].push(row);
            index = 1;
            break;
          case 1:
            areaListNew[1].push(row);
            index = 2;
            break;
          case 2:
            areaListNew[2].push(row);
            index = 0;
            break;
        }
      }
    }

    return (
      <div className="row test">
        {areaListNew.map((row1, i1) => {
          return (
            <div className={"col-sm-4 " + (i1 != 2 ? " left-bar " : "")} key={i1}>
              <ul className="list-inline">
                {/* row1.length != 0
                                             ?
                                             row1.map((row, i) => {
                                                 return (
                                                     <li key={i}>
                                                         <a href={this.props.base_url + this.props.sessionData.CityUniqueLink + '/' + row.AreaUniquelink}>
                                                             {"Destination Wedding Venues in " + row.AreaName}
                                                         </a>
                                                     </li>
                                                 )
                                             }, this)
                                             :
                                             <li className="loader-center"><Circle/></li>*/}
              </ul>
            </div>
          );
        }, this)}
      </div>
    );
  }

  destinationWeddingLinksVendors() {
    return (
      <div className="row test">
        <div className={"col-sm-4 left-bar"}>
          <ul className="list-inline">
            <li>
              <a href={SSR_WEB_BASE + "/destination-wedding-planner-india"}>{"Destination Wedding Planners in India"}</a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
  handleToggle = (tab, action) => {
    switch (tab) {
      case "corporateOpen":
        this.setState({ corporateOpen: action });
        break;
      case "bazaarOpen":
        this.setState({ bazaarOpen: action });
        break;
      case "venuesTabOpen":
        this.setState({ venuesTabOpen: action });
        break;
      case "vendorsTabOpen":
        this.setState({ vendorsTabOpen: action });
        break;
      case "realEventsTabOpen":
        this.setState({ realEventsTabOpen: action });
        break;
      case "blogTabOpen":
        this.setState({ blogTabOpen: action });
        break;
      case "invitesRSVPTabOpen":
        this.setState({ invitesRSVPTabOpen: action });
        generalUtil.callGa(window.pageType + " header", "clicked", "invites clicked");
        break;
      case "ideasTabOpen":
        this.setState({ ideasTabOpen: action });
        break;
      case "invitesTabOpen":
        this.setState({ invitesTabOpen: action });
        break;
    }
  };
  bottomHeaderSection() {
    var that = this;
    var tempArray = [];
  }
  toggleLoginModal(value) {
    this.setState({ isShowLoginModal: value, sidebarOpen: false });
  }
  signUpClick() {
    this.setState({ isShowSignUpModal: true, isShowLoginModal: false });
  }
  hideSignUpModal(modalState) {
    this.setState({ isShowSignUpModal: modalState });
  }
  hideSocialConformationModal() {
    this.setState({ isShowSocialConformationModal: false });
  }
  onTriggeredSocialConformationModal() {
    this.setState({ isShowSocialConformationModal: true }, function () {
      this.toggleLoginModal(false);
      this.hideSignUpModal(false);
    });
  }
  onCityChange(CityUniqueLink) {
    if (CityUniqueLink == "destination-wedding") {
      CityUniqueLink = "destination-wedding-home";
    }

    switch (this.props.pageType) {
      case "venueListing":
        window.location = SSR_WEB_BASE + "/banquets/" + CityUniqueLink;
        break;
      case "photographersListing":
        var pagetype = this.props.pageType;
        var index = pagetype.indexOf("Listing");
        var artistlink = pagetype.substring(0, index);
        window.location = SSR_WEB_BASE + "/" + artistlink + "/" + CityUniqueLink;
        break;
      case "anchors-in-indiaListing":
        var pagetype = this.props.pageType;
        var index = pagetype.indexOf("Listing");
        var artistlink = pagetype.substring(0, index);
        window.location = SSR_WEB_BASE + "/" + artistlink;
        break;
      case "comedians-in-indiaListing":
        var pagetype = this.props.pageType;
        var index = pagetype.indexOf("Listing");
        var artistlink = pagetype.substring(0, index);
        window.location = SSR_WEB_BASE + "/" + artistlink;
        break;
      case "catererListing":
        alert("Sorry for the inconvinience but Caterer service is only available in " + generalUtil.safeReturn(this.props.newcitySession, "CityName", ""));
        window.location = SSR_WEB_BASE + "/" + "caterers-in-" + generalUtil.safeReturn(this.props.newcitySession, "CityUniqueLink", "");
        break;
      default:
        window.location = SSR_WEB_BASE + "/" + CityUniqueLink;
        break;
    }
    // window.location = this.props.base_url + CityUniqueLink;
  }
  getCountry(data, countryCodeList) {
    if (generalUtil.inArray(data.CountryId, countryCodeList)) {
      var indexOfCountryCode = countryCodeList.indexOf(data.CountryId);
      countryCodeList.splice(indexOfCountryCode, 1);
      return (
        <div className="flagOfCIty">
          <div className={"countrySprite " + data.CountryName}></div>
          <p className="countryName">{data.CountryName}</p>
        </div>
      );
    }
  }
  renderList(cityId) {
    var sequenced = [];
    var normalarray = [];
    var countryCodeList = [];
    var countryList = [];
    this.props.cityList.map((row, i) => {
      if (countryCodeList.length < 1) {
        countryCodeList.push(row.CountryId);
      }

      if (generalUtil.inArray(row.CountryId, countryCodeList) == false) {
        countryCodeList.push(row.CountryId);
      }

      if (row.sequence != 0) {
        sequenced.push(row);
      } else {
        normalarray.push(row);
      }
    });

    sequenced.sort(function (a, b) {
      return a.sequence - b.sequence;
    });
    normalarray.sort(function (a, b) {
      var x = a.CityName.toLowerCase();
      var y = b.CityName.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });

    var finalArray = [];
    sequenced.map((row) => {
      finalArray.push(row);
    });
    normalarray.map((row) => {
      finalArray.push(row);
    });

    var groupBy = function (xs, key) {
      return finalArray.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    var newFilterArray = groupBy(countryCodeList, "CountryId");

    var arrayCOnt = [];
    if (window.innerWidth > 768) {
      for (var x in newFilterArray) {
        arrayCOnt.push(newFilterArray[x]);
        for (var y in newFilterArray[x]) {
          arrayCOnt.push(newFilterArray[x][y]);
        }
      }
      return arrayCOnt.map((row, i) => {
        if (row.CityName == undefined) {
          return (
            <div className="countryUlHead" key={i}>
              {this.getCountry(row[0], countryCodeList)}
            </div>
          );
        } else {
          return (
            <>
              <li key={i} onClick={this.onCityChange.bind(this, row.CityUniqueLink)} className="countryUl">
                {row.CityName}
              </li>
            </>
          );
        }
      });
    } else {
      for (var x in newFilterArray) {
        arrayCOnt.push(newFilterArray[x]);
        for (var y in newFilterArray[x]) {
          arrayCOnt.push(newFilterArray[x][y]);
        }
      }
      return arrayCOnt.map((row, i) => {
        if (row.CityName == undefined) {
          return (
            <div className="countryUlHead" key={i}>
              {this.getCountry(row[0], countryCodeList)}
            </div>
          );
        } else {
          if (row.CityId != "14") {
            return (
              <>
                <li key={i} onClick={this.onCityChange.bind(this, row.CityUniqueLink)} className={row.CityId == cityId ? "selected countryUl" : "countryUl"}>
                  {row.CityName}
                </li>
              </>
            );
          }
        }
      });
    }
  }
  toggleLogout() {
    if (this.state.logout == false) {
      this.setState({ logout: true });
    } else {
      this.setState({ logout: false });
    }
  }

  toggleSignupModalNew() {
    var data = this.props;
    var that = this;
    let isShowSignUpModal = true;
    this.setState({ showLoader: true });
    let sendQA = this.props.sendQA;
    this.toggleSignupModal(isShowSignUpModal);
    setTimeout(function () {
      that.setState({ showLoader: false });
    }, 3000);
  }

  toggleSignupModal(isShowSignUpModal) {
    var filters = this.props.filters;
    var url = window.location.href;

    var pathname = new URL(url).pathname;
    if (this.props.sessionData.CityId == "14") {
      pathname = "/banquets" + pathname;
    }
    var pathArray = pathname.split("/");
    var city = pathArray[2];
    var props = {
      config: config,
      constants: constants,
      sessionData: this.props.sessionData,
      occasionList: this.props.occasionList,
      areaList: this.props.areaList,
      citySelected: { city },
      cityList: this.props.cityList,
      pageType: pageType,
      filters: filters,
      callType: "login",
      isShowSignUpModal: isShowSignUpModal,
      toggleLoginModal: this.loginPopup,
      inviteHandleSubmit: this.props.inviteHandleSubmit,
    };
  }

  logoutNew = () => {
    // Get the current origin URL
    const url = window.location.origin;
    // Remove cookies and local storage data
    cookie.remove("userData", { path: "/" });
    cookie.remove("sessionData", { path: "/" });
    localStorageUtil.delLocal("sessionData");
    // Prepare auth data for the logout API call
    const authData = { url };
    // Send logout request to the server
    axios.post(WEB_BASE + "login/logoutNew", querystring.stringify(authData))
      .then(() => {
        // Reload the current page to ensure only the same page reloads
        window.location.reload();
      })
      .catch((error) => {
        console.error("Logout failed:", error);
      });
  };
  getVendorData = () => {
    let id = this.props?.sessionData?.CityId ?? this.props?.props?.NewsessionData?.CityId;
    let url = `https://api.bookeventz.com/Vendors/getVendorTypesByCity?cityId=${id}`;
    axios
      .get(url)
      .then(response => {
        this.setState({ vendorData: response.data.data })
      })
      .catch(error => {
        console.error(error, "error"); // Log any errors
      });
  };
  render() {
    return (
      <NewHeader props={this.props.props} parentName={this.props?.parentName !== undefined && this.props.parentName} vendorData={this.state.vendorData} logoutNew={this.logoutNew} toggleLoginModalNew={this.toggleLoginModalNew} sessionData={this.props.sessionData} pageType={this.props.pageType} />
    );
  }
}

// module.exports = Header;
export default Header;
