var cookie = require("cookie");
import React, { Component } from "react";

const languages = [
  { label: "English", value: "en", src: "https://flagcdn.com/h60/us.png" },
  { label: "हिंदी", value: "hi", src: "https://flagcdn.com/h60/in.png" },
  // Add additional languages as needed
];
const includedLanguages = languages.map((lang) => lang.value).join(",");
class GoogleTranslateWidget extends Component {
  state = {
    selectedLanguage: "",
  };

  componentDidMount() {
    // Ensure script is loaded before initialization
    const script = document.createElement("script");
    script.src =
      "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
    script.async = true;
    document.body.appendChild(script);
  }

  googleTranslateElementInit = () => {
    // console.log('googleTranslateElementInit');
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "auto",
        includedLanguages,

      },
      "google_translate_element"
    );
  };

  onChange = (e) => {
    console.log(e, "eee");
    const selectedLanguage = e.target.value;
    console.log(selectedLanguage, "selectedLanguage");

    this.setState({ selectedLanguage }, () => {
      let langUrl;

     
    if (selectedLanguage === "hi") {
      langUrl = "/hi";
    } else if (selectedLanguage === "en") {
      langUrl = "/en";
      window.location.reload();
    }
      document.cookie = cookie.serialize("googtrans", langUrl, { path: "/" });
      this.googleTranslateElementInit();
    });
  };

  render() {
    return (
      <LanguageSelector
        languages={languages}
        selectedLanguage={this.state.selectedLanguage}
        onChange={this.onChange}
      />
    );
  }
}

function LanguageSelector({ languages, selectedLanguage, onChange }) {
  return (
    <select value={selectedLanguage} onChange={onChange}>
      {languages.map((lang) => (
        <option value={lang.value} key={lang.value}>
          {lang.label}
        </option>
      ))}
    </select>
  );
}

export default GoogleTranslateWidget;
