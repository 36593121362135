
var React = require("react");
var ReactBootstrap = require("react-bootstrap");
var axios = require("axios");
var generalUtil = require("./generalUtil");
var cookie = require("react-cookies");
var SSR_WEB_BASE = "";
switch (process.env.NODE_ENV) {
  case "production":
    SSR_WEB_BASE = "https://www.bookeventz.com";
    break;
  case "development":
    SSR_WEB_BASE = "https://grunt.bookeventz.com";
    break;
  case "local":
    SSR_WEB_BASE = "http://localhost:8000";
    break;
  default:
    SSR_WEB_BASE = "";
}
// SSR_WEB_BASE = "https://www.bookeventz.com";

class CityList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
    this.ref = React.createRef(null);
  }

  getCountry(data, countryCodeList) {
    if (generalUtil.inArray(data.CountryName, countryCodeList)) {
      var indexOfCountryCode = countryCodeList.indexOf(data.CountryName);
      countryCodeList.splice(indexOfCountryCode, 1);

      if (this.props.pageType == "vendorListing") {
        //Remove condition Date - 26/10/2021
        //To see country name outside India
        // if(data.CountryName == "India"){
        return (
          <div className="flagOfCIty">
            <div className={"countrySprite " + data.CountryName}></div>
            <p className="countryName">{data.CountryName}</p>
          </div>
        );
        // }
      } else {
        return (
          <div className="flagOfCIty">
            <div className={"countrySprite " + data.CountryName}></div>
            <p className="countryName">{data.CountryName}</p>
          </div>
        );
      }
    }
  }
  handleClickOutside = (event) => {
    if (this.ref.current && !this.ref.current.contains(event.target)) {
      this.props.handleCityPopup()
    }
  };

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  }
  renderList(cityId) {
    var sequenced = [];
    var normalarray = [];
    var that = this;
    var countryCodeList = [];
    var countryList = [];
    this.props.cityList.sort(function (a, b) {
      var x = a.CountryName.toLowerCase();
      var y = b.CountryName.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });

    this.props.cityList.map((row, i) => {
      if (countryCodeList.length < 1) {
        countryCodeList.push(row.CountryName);
      }

      if (generalUtil.inArray(row.CountryName, countryCodeList) == false) {
        countryCodeList.push(row.CountryName);
      }

      if (row.sequence != 0) {
        sequenced.push(row);
      } else {
        normalarray.push(row);
      }
    });

    sequenced.sort(function (a, b) {
      return a.sequence - b.sequence;
    });
    normalarray.sort(function (a, b) {
      var x = a.CityName.toLowerCase();
      var y = b.CityName.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
    var finalArray = [];
    sequenced.map((row) => {
      finalArray.push(row);
    });
    normalarray.map((row) => {
      finalArray.push(row);
    });
    var priority = [];
    var priority1 = [];
    finalArray.map(function (row) {
      if (that.props.newcitySession != undefined) {
        if (row.CountryId == that.props.newcitySession.CountryId) {
          priority.push(row);
        } else {
          priority1.push(row);
        }
      }
    });
    priority1.sort(function (a, b) {
      var x = a.CountryName.toLowerCase();
      var y = b.CountryName.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
    finalArray = priority.concat(priority1);

    var groupBy = function (xs, key) {
      return finalArray.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };

    var newFilterArray = groupBy(countryCodeList, "CountryName");

    var arrayCOnt = [];
    if (window.innerWidth > 768) {
      for (var x in newFilterArray) {
        arrayCOnt.push(newFilterArray[x]);
        for (var y in newFilterArray[x]) {
          arrayCOnt.push(newFilterArray[x][y]);
        }
      }

      return arrayCOnt.map((row, i) => {
        if (row.CityName == undefined) {
          return (
            <div className="countryUlHead" key={i}>
              {this.getCountry(row[0], countryCodeList)}
            </div>
          );
        } else {
          if (row.CityName !== "Destination Wedding") {
            return (
              <div className="countryUl" key={i}>
                <li
                  key={i}
                  onClick={this.onCityChange.bind(this, row.CityUniqueLink)}
                  className={row.CityId == cityId ? "selected" : ""}
                >
                  {row.CityName}
                </li>
              </div>
            );
          }
        }
      });
    } else {
      for (var x in newFilterArray) {
        arrayCOnt.push(newFilterArray[x]);
        for (var y in newFilterArray[x]) {
          arrayCOnt.push(newFilterArray[x][y]);
        }
      }

      return arrayCOnt.map((row, i) => {
        if (row.CityName == undefined) {
          return (
            <div className="countryUlHead" key={i}>
              {this.getCountry(row[0], countryCodeList)}
            </div>
          );
        } else {
          return (
            <div className="countryUl" key={i}>
              <li
                key={i}
                onClick={this.onCityChange.bind(this, row.CityUniqueLink)}
                className={row.CityId == cityId ? "selected" : ""}
              >
                {row.CityName}
              </li>
            </div>
          );
        }
      });
    }
  }

  handleClick() {
    axios
      .post("https://api.bookeventz.com/location/getCityList")
      .then((xhrResponse) => {
        if (generalUtil.safeReturn(xhrResponse, "data", false) !== false) {
          let data = xhrResponse.data;
          this.setState({ cityList: data });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onCityChange(CityUniqueLink) {
    const { sessionData, cityList, pageType } = this.props;

    // Update session data with selected city's details
    const selectedCity = cityList.find(value => value.cityUniqueLink === CityUniqueLink);
    if (selectedCity) {
      const updatedSessionData = {
        ...sessionData,
        cityUniqueLink: CityUniqueLink,
        cityId: selectedCity.cityId,
        cityName: selectedCity.cityName,
      };
      cookie.save("sessionData", updatedSessionData, { path: "/" });
    }

    // Adjust CityUniqueLink for special cases
    if (CityUniqueLink === "destination-wedding") {
      CityUniqueLink = "destination-wedding-home";
    }

    // Determine the redirect URL based on page type
    let redirectUrl = `/${CityUniqueLink}`; // Default redirect

    if (pageType === "vendorListing") {
      const vendorMapping = [
        { keyword: "photographers", path: `/photographers/${CityUniqueLink}` },
        { keyword: "mehendi-artists", path: `/mehendi-artists/${CityUniqueLink}` },
        { keyword: "bridal-makeups", path: `/bridal-makeups/${CityUniqueLink}` },
        { keyword: "decorators", path: `/decorators/${CityUniqueLink}` }, // Same path for decorators
      ];

      const vendor = vendorMapping.find(v => window.location.href.includes(v.keyword));
      if (vendor) {
        redirectUrl = vendor.path;
      }
    } else if (pageType === "anchors-in-indiaListing" || pageType === "comedians-in-indiaListing") {
      redirectUrl = `/${pageType.replace("Listing", "")}`;
    } else if (pageType === "catererListing") {
      alert("Sorry, this service is not available in your area.")
      redirectUrl = CityUniqueLink;
    }
    // if (redirectUrl) {
    //   console.log(redirectUrl, "inside redirectUrl")
    //   // Change the URL without refreshing the page
    //   // window.history.pushState({}, '', `${SSR_WEB_BASE}${redirectUrl}`);
    //   window.history.pushState({}, '', `https://localhost:8000${redirectUrl}`);
    //   // Refresh the page explicitly
    //   window.location.reload();
    // }
    if (redirectUrl) {
      // Open the URL in a new window
      // window.open(`http://localhost:8000${redirectUrl}`, '_blank');
      window.open(`https://www.bookeventz.com/banquets${redirectUrl}`, '_blank');

    }
  }


  handleClickMobile() {
    var value = document.getElementById("citylist").value;

    if (value == "destination-wedding") {
      value = "destination-wedding-home";
    }

    window.location = this.props.base_url + value;
  }

  render() {
    var that = this;
    return (
      <div className="modal-dialog_9">
        <div className="modal-content_98">
          <div className="modal-body" id="vendor_city_popup" ref={this.ref}>
            <span onClick={this.props.handleCityPopup} id="close_popup">
              <img src="https://media.bookeventz.com/html/bookeventz.com/images/headerFooterIcons/closo.svg" alt="Close" />
            </span>
            <div className="text-center cityHeader">Select City</div>
            <div
              onClick={this.onCityChange.bind(this, "destination-wedding")}
              className="countryUl"
            >
            </div>
            <a href="https://www.bookeventz.com/destination-wedding" target="_blank" id="d_09">destination wedding</a>
            {this.props.cityList != undefined && this.props?.cityList?.length != 0
              ? this.renderList(this.props.sessionData?.CityId)
              : ""}
            <div className="countryUlHead"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default CityList;
