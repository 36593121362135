"use strict";

var React = require("react");
import ReactDOM from "react-dom";
import config from "../../config/config";
import constants from "../../config/constants";
import HeaderLoginSection from "../../views/general/headerLoginSection";
import GoogleTranslateWidget from "./GoogleTranslateWidget";
import CityList from "./cityList";
import generalUtil from "./generalUtil";
import localStorageUtil from "./localstorage";
import NewTopHeader from "./newTopHeader";
import LazyLoad from "react-lazyload";
var axios = require("axios");
var Sidebar = require("react-sidebar").default;
var cookie = require("react-cookies");
// var Reactbootstrap = require('react-bootstrap');
const {Panel, Nav, NavDropdown, NavItem} = require("react-bootstrap");
var querystring = require("querystring");
const pageType = "venueListing";
if (typeof window === "undefined") {
  global.window = {};
}
var environment = "production";
if (environment == "development") {
  var API_BASE = "https://apidemo.bookeventz.com/";
  var WEB_BASE = "https://staging.bookeventz.com/";
} else {
  var API_BASE = "https://api.bookeventz.com/";
  var WEB_BASE = "https://www.bookeventz.com/";
}

var SSR_WEB_BASE = "";
switch (process.env.NODE_ENV) {
  case "production":
    SSR_WEB_BASE = "https://www.bookeventz.com";
    break;
  case "development":
    SSR_WEB_BASE = "https://grunt.bookeventz.com";
    // SSR_WEB_BASE = 'http://localhost:8000';
    break;
  default:
    SSR_WEB_BASE = "";
}
SSR_WEB_BASE = "https://www.bookeventz.com";
class Header extends React.Component {
  constructor(props) {
    super(props);
    // console.log(this.props.pageType,'page type')

    var isShowUserDashboardModal = false;
    if (generalUtil.safeReturn(this.props.sessionData, "eventId", false, ["", null]) !== false || generalUtil.safeReturn(this.props.sessionData, "enquiryId", false, ["", null]) !== false) {
      isShowUserDashboardModal = true;
      /* if(localStorageUtil.getLocal('isHideUserDashboardPrompt') == '1')
             {
                 isShowUserDashboardModal = false;
             }*/
    }

    this.state = {
      otherProps: this.props.otherProps,
      sidebarOpen: false,
      blogDropOpen: false,
      areaDropOpen: false,
      venuesDropOpen: false,
      bazaarDropOpen: false,
      cityDropOpen: false,
      sidebarViewPort: false,
      isShowLoginModal: false,
      isShowUserDashboardModal,
      isShowSocialConformationModal: false,
      isShowSignUpModal: false,
      venuesTabOpen: false,
      corporateOpen: false,
      bazaarOpen: false,
      vendorsTabOpen: false,
      invitesRSVPTabOpen: false,
      realEventsTabOpen: false,
      blogTabOpen: false,
      ideasTabOpen: false,
      invitesTabOpen: false,
      vendorsDropOpen: false,
      rsvpDropopen: false,
      rsvpEventsDropopen: false,
      ideasDropopen: false,
      showModal: false,
      // cityPopup:false,
      showUnsportedBrowser: false,
      QA: this.props.QA,
      cityList: this.props.cityList,
      logout: false,
      isWish: this.props.isWish != undefined ? this.props.isWish : false,
      renderCity: false,
    };
    this.toggleRealEventsDrop = this.toggleRealEventsDrop.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleHide = this.handleHide.bind(this);
    this.loginPopup = this.loginPopup.bind(this);
  }

  loginPopup(isShowLoginModal) {
    console.log(this.props, "props");
    var filters = this.props.filters;
    var url = window.location.href;

    var pathname = new URL(url).pathname;
    if (this.props?.sessionData?.CityId == "14") {
      pathname = "/banquets" + pathname;
    }
    var pathArray = pathname.split("/");

    var city = pathArray.length > 1 ? pathArray[2] : "";
    var props = {
      config: config,
      constants: constants,
      sessionData: this.props.sessionData,
      occasionList: this.props.occasionList,
      areaList: this.props.areaList,
      citySelected: {city},
      cityList: this.props.cityList,
      pageType: this.props.pageType,
      filters: filters,
      callType: "login",
      isShowLoginModal: isShowLoginModal,
      inviteHandleSubmit: this.props.inviteHandleSubmit,
    };
    ReactDOM.unmountComponentAtNode(document.getElementById("login"));
    ReactDOM.render(React.createElement(HeaderLoginSection, props), document.getElementById("login"));
  }

  goToEventPage() {
    let url = SSR_WEB_BASE + "/" + "user_profile/lead_venu_listing/suggested/";

    if (generalUtil.safeReturn(this.props.userData, "eventId", false, ["", null]) !== false) {
      url = url + "?id=" + this.props.userData.eventIdMd5;
    }

    window.open(url, "__blank");
  }
  componentWillMount() {
    /*    if(this.props.cityList != undefined && this.props.cityList.length == 0)
            {
                this.props.requestCityList(this.props);
            }*/
    this.switchInvite = this.switchInvite.bind(this);
    this.toggleCityDrop = this.toggleCityDrop.bind(this);
  }
  switchInvite() {}
  handleClose() {
    this.setState({showUnsportedBrowser: false});
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      isShowLoginModal: nextProps.isShowLoginModal,
      otherProps: nextProps.otherProps,
    });
  }
  onSetSidebarOpen(open) {
    this.setState({sidebarOpen: open});
  }
  toggleLoginModalNew() {
    var data = this.props;
    var that = this;
    this.setState({showLoader: true});
    let isShowLoginModal = true;
    let sendQA = this.props.sendQA;
    this.loginPopup(isShowLoginModal);
    this.setState({isShowLoginModal: isShowLoginModal});
    setTimeout(function () {
      that.setState({showLoader: false});
    }, 3000);
  }
  componentDidMount() {
    if (generalUtil.myGet("logout") == 1) {
      localStorage.removeItem("sessionData");
      clearCookies();
    }

    this.setState({sidebarViewPort: true});
    if (navigator.userAgent.search("Safari") == 91) {
      this.setState({showUnsportedBrowser: false});
    }
  }

  toggleCityDrop() {
    if (this.state.cityDropOpen == false) {
      this.setState({cityDropOpen: true});
    } else {
      this.setState({cityDropOpen: false});
    }
  }
  toggleRealEventsDrop() {
    if (this.state.rsvpEventsDropopen == false) {
      this.setState({
        blogDropOpen: false,
        vendorsDropOpen: false,
        rsvpDropopen: false,
        rsvpEventsDropopen: true,
      });
    } else {
      this.setState({rsvpEventsDropopen: false});
    }
  }
  handleHide() {
    this.setState({cityPopup: false});
    // localStorageUtil.setLocal('cityPopup','true');
    document.getElementById("overlay").style.display = "none";
  }
  toggleVendorsDrop() {
    if (this.state.vendorsDropOpen == false) {
      this.setState({
        vendorsDropOpen: true,
        blogDropOpen: false,
        vendorOpen: "wedding",
      });
    } else {
      this.setState({vendorsDropOpen: false, vendorOpen: ""});
    }
  }
  toggleVendors(vendor) {
    if (this.state.vendorOpen == vendor) {
      this.setState({vendorOpen: ""});
    } else {
      this.setState({vendorOpen: vendor});
    }
  }
  toggleBazaar() {
    if (this.state.rsvpDropopen == false) {
      this.setState({
        blogDropOpen: false,
        vendorsDropOpen: false,
        rsvpDropopen: false,
        bazaarDropOpen: true,
      });
      generalUtil.callGa(window.pageType + " header", "clicked", "Bzaar clicked");
    } else {
      this.setState({bazaarDropOpen: false});
    }
  }
  toggleIdeasDrop() {
    if (this.state.ideasDropopen == false) {
      this.setState({
        blogDropOpen: false,
        vendorsDropOpen: false,
        rsvpDropopen: false,
        rsvpEventsDropopen: false,
        ideasDropopen: true,
      });
    } else {
      this.setState({ideasDropopen: false});
    }
  }
  toggleRsvpDrop() {
    if (this.state.rsvpDropopen == false) {
      this.setState({
        blogDropOpen: false,
        vendorsDropOpen: false,
        rsvpDropopen: true,
      });
      generalUtil.callGa(window.pageType + " header", "clicked", "invites clicked");
    } else {
      this.setState({rsvpDropopen: false});
    }
  }
  toggleBlogDrop() {
    if (this.state.blogDropOpen == false) {
      this.setState({blogDropOpen: true, vendorsDropOpen: false});
    } else {
      this.setState({blogDropOpen: false});
    }
  }
  toggleVendorsDrop() {
    if (this.state.vendorsDropOpen == false) {
      this.setState({
        vendorsDropOpen: true,
        blogDropOpen: false,
        vendorOpen: "wedding",
      });
    } else {
      this.setState({vendorsDropOpen: false, vendorOpen: ""});
    }
  }
  handleGetAreaList() {
    var that = this;
    if (this.state.parentAreaList != undefined && this.state.parentAreaList.length == 0) {
      axios
        .post(WEB_BASE + "home/getParentAreaList")
        .then((xhrResponse) => {
          if (generalUtil.safeReturn(xhrResponse, "data", false) !== false) {
            let data = xhrResponse.data;

            that.props.initialiseParentAreaList(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  destinationWeddingLinksHtml() {
    var areaListNew = [[], [], []];
    var index = 0;
    for (let i in this.state.areaList) {
      let row = this.state.areaList[i];

      if (row.isPopular == 1) {
        switch (index) {
          case 0:
            areaListNew[0].push(row);
            index = 1;
            break;
          case 1:
            areaListNew[1].push(row);
            index = 2;
            break;
          case 2:
            areaListNew[2].push(row);
            index = 0;
            break;
        }
      }
    }

    return (
      <div className="row test">
        {areaListNew.map((row1, i1) => {
          return (
            <div className={"col-sm-4 " + (i1 != 2 ? " left-bar " : "")} key={i1}>
              <ul className="list-inline">
                {/* row1.length != 0
                                             ?
                                             row1.map((row, i) => {
                                                 return (
                                                     <li key={i}>
                                                         <a href={this.props.base_url + this.props.sessionData.CityUniqueLink + '/' + row.AreaUniquelink}>
                                                             {"Destination Wedding Venues in " + row.AreaName}
                                                         </a>
                                                     </li>
                                                 )
                                             }, this)
                                             :
                                             <li className="loader-center"><Circle/></li>*/}
              </ul>
            </div>
          );
        }, this)}
      </div>
    );
  }

  destinationWeddingLinksVendors() {
    return (
      <div className="row test">
        <div className={"col-sm-4 left-bar"}>
          <ul className="list-inline">
            <li>
              <a href={SSR_WEB_BASE + "/destination-wedding-planner-india"}>{"Destination Wedding Planners in India"}</a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
  handleToggle = (tab, action) => {
    switch (tab) {
      case "corporateOpen":
        this.setState({corporateOpen: action});
        break;
      case "bazaarOpen":
        this.setState({bazaarOpen: action});
        break;
      case "venuesTabOpen":
        this.setState({venuesTabOpen: action});
        break;
      case "vendorsTabOpen":
        this.setState({vendorsTabOpen: action});
        break;
      case "realEventsTabOpen":
        this.setState({realEventsTabOpen: action});
        break;
      case "blogTabOpen":
        this.setState({blogTabOpen: action});
        break;
      case "invitesRSVPTabOpen":
        this.setState({invitesRSVPTabOpen: action});
        generalUtil.callGa(window.pageType + " header", "clicked", "invites clicked");
        break;
      case "ideasTabOpen":
        this.setState({ideasTabOpen: action});
        break;
      case "invitesTabOpen":
        this.setState({invitesTabOpen: action});
        break;
    }
  };
  bottomHeaderSection() {
    var that = this;
    var tempArray = [];

    return "Hello tu kon hai";
  }
  toggleLoginModal(value) {
    this.setState({isShowLoginModal: value, sidebarOpen: false});
  }
  signUpClick() {
    this.setState({isShowSignUpModal: true, isShowLoginModal: false});
  }
  hideSignUpModal(modalState) {
    this.setState({isShowSignUpModal: modalState});
  }
  hideSocialConformationModal() {
    this.setState({isShowSocialConformationModal: false});
  }
  onTriggeredSocialConformationModal() {
    this.setState({isShowSocialConformationModal: true}, function () {
      this.toggleLoginModal(false);
      this.hideSignUpModal(false);
    });
  }
  onCityChange(CityUniqueLink) {
    if (CityUniqueLink == "destination-wedding") {
      CityUniqueLink = "destination-wedding-home";
    }

    switch (this.props.pageType) {
      case "venueListing":
        window.location = SSR_WEB_BASE + "/banquets/" + CityUniqueLink;
        break;
      case "photographersListing":
        var pagetype = this.props.pageType;
        var index = pagetype.indexOf("Listing");
        var artistlink = pagetype.substring(0, index);
        window.location = SSR_WEB_BASE + "/" + artistlink + "/" + CityUniqueLink;
        break;
      case "anchors-in-indiaListing":
        var pagetype = this.props.pageType;
        var index = pagetype.indexOf("Listing");
        var artistlink = pagetype.substring(0, index);
        window.location = SSR_WEB_BASE + "/" + artistlink;
        break;
      case "comedians-in-indiaListing":
        var pagetype = this.props.pageType;
        var index = pagetype.indexOf("Listing");
        var artistlink = pagetype.substring(0, index);
        window.location = SSR_WEB_BASE + "/" + artistlink;
        break;
      case "catererListing":
        alert("Sorry for the inconvinience but Caterer service is only available in " + generalUtil.safeReturn(this.props.newcitySession, "CityName", ""));
        window.location = SSR_WEB_BASE + "/" + "caterers-in-" + generalUtil.safeReturn(this.props.newcitySession, "CityUniqueLink", "");
        break;
      default:
        window.location = SSR_WEB_BASE + "/" + CityUniqueLink;
        break;
    }
    // window.location = this.props.base_url + CityUniqueLink;
  }
  getCountry(data, countryCodeList) {
    if (generalUtil.inArray(data.CountryId, countryCodeList)) {
      var indexOfCountryCode = countryCodeList.indexOf(data.CountryId);
      countryCodeList.splice(indexOfCountryCode, 1);
      return (
        <div className="flagOfCIty">
          <div className={"countrySprite " + data.CountryName}></div>
          <p className="countryName">{data.CountryName}</p>
        </div>
      );
    }
  }
  renderList(cityId) {
    var sequenced = [];
    var normalarray = [];
    var countryCodeList = [];
    var countryList = [];
    this.props.cityList.map((row, i) => {
      if (countryCodeList.length < 1) {
        countryCodeList.push(row.CountryId);
      }

      if (generalUtil.inArray(row.CountryId, countryCodeList) == false) {
        countryCodeList.push(row.CountryId);
      }

      if (row.sequence != 0) {
        sequenced.push(row);
      } else {
        normalarray.push(row);
      }
    });

    sequenced.sort(function (a, b) {
      return a.sequence - b.sequence;
    });
    normalarray.sort(function (a, b) {
      var x = a.CityName.toLowerCase();
      var y = b.CityName.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });

    var finalArray = [];
    sequenced.map((row) => {
      finalArray.push(row);
    });
    normalarray.map((row) => {
      finalArray.push(row);
    });

    var groupBy = function (xs, key) {
      return finalArray.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    var newFilterArray = groupBy(countryCodeList, "CountryId");

    var arrayCOnt = [];
    if (window.innerWidth > 768) {
      for (var x in newFilterArray) {
        arrayCOnt.push(newFilterArray[x]);
        for (var y in newFilterArray[x]) {
          arrayCOnt.push(newFilterArray[x][y]);
        }
      }
      return arrayCOnt.map((row, i) => {
        if (row.CityName == undefined) {
          return (
            <div className="countryUlHead" key={i}>
              {this.getCountry(row[0], countryCodeList)}
            </div>
          );
        } else {
          return (
            <>
              <li key={i} onClick={this.onCityChange.bind(this, row.CityUniqueLink)} className="countryUl">
                {row.CityName}
              </li>
            </>
          );
        }
      });
    } else {
      for (var x in newFilterArray) {
        arrayCOnt.push(newFilterArray[x]);
        for (var y in newFilterArray[x]) {
          arrayCOnt.push(newFilterArray[x][y]);
        }
      }
      return arrayCOnt.map((row, i) => {
        if (row.CityName == undefined) {
          return (
            <div className="countryUlHead" key={i}>
              {this.getCountry(row[0], countryCodeList)}
            </div>
          );
        } else {
          if (row.CityId != "14") {
            return (
              <>
                <li key={i} onClick={this.onCityChange.bind(this, row.CityUniqueLink)} className={row.CityId == cityId ? "selected countryUl" : "countryUl"}>
                  {row.CityName}
                </li>
              </>
            );
          }
        }
      });
    }
  }
  toggleLogout() {
    if (this.state.logout == false) {
      this.setState({logout: true});
    } else {
      this.setState({logout: false});
    }
  }

  toggleSignupModalNew() {
    var data = this.props;
    var that = this;
    let isShowSignUpModal = true;
    this.setState({showLoader: true});
    let sendQA = this.props.sendQA;
    this.toggleSignupModal(isShowSignUpModal);
    setTimeout(function () {
      that.setState({showLoader: false});
    }, 3000);
  }

  toggleSignupModal(isShowSignUpModal) {
    var filters = this.props.filters;
    var url = window.location.href;

    var pathname = new URL(url).pathname;
    if (this.props.sessionData.CityId == "14") {
      pathname = "/banquets" + pathname;
    }
    var pathArray = pathname.split("/");
    var city = pathArray[2];
    var props = {
      config: config,
      constants: constants,
      sessionData: this.props.sessionData,
      occasionList: this.props.occasionList,
      areaList: this.props.areaList,
      citySelected: {city},
      cityList: this.props.cityList,
      pageType: pageType,
      filters: filters,
      callType: "login",
      isShowSignUpModal: isShowSignUpModal,
      toggleLoginModal: this.loginPopup,
      inviteHandleSubmit: this.props.inviteHandleSubmit,
    };
    myReactDOM2.unmountComponentAtNode(document.getElementById("login"));
    myReactDOM2.render(myReact2.createElement(HeaderLoginSection, props), document.getElementById("login"));
  }

  toggleAreaDrop() {
    if (this.state.areaDropOpen == false) {
      this.setState({areaDropOpen: true});
    } else {
      this.setState({areaDropOpen: false});
    }
  }
  toggleVenuesDrop() {
    if (this.state.venuesDropOpen == false) {
      this.setState({venuesDropOpen: true});
    } else {
      this.setState({venuesDropOpen: false});
    }
  }
  logoutNew() {
    let url = window.location.origin;
    cookie.remove("userData", {path: "/"});
    cookie.remove("sessionData", {path: "/"});
    localStorageUtil.delLocal("sessionData");
    let authData = [];
    authData["url"] = url;
    console.log("logout new");
    axios
      .post(WEB_BASE + "login/logoutNew", querystring.stringify(authData))
      .then((xhrResponse) => {
        window.location.href = url;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  render() {
    var shortlistCount = 0;
    var stylesBar = {};
    var that = this;
    var sideBarContent = "";
    if (this.props.areaList != undefined && that.props.otherProps.newcitySession != undefined) {
      var areaLinks = this.props.areaList.map(function (area, id) {
        return parseInt(area.isPopular) ? (
          <li className="" key={id}>
            <a
              href={
                that.props.otherProps.newcitySession.CityId == "14"
                  ? SSR_WEB_BASE + "/" + that.props.otherProps.newcitySession.CityUniqueLink + "/" + area.AreaUniqueLink
                  : SSR_WEB_BASE + "/banquets/" + that.props.otherProps.newcitySession.CityUniqueLink + "/" + area.AreaUniqueLink
              }
            >
              Banquet Halls in {area.AreaName}
            </a>
          </li>
        ) : (
          ""
        );
      });
    }

    if (this.state.sidebarViewPort == true) {
      stylesBar = {
        sidebar: {
          boxShadow: "none",
        },
        overlay: {
          backgroundColor: "rgba(0,0,0,.7)",
        },
      };

      sideBarContent = (
        <div>
          <ul className="sidebar-nav">
            <li className="Mobil-new-icon-wrapper row">
              <div className="Mobil-new-icon-wrapper1" id="wrap11"></div>
              <div className="header-login-section col-xs-2" id="logout">
                {this.props.sessionData != undefined && this.props.sessionData.user_type != undefined && this.props.sessionData.user_type != "" ? (
                  this.props.sessionData.userPic != undefined ? (
                    <img alt={"media.bookeventz.com"} className="lazyload" data-src={this.props.sessionData.userPic} width="60px" />
                  ) : (
                    <span className="glyphicon glyphicon-user" />
                  )
                ) : (
                  <span className="glyphicon glyphicon-user" />
                )}
              </div>
              {this.props.sessionData != undefined && this.props.sessionData.userName != undefined ? (
                <div className="login-wrap-1" id="new-login-wrap">
                  <div className="color-555">{this.props.sessionData.userName}</div>

                  {/* <div className="color-555 logout-section">
                                            <a onClick={that.logoutNew.bind(this)}  className="innerATag">Logout</a>
                                          </div> */}
                </div>
              ) : (
                <div className="login-wrap-1">
                  <span className="color-555" onClick={this.toggleLoginModalNew.bind(this, true)} id="login-section">
                    Login &nbsp; Or &nbsp;
                  </span>
                  <span className="color-555" onClick={this.toggleSignupModalNew.bind(this, true)} id="login-section">
                    Register
                  </span>
                </div>
              )}
            </li>

            {/* {this.props.sessionData &&
            this.props.sessionData.user_types == undefined &&
            this.props.sessionData.user_type == undefined ? (
              ""
            ) : ( */}
            <>
              {/* {this.props.sessionData && this.props.sessionData.userName != undefined ? ( */}
              <li className="menu-wrap-for-second-part row" id="vendor-type-wrapper" onClick={this.toggleLogout.bind(this)}>
                <div data-src="https://media.bookeventz.com/html/bookeventz.com/asset/images/BE_Hamburger_Sprite_enlarged-new-1.webp" className="  account"></div>
                <span className="mobile-navbar-text">My Account</span>
                <span
                  data-src="https://media.bookeventz.com/html/bookeventz.com/asset/images/BE_Hamburger_Sprite_enlarged-new-1.webp"
                  className={this.state.logout == true ? "headerUpIcon" : "  headerDropIcons"}
                />
              </li>
              {/* ) : (
                  ""
                )} */}

              <li className="dropPanel">
                <Panel collapsible expanded={this.state.logout}>
                  <ul className={"list-unstyled col-xs-12 vendor-list " + this.state.logout}>
                    <li>
                      <a className="vendor-header-tab col-xs-12 external-class wrap-enquiries" href={SSR_WEB_BASE + "/" + "user-bookings"}>
                        <div className="lazyload  icon-headerenquiry"></div>
                        <span className="mobile-navbar-text logged-inwrp">My Event Enquiries</span>
                      </a>
                    </li>
                    <li>
                      <a className="vendor-header-tab col-xs-12 external-class wrap-enquiries" href={SSR_WEB_BASE + "/" + "user-bookings"}>
                        <div className="lazyload icon-headerbookings"></div>
                        <span className="mobile-navbar-text logged-inwrp">My Bookings</span>
                      </a>
                    </li>
                    <li>
                      <a className="vendor-header-tab col-xs-12 external-class wrap-enquiries" href={SSR_WEB_BASE + "/" + "user-bookings"}>
                        <div className="lazyload  icon-headerreviews"></div>
                        <span className="mobile-navbar-text logged-inwrp">My Reviews</span>
                      </a>
                    </li>
                    <li>
                      <a className="vendor-header-tab col-xs-12 external-class wrap-enquiries" href={SSR_WEB_BASE + "/" + "shortlistedVendors/" + this.props.sessionData?.CityUniqueLink}>
                        <div className="lazyload  icon-headerreviews"></div>
                        <span className="mobile-navbar-text logged-inwrp">Shortlisted Vendors</span>
                      </a>
                    </li>
                    <li>
                      <a onClick={that.logoutNew.bind(this)} className="vendor-header-tab col-xs-12 external-class wrap-enquiries">
                        <div className="lazyload  icon-headerlogout"></div>
                        <span className="mobile-navbar-text logged-inwrp">Logout</span>
                      </a>
                    </li>
                  </ul>
                </Panel>
              </li>
            </>
            {/* )} */}

            <li className="menu-wrap-for-second-part row" id="vendor-type-wrapper" onClick={() => this.setState({renderCity: true})}>
              <a className="vendor-header-tab col-xs-12 external-class" data-toggle="modal" data-target="#myModal2">
                <LazyLoad height={10}>
                  <div data-src="https://media.bookeventz.com/html/bookeventz.com/asset/images/BE_Hamburger_Sprite_enlarged-new-1.webp" className="  locationpin"></div>
                </LazyLoad>
                <span className="mobile-navbar-text">City</span>
                <span
                  data-src="https://media.bookeventz.com/html/bookeventz.com/asset/images/BE_Hamburger_Sprite_enlarged-new-1.webp"
                  className={this.state.cityDropOpen == true ? "headerUpIcon" : "  headerDropIcons"}
                />
              </a>
            </li>

            <li className="menu-wrap-for-second-part row" onClick={this.toggleVenuesDrop.bind(this)}>
              <div data-src="https://media.bookeventz.com/html/bookeventz.com/asset/images/BE_Hamburger_Sprite_enlarged-new-1.webp" className="  col-sm-4 icon-header-mobile icon-header-image1"></div>
              <span className="mobile-navbar-text">Venues</span>
              <span
                data-src="https://media.bookeventz.com/html/bookeventz.com/asset/images/BE_Hamburger_Sprite_enlarged-new-1.webp"
                className={this.state.venuesDropOpen == true ? "headerUpIcon" : "  headerDropIcons"}
              />
            </li>
            <li className="dropPanel">
              {this.props.newcitySession != undefined && this.state.venuesDropOpen && (
                <ul className={"list-unstyled col-xs-12 vendor-list " + this.state.venuesDropOpen}>
                  <li className="user-reviews-head">
                    <a className="innerATag contentBlock" onClick={this.toggleAreaDrop.bind(this)}>
                      Popular Areas
                      <span
                        data-src="//media.bookeventz.com/html/bookeventz.com/asset/images/BE_Hamburger_Sprite_enlarged-new-1.png"
                        className={this.state.areaDropOpen == true ? "headerUpIconMinus" : "headerDropIconsPlus"}
                      />
                    </a>
                  </li>

                  <li className="dropPanel">
                    <Panel collapsible expanded={this.state.areaDropOpen}>
                      <ul className={"list-unstyled col-xs-12 vendor-list " + this.state.areaDropOpen}>{areaLinks}</ul>
                    </Panel>
                  </li>

                  {this.props.newcitySession != undefined ? (
                    <li className="user-reviews-head">
                      <a title="Bookeventz Venue" href={SSR_WEB_BASE + "/bookeventz-venues/" + this.props.newcitySession.CityUniqueLink}>
                        Bookeventz Venue
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {this.props.newcitySession != undefined ? (
                    <li className="user-reviews-head">
                      <a title=" Banquet halls near me" href={SSR_WEB_BASE + "/" + this.props.newcitySession.CityUniqueLink + "/banquet-halls-near-me"}>
                        Banquet halls near me
                      </a>
                    </li>
                  ) : (
                    ""
                  )}

                  {this.props.newcitySession.cityName == "Mumbai" || this.props.newcitySession.cityName == "Bangalore" ? (
                    <li className="user-reviews-head">
                      <a className="innerATag" title="McDonalds Party Section" href={SSR_WEB_BASE + "/mumbai/mcdonalds-birthday-party"}>
                        McDonalds Party Section
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              )}
            </li>

            <li className="dropPanel">
              {this.props.newcitySession != undefined && this.state.venuesDropOpen && (
                <ul className={"list-unstyled col-xs-12 vendor-list " + this.state.venuesDropOpen}>
                  <li className="user-reviews-head">
                    <a
                      className="innerATag"
                      title={"Banquet halls in " + this.props.newcitySession && this.props.newcitySession.cityName}
                      href={
                        this.props.newcitySession && this.props.newcitySession.CityId == "14"
                          ? WEB_BASE + "banquets/" + this.props.newcitySession.CityUniqueLink
                          : WEB_BASE + "banquets/" + this.props.newcitySession.CityUniqueLink
                      }
                    >
                      Banquet halls in {this.props.newcitySession && this.props.newcitySession.CityName}
                    </a>
                  </li>
                  <li className="user-reviews-head">
                    <a
                      className="innerATag"
                      title={"Wedding venues in " + this.props.newcitySession.cityName}
                      href={
                        this.props.newcitySession.CityId == "14"
                          ? SSR_WEB_BASE + "/" + "banquets/" + this.props.newcitySession.CityUniqueLink + "/wedding"
                          : SSR_WEB_BASE + "/" + "banquets/" + this.props.newcitySession.CityUniqueLink + "/wedding"
                      }
                    >
                      Wedding venues in {this.props.newcitySession && this.props.newcitySession.CityName}
                    </a>
                  </li>
                  <li className="user-reviews-head">
                    <a
                      className="innerATag"
                      title={"Party halls in " + this.props.newcitySession.cityName}
                      href={
                        this.props.newcitySession.CityId == "14"
                          ? SSR_WEB_BASE + "/" + "banquets/" + this.props.newcitySession.CityUniqueLink + "/birthday-party"
                          : SSR_WEB_BASE + "/" + "banquets/" + this.props.newcitySession.CityUniqueLink + "/birthday-party"
                      }
                    >
                      Party halls in {this.props.newcitySession && this.props.newcitySession.CityName}
                    </a>
                  </li>
                  <li className="user-reviews-head">
                    <a
                      className="innerATag"
                      title={"5 Star Banquet halls in " + this.props.newcitySession.cityName}
                      href={
                        this.props.newcitySession.CityId == "14"
                          ? SSR_WEB_BASE + "/" + "banquets/" + this.props.newcitySession.CityUniqueLink + "/5-star-hotels"
                          : SSR_WEB_BASE + "/" + "banquets/" + this.props.newcitySession.CityUniqueLink + "/5-star-hotels"
                      }
                    >
                      5 Star Banquet halls in {this.props.newcitySession && this.props.newcitySession.CityName}
                    </a>
                  </li>
                  {this.props.newcitySession &&
                  this.props.newcitySession != undefined &&
                  (this.props.newcitySession.CityId == "1" ||
                    this.props.newcitySession.CityId == "2" ||
                    this.props.newcitySession.CityId == "3" ||
                    this.props.newcitySession.CityId == "4" ||
                    this.props.newcitySession.CityId == "5" ||
                    this.props.newcitySession.CityId == "6" ||
                    this.props.newcitySession.CityId == "7" ||
                    this.props.newcitySession.CityId == "12" ||
                    this.props.newcitySession.CityId == "13" ||
                    this.props.newcitySession.CityId == "14" ||
                    this.props.newcitySession.CityId == "53" ||
                    this.props.newcitySession.CityId == "15") ? (
                    <li className="user-reviews-head">
                      <a title=" Banquet halls near me" href={SSR_WEB_BASE + "/" + this.props.newcitySession.CityUniqueLink + "/banquet-halls-near-me"}>
                        Banquet halls near me
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              )}
            </li>
            {this.props.newcitySession != null &&
              this.props.newcitySession != undefined &&
              constants.VENDOR_COUNTRY_IDS.includes(this.props.newcitySession.CountryId) &&
              this.props.newcitySession?.CityId !== "14" && (
                <li className="menu-wrap-for-second-part row  " id="vendor-type-wrapper" onClick={this.toggleVendorsDrop.bind(this)}>
                  <div data-src="//media.bookeventz.com/html/bookeventz.com/asset/images/BE_Hamburger_Sprite_enlarged-new-1.png" className="icon-header-mobile icon-header-image2"></div>
                  <span className="mobile-navbar-text">Vendors</span>
                  <span
                    data-src="//media.bookeventz.com/html/bookeventz.com/asset/images/BE_Hamburger_Sprite_enlarged-new-1.png"
                    className={this.state.vendorsDropOpen == true ? "headerUpIcon" : "  headerDropIcons"}
                  />
                </li>
              )}
            <li className="dropPanel">
              {this.props.newcitySession != null &&
                this.props.newcitySession != undefined &&
                constants.VENDOR_COUNTRY_IDS.includes(this.props.newcitySession.CountryId) &&
                this.state.vendorsDropOpen && (
                  <ul className={"list-unstyled col-xs-12 vendor-list " + this.state.vendorsDropOpen}>
                    <li className="user-profile-head">
                      <a
                        className="innerATag"
                        title={"Photographers in " + (this.props.newcitySession != null && this.props.newcitySession.CityName)}
                        href={SSR_WEB_BASE + "/photographers/" + (this.props.newcitySession != null && this.props.newcitySession.CityUniqueLink)}
                        target="_blank"
                      >
                        Photographers
                      </a>
                    </li>
                    <li className="user-profile-head">
                      <a
                        className="innerATag"
                        title={"Bridal MakeUp Artists in " + (this.props.newcitySession != null && this.props.newcitySession.CityName)}
                        href={SSR_WEB_BASE + "/bridal-makeups/" + (this.props.newcitySession != null && this.props.newcitySession.CityUniqueLink)}
                        target="_blank"
                      >
                        Makeup Artists
                      </a>
                    </li>
                    <li className="user-profile-head">
                      <a
                        className="innerATag"
                        title={"Bridal Mehendi Artists in " + (this.props.newcitySession != null && this.props.newcitySession.CityName)}
                        href={SSR_WEB_BASE + "/mehendi-artists/" + (this.props.newcitySession != null && this.props.newcitySession.CityUniqueLink)}
                        target="_blank"
                      >
                        Mehendi Artists
                      </a>
                    </li>
                    <li className="user-profile-head">
                      <a
                        className="innerATag"
                        title={"Caterers in " + (this.props.newcitySession != null && this.props.newcitySession.CityName)}
                        href={SSR_WEB_BASE + "/caterer/" + (this.props.newcitySession != null && this.props.sessionData.CityUniqueLink)}
                        target="_blank"
                      >
                        Caterers
                      </a>
                    </li>
                    <li className="user-profile-head">
                      <a
                        className="innerATag"
                        title={"Decorators in " + (this.props.newcitySession != null && this.props.newcitySession.CityName)}
                        href={SSR_WEB_BASE + "/decorators/" + (this.props.newcitySession != null && this.props.newcitySession.CityUniqueLink)}
                        target="_blank"
                      >
                        Decorators
                      </a>
                    </li>
                    <li className="user-profile-head">
                      <a
                        className="innerATag"
                        title={"Anchors in " + generalUtil.safeReturn(this.props.newcitySession, "CityName", "")}
                        href={SSR_WEB_BASE + "/artists/anchors-in-india"}
                        target="_blank"
                      >
                        Anchors
                      </a>
                    </li>
                    <li className="user-profile-head">
                      <a
                        title={"Comedians in " + generalUtil.safeReturn(this.props.newcitySession, "CityName", "")}
                        href={SSR_WEB_BASE + "/artists/comedian-in-india"}
                        className="innerATag"
                        target="_blank"
                      >
                        Comedians
                      </a>
                    </li>
                    <li className="user-profile-head">
                      <a
                        className="innerATag"
                        title={"Choreographers in " + (this.props.newcitySession != null && this.props.newcitySession.CityName)}
                        href={SSR_WEB_BASE + "/choreographer/" + (this.props.newcitySession != null && this.props.newcitySession.CityUniqueLink)}
                        target="_blank"
                      >
                        Choreographers
                      </a>
                    </li>
                    <li className="user-profile-head">
                      <a
                        className="innerATag"
                        title={"Event Planner in " + (this.props.newcitySession != null && this.props.newcitySession.CityName)}
                        href={SSR_WEB_BASE + "/event-planner/" + (this.props.newcitySession != null && this.props.newcitySession.CityUniqueLink)}
                        target="_blank"
                      >
                        Event Planners
                      </a>
                    </li>
                  </ul>
                )}
            </li>

            <li className="menu-wrap-for-second-part row">
              <a href={SSR_WEB_BASE + "/" + "corporate-event-planning-portal"} className="venue-header-tab col-sm-12">
                <div data-src="//media.bookeventz.com/html/bookeventz.com/asset/images/BE_Hamburger_Sprite_enlarged-new-1.png" className="  col-sm-4 icon-header-mobile icon-header-image3"></div>
                <span className="mobile-navbar-text">For Corporates</span>
              </a>
            </li>

            {/* <li className="dropdown menu-wrap-for-second-part row external-class">
              <a
                className="vendor-header-tab1 col-xs-12"
                href={"https://www.toysbzaar.com/" + "toys-and-games"}
                target="_blank"
              >
                <div
                  data-src="//media.bookeventz.com/html/bookeventz.com/asset/images/BE_Hamburger_Sprite_enlarged-new-1.png"
                  className="  icon-header-mobile icon-header-image12"
                ></div>
                <span className="mobile-navbar-text">Bzaar</span>
                <span
                  data-src="//media.bookeventz.com/html/bookeventz.com/asset/images/BE_Hamburger_Sprite_enlarged-new-1.png"
                  className={
                    this.state.bazaarDropOpen == true ? "headerUpIcon" : "  headerDropIcons"
                  }
                />
               
              </a>
            </li> */}
            <li className="dropPanel">
              <Panel collapsible expanded={this.state.bazaarDropOpen}>
                <ul className={"list-unstyled col-xs-12 vendor-list " + this.state.bazaarDropOpen}></ul>
              </Panel>
            </li>

            <li className="dropdown menu-wrap-for-second-part row external-class" onClick={this.toggleRsvpDrop.bind(this)}>
              <div data-src="https://media.bookeventz.com/html/bookeventz.com/asset/images/BE_Hamburger_Sprite_enlarged-new-1.webp" className="  icon-header-mobile icon-header-image5"></div>
              <span className="mobile-navbar-text">Invites & RSVP</span>
              <span
                data-src="https://media.bookeventz.com/html/bookeventz.com/asset/images/BE_Hamburger_Sprite_enlarged-new-1.webp"
                className={this.state.rsvpDropopen == true ? "headerUpIcon" : "  headerDropIcons"}
              />
            </li>

            <li className="dropPanel">
              {this.state.rsvpDropopen && (
                <ul className={"list-unstyled col-xs-12 vendor-list " + this.state.rsvpDropopen}>
                  <li className="user-leads-head">
                    <a className="innerATag" href={SSR_WEB_BASE + "/invites/wedding"} onClick={this.switchInvite.bind(this, "wedding")}>
                      Wedding
                    </a>
                  </li>
                  <li className="user-reviews-head">
                    <a className="innerATag" href={SSR_WEB_BASE + "/invites/birthday-party"}>
                      Birthday Party
                    </a>
                  </li>
                  <li className="user-profile-head">
                    <a className="innerATag" href={SSR_WEB_BASE + "/invites/corporate-event"}>
                      Corporate Event
                    </a>
                  </li>
                </ul>
              )}
            </li>
            <li className="dropdown menu-wrap-for-second-part row external-class" onClick={this.toggleRealEventsDrop.bind(this)}>
              <div data-src="https://media.bookeventz.com/html/bookeventz.com/asset/images/BE_Hamburger_Sprite_enlarged-new-1.webp" className="  icon-header-mobile icon-header-image6"></div>
              <span className="mobile-navbar-text">Real Events</span>
              <span
                data-src="https://media.bookeventz.com/html/bookeventz.com/asset/images/BE_Hamburger_Sprite_enlarged-new-1.webp"
                className={this.state.rsvpEventsDropopen == true ? "headerUpIcon" : "  headerDropIcons"}
              />
            </li>

            <li className="dropPanel">
              {this.state.rsvpEventsDropopen && (
                <ul className={"list-unstyled col-xs-12 vendor-list " + this.state.rsvpEventsDropopen}>
                  <li className="user-leads-head">
                    <a className="innerATag" href={SSR_WEB_BASE + "/" + "real-events/wedding"}>
                      Wedding
                    </a>
                  </li>
                  <li className="user-reviews-head">
                    <a className="innerATag" href={SSR_WEB_BASE + "/" + "real-events/birthday"}>
                      Birthday Party
                    </a>
                  </li>
                  <li className="user-profile-head">
                    <a className="innerATag" href={SSR_WEB_BASE + "/" + "real-events/conference"}>
                      Corporate Event
                    </a>
                  </li>
                  <li className="user-profile-head">
                    <a className="innerATag" href={SSR_WEB_BASE + "/" + "real-events/cocktail"}>
                      Cocktail
                    </a>
                  </li>
                  <li className="user-profile-head">
                    <a className="innerATag" href={SSR_WEB_BASE + "/" + "real-events"}>
                      All
                    </a>
                  </li>
                </ul>
              )}
            </li>
            <li className="dropdown menu-wrap-for-second-part row external-class" onClick={this.toggleBlogDrop.bind(this)}>
              <div data-src="https://media.bookeventz.com/html/bookeventz.com/asset/images/BE_Hamburger_Sprite_enlarged-new-1.webp" className="  icon-header-mobile icon-header-image4"></div>
              <span className="mobile-navbar-text">Blog</span>
              <span
                data-src="https://media.bookeventz.com/html/bookeventz.com/asset/images/BE_Hamburger_Sprite_enlarged-new-1.webp"
                className={this.state.blogDropOpen == true ? "headerUpIcon" : "  headerDropIcons"}
              />
            </li>
            <li className="dropPanel">
              {this.state.blogDropOpen && (
                <ul className={"list-unstyled col-xs-12 vendor-list " + this.state.blogDropOpen}>
                  <li className="user-leads-head">
                    <a className="innerATag" href={WEB_BASE + "blog/category/wedding-ideas"}>
                      Wedding
                    </a>
                  </li>
                  <li className="user-reviews-head">
                    <a className="innerATag" href={WEB_BASE + "/blog/category/birthday-party"}>
                      Birthday Party
                    </a>
                  </li>
                  <li className="user-profile-head">
                    <a className="innerATag" href={WEB_BASE + "blog/category/cocktail-party"}>
                      Cocktail Party
                    </a>
                  </li>
                  <li className="user-profile-head">
                    <a className="innerATag" href={WEB_BASE + "blog/category/corporate-events"}>
                      Corporate Party
                    </a>
                  </li>
                  <li className="user-profile-head">
                    <a className="innerATag" href={WEB_BASE + "blog/category/top-venues"}>
                      Top Venues
                    </a>
                  </li>
                </ul>
              )}
            </li>

            <li className="dropdown menu-wrap-for-second-part row external-class" onClick={this.toggleIdeasDrop.bind(this)}>
              <div data-src="https://media.bookeventz.com/html/bookeventz.com/asset/images/BE_Hamburger_Sprite_enlarged-new-1.webp" className="  icon-header-mobile icon-header-image7"></div>
              <span className="mobile-navbar-text">Ideas</span>
              <span
                data-src="https://media.bookeventz.com/html/bookeventz.com/asset/images/BE_Hamburger_Sprite_enlarged-new-1.webp"
                className={this.state.ideasDropopen == true ? "headerUpIcon" : "  headerDropIcons"}
              />
            </li>

            <li className="dropPanel">
              {this.state.ideasDropopen && (
                <ul className={"list-unstyled col-xs-12 vendor-list " + this.state.ideasDropopen}>
                  <li className="user-leads-head1">
                    <a rel="noreferrer" className="innerATag" href="https://weddingideas.bookeventz.com">
                      Wedding
                    </a>
                  </li>
                  <li className="user-reviews-head">
                    <a rel="noreferrer" className="innerATag" href="https://birthdayideas.bookeventz.com">
                      Birthday Party
                    </a>
                  </li>
                  <li className="user-profile-head">
                    <a rel="noreferrer" className="innerATag" href="https://corporateeventideas.bookeventz.com">
                      Corporate Event
                    </a>
                  </li>
                </ul>
              )}
            </li>
            <li className="menu-wrap-for-second-part row">
              <a href={SSR_WEB_BASE + "/" + "build-your-event-website"} className="venue-header-tab col-sm-12">
                <div data-src="https://media.bookeventz.com/html/bookeventz.com/asset/images/BE_Hamburger_Sprite_enlarged-new-1.webp" className="  icon-header-mobile icon-header-image8"></div>
                <span className="mobile-navbar-text">Create Event Website</span>
              </a>
            </li>
            <li className="menu-wrap-for-second-part row">
              <a href={SSR_WEB_BASE + "/" + "testimonial"} className="venue-header-tab col-sm-12">
                <div
                  data-src="https://media.bookeventz.com/html/bookeventz.com/asset/images/BE_Hamburger_Sprite_enlarged-new-1.webp"
                  className="  col-sm-4 icon-header-mobile icon-header-image9"
                ></div>
                <span className="mobile-navbar-text">Testimonial</span>
              </a>
            </li>
            <li className="menu-wrap-for-second-part row">
              <a href={SSR_WEB_BASE + "/" + "why-list-with-us"} className="venue-header-tab col-sm-12">
                <div
                  data-src="https://media.bookeventz.com/html/bookeventz.com/asset/images/BE_Hamburger_Sprite_enlarged-new-1.png"
                  className="  col-sm-4 icon-header-mobile icon-header-image10"
                ></div>
                <span className="mobile-navbar-text">List With Us</span>
              </a>
            </li>
          </ul>
        </div>
      );
    }

    // var NewsessionData = cookie.load('sessionData');

    if (localStorageUtil.getLocal("shortlisted") != null) {
      let shortListData = localStorageUtil.getLocal("shortlisted");
      shortlistCount = shortListData.length;
    } else if (this.props.vCount !== null) {
      shortlistCount = this.props.vCount?.length;
    } else {
    }
    const windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;

    const myStyle = {
      display: windowWidth > 768 ? "none" : "block",
    };
    return (
      <React.Fragment>
        <header className="new-head-1">
          <div className="container-fluid">
            {this.state.sidebarViewPort == true && window.innerWidth < 768 ? (
              <Sidebar
                sidebar={sideBarContent}
                sidebarClassName="sidenav"
                contentClassName="sideBarContentClassName"
                open={this.state.sidebarOpen}
                styles={stylesBar}
                onSetOpen={this.onSetSidebarOpen.bind(this)}
              />
            ) : (
              ""
            )}
            {this.state.renderCity && (
              <div className="modal fade city_modal_87" id="myModal2" role="">
                <div className="modal-dialog">
                  <div className="modal-content cityModel">
                    <div className="modal-body">
                      {this.props.cityList != undefined && this.props.newcitySession != undefined ? this.renderList(this.props.newcitySession.CityId) : ""}
                      <div className="countryUlHead"></div>
                      <div onClick={this.onCityChange.bind(this, "destination-wedding")} className="countryUl">
                        <li className="">Destination Wedding</li>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="row header-top">
              <div className="col-xs-12 outerrr">
                <div className="pull-left logo-wrap" style={{display: "flex"}}>
                  <img
                    // style={myStyle}
                    onClick={this.onSetSidebarOpen.bind(this, true)}
                    src="https://media.bookeventz.com/html/ui_website/images/hamburger.svg"
                    alt="Home Iocn"
                    id="home-iocn"
                    data-cache-control="public, max-age=604800"
                  />{" "}
                  {/* <span
                    onClick={this.onSetSidebarOpen.bind(this, true)}
                    className="sprite hamburger hidden-sm hidden-md hidden-lg"
                  /> */}
                  <a href={SSR_WEB_BASE} className="logo">
                    {
                      <img
                        alt={"media.bookeventz.com"}
                        src={"//media.bookeventz.com/html/bookeventz.com/asset/images/bzMainLogo.svg"}
                        width={"161px"}
                        height={"24px"}
                        data-cache-control="public, max-age=604800"
                      />
                    }
                  </a>
                </div>
                <div className="pull-right innr">
                  {this.props.userData != undefined && this.props.userData.eventIdMd5 != undefined ? (
                    <div className="eventButton hidden-xs">
                      <button onClick={this.goToEventPage.bind(this)}>My Event Page</button>
                    </div>
                  ) : (
                    ""
                  )}
                  {/*  {
                                    <div className="eventButton hidden-xs">
                                        <button onClick={this.goToEventPage.bind(this)}>My Event Page</button>
                                    </div>
                                }*/}
                  <div className="city-change">
                    {window.innerWidth > 768 ? (
                      <CityList
                        props={this.props.props}
                        sessionData={this.props.newcitySession}
                        citySelected={this.props.props.citySelected ? this.props.props.citySelected : "mumbai"}
                        newcitySession={this.props.newcitySession}
                        cityList={this.props.cityList}
                        pageType={this.props.pageType}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  {(this.props.pageType === "homeBlogListing" ||
                    this.props.pageType === "categoryBlogListing" ||
                    this.props.pageType === "authorBlogListing" ||
                    this.props.pageType === "tagBlogListing") && (
                    <div className="city-change lang">
                      <GoogleTranslateWidget />
                    </div>
                  )}

                  <div style={{display: "inline-block"}} className="hidden-lg hidden-md hidden-sm">
                    {that.props.sessionData != undefined && that.props.sessionData.userName != undefined && this.state.isWish ? (
                      <div className="wishListWrap">
                        <a href={SSR_WEB_BASE + "/" + "shortlistedVendors/" + this.props.sessionData.CityUniqueLink} target="_blank">
                          <div id="wishListIcon1" className={shortlistCount == 0 ? "wishListIcon" : "wishListIconFilled"}>
                            {shortlistCount > 0 ? <div className="wishCount">{shortlistCount}</div> : ""}
                          </div>
                        </a>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="hidden-xs login-wrap">
                    {this.props.newcitySession != undefined ? (
                      this.props.newcitySession.CityId == "1" ? (
                        <div className="contact">
                          {this.props.pageType == "ContractorPackagesLanding" || this.props.pageType == "ContractorPackageListing" ? (
                            this.props.pageType == "ContractorPackageListing" ? (
                              generalUtil.safeReturn(this.props.newcitySession, "CityId", "1") == "1" ? (
                                <div className="check1">
                                  <span title="Call Now" className="headerSprite callIconHeader" />
                                  <span title="Call Now" className="mobileno">
                                    99675 10777
                                  </span>
                                </div>
                              ) : (
                                ""
                              )
                            ) : (
                              <div className="check1">
                                <span title="Call Now" className="headerSprite callIconHeader" />
                                <span title="Call Now" className="mobileno">
                                  99675 10777
                                </span>
                              </div>
                            )
                          ) : (
                            <div className="check1">
                              <span title="Call Now" className="headerSprite callIconHeader" />
                              <span title="Call Now" className="mobileno">
                                99675 81110
                              </span>
                            </div>
                          )}
                        </div>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {that.props.sessionData != undefined && that.props.sessionData.userName != undefined && this.state.isWish ? (
                      <div className="wishListWrap">
                        <a href={SSR_WEB_BASE + "/" + "shortlistedVendors/" + this.props.sessionData.CityUniqueLink} target="_blank">
                          <div id="wishListIcon" className={shortlistCount == 0 ? "wishListIcon" : "wishListIconFilled"}>
                            {shortlistCount > 0 ? <div className="wishCount">{shortlistCount}</div> : ""}
                          </div>
                        </a>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="login-section" id="login-section">
                      {this.props.sessionData != undefined && this.props.sessionData.userName != undefined ? (
                        <span className="login-user-in">
                          {/* <span  id="dropdownMenuButton" data-toggle="dropdown"
                                                                   aria-haspopup="true" aria-expanded="false">
                                                                <div className="headerSprite PeopleHeader" />
                                                                <a className="text">{generalUtil.safeReturn(this.props.sessionData,'userName', '')}</a>
                                                                </span>*/}
                          <button
                            className="btn btn-secondary dropdown-toggle"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            data-bs-target="#navbarCollapse"
                            data-bs-toggle="collapse"
                            type={"button"}
                            style={{display: "flex !important"}}
                            onClick={() => {
                              this.setState({
                                desktopLoginMenu: !this.state.desktopLoginMenu,
                              });
                            }}
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <div className="headerSprite PeopleHeader" />
                            <a className="text" style={{color: "#000"}}>
                              {generalUtil.safeReturn(this.props.sessionData, "userName", "")}
                            </a>
                          </button>
                          <div id="navbarCollapse" className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={this.state.desktopLoginMenu ? {display: "block"} : {display: "none"}}>
                            <li>
                              <a className="vendor-header-tab col-xs-12 external-class wrap-enquiries" href={SSR_WEB_BASE + "/" + "user-bookings"}>
                                <div className="lazyload  icon-headerenquiry"></div>
                                <span className="mobile-navbar-text logged-inwrp">My Profile</span>
                              </a>
                            </li>
                            <li>
                              <a className="vendor-header-tab col-xs-12 external-class wrap-enquiries" href={WEB_BASE + "user-bookings"}>
                                <div className="lazyload  icon-headerenquiry"></div>
                                <span className="mobile-navbar-text logged-inwrp">My Event Enquiries</span>
                              </a>
                            </li>
                            <li>
                              <a className="vendor-header-tab col-xs-12 external-class wrap-enquiries" href={SSR_WEB_BASE + "/" + "user-bookings"}>
                                <div className="lazyload icon-headerbookings"></div>
                                <span className="mobile-navbar-text logged-inwrp">My Bookings</span>
                              </a>
                            </li>
                            <li>
                              <a className="vendor-header-tab col-xs-12 external-class wrap-enquiries" href={SSR_WEB_BASE + "/" + "user-bookings"}>
                                <div className="lazyload  icon-headerreviews"></div>
                                <span className="mobile-navbar-text logged-inwrp">My Reviews</span>
                              </a>
                            </li>
                            {this.props.sessionData.CityUniqueLink != undefined ? (
                              <li>
                                <a className="vendor-header-tab col-xs-12 external-class wrap-enquiries" href={SSR_WEB_BASE + "/" + "shortlistedVendors/" + this.props.sessionData.CityUniqueLink}>
                                  <div className="lazyload  icon-headerreviews"></div>
                                  <span className="mobile-navbar-text logged-inwrp">Shortlisted Vendors</span>
                                </a>
                              </li>
                            ) : (
                              ""
                            )}

                            <li>
                              <a className="vendor-header-tab col-xs-12 external-class wrap-enquiries" onClick={that.logoutNew.bind(this)}>
                                <div className="lazyload  icon-headerlogout"></div>
                                <span className="mobile-navbar-text logged-inwrp">Logout</span>
                              </a>
                            </li>
                          </div>
                        </span>
                      ) : (
                        <span className="login-user" style={{display: "flex !important"}} onClick={this.toggleLoginModalNew.bind(this, true)}>
                          <span title="My Account" className="headerSprite PeopleHeader" />
                          <span title="My Account" className="text">
                            LOGIN
                          </span>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* {this.bottomHeaderSection()} */}
            <NewTopHeader
              handleToggle={this.handleToggle}
              venuesTabOpen={this.state.venuesTabOpen}
              newcitySession={this.props.newcitySession}
              SSR_WEB_BASE={SSR_WEB_BASE}
              constants={constants}
              areaList={this.props.areaList}
              blogTabOpen={this.state.blogTabOpen}
              invitesTabOpen={this.state.invitesTabOpen}
              sessionData={this.props.sessionData}
              ideasTabOpen={this.state.ideasTabOpen}
              corporateOpen={this.state.corporateOpen}
              bazaarOpen={this.state.bazaarOpen}
              vendorsTabOpen={this.state.vendorsTabOpen}
            />
          </div>

          {/*<HeaderLoginSection props = {this.props} isShowLoginModal = {this.state.isShowLoginModal}/>*/}
        </header>
      </React.Fragment>
    );
  }
}

// module.exports = Header;
export default Header;
