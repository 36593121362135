"use strict";

var React = require("react");
var generalUtil = require("./generalUtil");
/* Uncomment below import to enable Firebase */
import SubscribeModal from "./subscribeModal";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import Chatbot from "../general/WhatsApp";
/* Do Not uncomment below import */
import { onBackgroundMessage } from "firebase/messaging/sw";
var querystring = require("querystring");
/*
import * as  CryptoJS from 'crypto-js';
*/
/*
import {Popover} from 'react-bootstrap'
*/
// var Reactbootstrap = require('react-bootstrap');

// import CityListPopup from './cityListPopup'

if (typeof window === "undefined") {
  global.window = {};
}
var environment = "production";
if (environment == "development") {
  var API_BASE = "https://apidemo.bookeventz.com/";
  var WEB_BASE = "https://staging.bookeventz.com/";
} else {
  var API_BASE = "https://api.bookeventz.com/";
  var WEB_BASE = "https://www.bookeventz.com/";
}

var SSR_WEB_BASE = "";
switch (process.env.NODE_ENV) {
  case "production":
    SSR_WEB_BASE = "https://www.bookeventz.com";
    break;
  case "development":
    SSR_WEB_BASE = "https://grunt.bookeventz.com";
    break;
  case "local":
    SSR_WEB_BASE = "http://localhost:5000";
    break;
  default:
    SSR_WEB_BASE = "";
}
SSR_WEB_BASE = "https://www.bookeventz.com";

const footerListArray = [
  { type: "city", title: "Banquet Halls by City" },
  { type: "area", title: "Banquet by Area" },
  { type: "venueType", title: "Banquet by Venue" },
  { type: "occasion", title: "Banquet by Occasion" },
];

import constants from "../../config/constants";
import config from "../../config/config";
import NewFooter from "./NewFooter/Footer";
class Footer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      filters: this.props.otherProps.filters,
      // showNewSubscriber:['venueListing','homePage','venueProduct'].includes(this.props.pageType),
      showNewSubscriber: true,
      userData: this.props.otherProps?.userData,
      notifModal: false,
      payLoad: null,
    };
  }

  componentDidMount() {
    /**
     * Uncomment it for enable FCM
     * **/
    const firebaseConfig = {
      apiKey: "AIzaSyB4pVOqXTiHWF8LCz05km9SyCXUepPfFA4",
      authDomain: "bookeventz-web.firebaseapp.com",
      projectId: "bookeventz-web",
      storageBucket: "bookeventz-web.appspot.com",
      messagingSenderId: "577866287857",
      appId: "1:577866287857:web:d3b1419b534907b808f750",
      measurementId: "G-4R0LN143ZW",
    };
    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);
    generalUtil.fcmPushNotification(messaging, onMessage, getToken);
    onMessage(messaging, (payload) => {
      this.setState({ payLoad: payload });
      let permission = Notification.permission;
      if (permission === "granted") {
        this.setState({ notifModal: !this.state.notifModal });
        var title = payload.notification.title;
        let icon = "https://img.onesignal.com/t/6574a703-52ff-45cc-bbfb-a941769222db.png";
        var body = payload.notification.body;
        let image = payload.notification.image;
        let data = {
          click_action: payload.click_actionf ? payload.click_action : "https://www.bookeventz.com/blog",
        };
        var notification = new Notification(title, { body, icon, image, data });
        notification.onclick = () => {
          notification.close();
          window.location.href = "https://www.bookeventz.com";
          window.parent.focus();
        };
      } else if (permission === "default") {
        // requestAndShowPermission();
      } else {
        console.log("Use normal alert");
      }
    });
  }
  render() {
    var that = this;
    var user = "info";
    var domain = "bookeventz.com";
    const { showNewSubscriber } = this.state;
    // console.log(this.props, "props from new footer")
    return (
      <footer>
        {this.props.eventId == null && this.props.sessionData && !("userName" in this.props.sessionData) && this.props?.pageType !== "vendorListing" && this.props?.pageType !== "vendorProduct" && (
          <Chatbot
            sessionData={this.props.sessionData}
            event_Id={this.props.eventId}
            class_name={this.props.class_name}
            productDetails={this.props.productDetails}
            flag={this.props.flag}
            filters={this.props.filters}
          />
        )}
        {this.state.notifModal && (
          <div
            id="fcm-wrapper"
            style={{
              width: 365,
              height: "auto",
              background: "#FFFFFF",
              borderRadius: 5,
              padding: "16px 10px 14px 16px",
              position: "fixed",
              top: 10,
              right: 20,
              zIndex: 999999,
            }}
          >
            <span
              id="closi"
              style={{
                width: 18,
                height: 18,
                background: "#E7E7E7",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "100%",
                position: "absolute",
                right: -8,
                top: -5,
              }}
              onClick={() => this.setState({ notifModal: !this.state.notifModal })}
            >
              <img src="../../media/closi.svg" alt="closi-button" />
            </span>
            <a href={this.state.payLoad?.data?.custom_open_url} target="_blank" onClick={() => this.setState({ notifModal: false })} style={{ cursor: "pointer" }}>
              <div
                id="ro"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: 14,
                  width: "100%",
                  height: "100%",
                }}
              >
                <div id="i" style={{ display: "flex", flexDirection: "column", gap: 8 }}>
                  <span
                    id="ti"
                    style={{
                      fontFamily: "Metropolis,sans-serif",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: 14,
                      color: "#333333",
                      width: "100%",
                      lineBreak: "anywhere",
                    }}
                  >
                    {this.state.payLoad?.notification?.title.length > 55 ? this.state.payLoad?.notification?.title.slice(0, 55) + "." : this.state.payLoad?.notification?.title}
                  </span>
                  <span
                    id="tid"
                    style={{
                      fontFamily: "Metropolis,sans-serif",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: 12,
                      color: "#888888",
                      width: "100%",
                      lineBreak: "anywhere",
                    }}
                  >
                    {this.state.payLoad?.notification?.body?.length > 100 ? this.state.payLoad?.notification?.body.slice(0, 100) + "..." : this.state.payLoad?.notification?.body}
                  </span>
                </div>
                <div id="im" style={{ width: 87, height: 63, borderRadius: 5 }}>
                  <img src={this.state.payLoad?.notification?.image} alt="Image of Notification" style={{ height: 63, width: 87, objectFit: "cover", borderRadius: 5 }} />
                </div>
              </div>
            </a>
          </div>
        )}

        {showNewSubscriber && (
          <SubscribeModal
            token={this.props?.props?.token}
            isShowModal={showNewSubscriber}
            pageType={this.props.pageType}
            filters={this.state.filters}
            userData={this.state.userData}
            sessionData={this.props.sessionData}
            occasionList={this.props.occasionList}
          />
        )}
        <NewFooter props={this.props.props} />
      </footer>
    );
  }
}

export default Footer;
