// import {axios} from 'axios'
var axios = require('axios');
var querystring = require("querystring");
let API_BASE = 'https://api.bookeventz.com/';
module.exports = {
    phoneNumberAnalytics: function (params) {
        axios.post(API_BASE+'Banquets/insertPhoneNumberView',querystring.stringify(params)).then((result)=>{
            console.log(result,'result result')
        });
    },
    enquiryNotWorkingSource: function (params) {
        axios.post(API_BASE+'Enquiry/enquiryNotWorkingSource',querystring.stringify(params)).then((result)=>{
            console.log(result,'result enquiryNotWorkingSource')
        });
    },
    updateGoogleLogsLogin: function (params) {
        axios.post(API_BASE+'Enquiry/updateGoogleLogsLogin',querystring.stringify(params)).then((result)=>{
            console.log(result,'result updateGoogleLogsLogin')
        });
    },
};